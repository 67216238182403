<template>
  <div class="m_base">
    <div v-for="topImage in topImages" :key="topImage.id">
      <div class="container">
          <img v-for="image in topImage.imageObjects" :key="image" :src="image.url" alt="" class="image">
      </div>
    </div>
    <router-link :to="{name: 'ManagerEditTopImages'}" class="m_go _eg">Edit Top Images</router-link>
  </div>
</template>

<script>

import "@/css/manager.css"
import {db} from '@/firebase/index.js'


export default {
  components:{

  },
  props:{
    is_sign: Boolean
  },
  data(){
    return{
      pageName: "topImage",
      isError: false,

      topImages: [],
      imagesObjects: [],
      dataId: "RNlgUhqSbVJUGxiCR0qT"

    }
  },
  beforeCreate(){

  },
  created(){
    this.$store.commit('managerPageName', this.pageName)
    if(!this.is_sign){
      this.$router.push({name: 'Manager'})
    }
    // db.collection('topImages').doc(this.dataId).get()
    // .then((doc)=>{
      
    //   this.topImages = new TopImages(doc.data()) 
    //   console.log(this.topImages.imagesObjects)
      
    // })
    db.collection('topImages').get()
    .then(querySnapShot=>{
      querySnapShot.forEach(doc => {
        this.topImages.push(doc.data())
      })
    })

  },
  mounted(){

  },
  methods:{

    
  },
  watch: {

  },
  computed:{

  },

}
</script>


<style scoped>
ul {
  margin-bottom: 50px;
}
.container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;
}
.image{
  width: calc(50% - 10px);
  margin-bottom: 20px;
  object-fit: cover;
}

.edit{
  background-color: var(--gray-sub);
  color: var(--white);
  display: block;
  width: 50px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 1.2rem;
}


@media screen and (min-width:600px){

}

</style>