<template>
  <div class="_base">
    <Header :isOpen="isOpenNavi" :pageTitle="pageTitle" @open="openNavi()" :currentPosition="_currentPosition"></Header>
    <Navi :isOpen="isOpenNavi" @close="closeNavi"></Navi>
    <!-- <GoTop></GoTop> -->
    
    <div  class="_content" >
      <div class="_container">
        <div class="_left">
          <div class="_left-content">
            <h2 class="_left-title title">個人情報の定義</h2>
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <p class="_text">
              本プライバシーポリシーにおける、「個人情報」とは、個人情報の保護に関する法律規定される「個人情報」及を指します。
            </p>
          </div>
        </div>
      </div>
      <div class="_container">
        <div class="_left">
          <div class="_left-content">
            <h2 class="_left-title title">個人情報の取得</h2>
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <p class="_text">
              当社は、利用目的の達成に必要な範囲で、個人情報を適正に取得し、不正の手段により取得することはありません。
            </p>
          </div>
        </div>
      </div>
      <div class="_container">
        <div class="_left">
          <div class="_left-content">
            <h2 class="_left-title title">利用目的</h2>
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <p class="_text">
              当社は、取得した個人情報を以下の目的で利用します。<br><br>

              1. 当社サービスのおける商品の発送、関連するアフターサービス、新商品サービスに関する情報の通知<br>
              2. 当社サービスに関するお問い合わせ等への対応<br>
              3. 当社サービスに関する規約等の変更等の通知<br>
            </p>
          </div>
        </div>
      </div>
      <div class="_container">
        <div class="_left">
          <div class="_left-content">
            <h2 class="_left-title title">第三者提供</h2>
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <p class="_text">
               当社は、法令に定められた場合を除き、あらかじめ利用者の同意を得ないで、第三者（日本国外にあるも者を含みます。）に個人情報を提供しません。
            </p>
          </div>
        </div>
      </div>
      <div class="_container">
        <div class="_left">
          <div class="_left-content">
            <h2 class="_left-title title">開示、訂正、利用停止、削除</h2>
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <p class="_text">
              当社は、利用者から個人情報の開示、訂正、利用停止、削除を求められたときは、法令に定められた場合を除き、本人確認の上で、遅滞なく開示を行います。
            </p>
          </div>
        </div>
      </div>
      <div class="_container">
        <div class="_left">
          <div class="_left-content">
            <h2 class="_left-title title">お問い合わせ窓口</h2>
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <p class="_text">
              個人情報の取り扱いに関するお問い合わせは、下記の窓口までお願いいたします。<br>
              <a class="mail" href="mailto:info@jibi.co.jp">info@jibi.co.jp</a>
            </p>
          </div>
        </div>
      </div>
      <div class="_container">
        <div class="_left">
          <div class="_left-content">
            <h2 class="_left-title title">変更について</h2>
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <p class="_text">
              当社は、必要に応じて、本プライバシーポリシーを変更いたします。なお、本プライバシーポリシーを変更する場合は、その内容を当社のウェブサイト上で表示いたします。
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer :pageTitle="pageTitle"></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'
// import GoTop from '@/components/GoTop.vue'
import Mixin from '@/mixin/mixin.js'

export default {
  components:{
    Header,
    Navi,
    Footer,
    // GoTop
  },
  props:{

},
  data(){
    return{
      pageTitle: "Privacy Policy",
      isOpenNavi: false,
    }
  },
  mixins:[
    Mixin
  ],
  beforeCreate(){
    
  },
  created(){
    
    
  },
  mounted(){

  },
  beforeDestory(){
    
  },
  methods:{
    // ナビ用
    openNavi(){
      this.isOpenNavi = true
      this._stop(true)
    },
    closeNavi(){
      this.isOpenNavi = false
      this._stop(false)
    },


  },
  watch: {

  },
  computed:{

  },

}
</script>

<style scoped>
.title{
  font-size: 1.8rem;
}
.mail{
  text-decoration:underline;
  text-decoration-color:var(--black);
}

@media screen and (max-width:599px){

}



/* タブレット */
@media screen and (min-width:600px) and (max-width:860px){

}
</style>