<template>
  <div class="_base">
    <Header :isOpen="isOpenNavi" :pageTitle="pageTitle" @open="openNavi()" :currentPosition="_currentPosition"></Header>
    <Navi :isOpen="isOpenNavi" @close="closeNavi"></Navi>
    <!-- <GoTop></GoTop> -->
    <div class="content">
      <div class="container">
        <Show :currentPosition="_currentPosition" :isCompletedLoad="isCompletedLoad">
        <div class="filter">
          <p @click="changeFilter('All')" :class="{'filter-active': workTag === 'All'}" class="filter-text">All</p>
          <p @click="changeFilter('Interior')" :class="{'filter-active': workTag === 'Interior'}" class="filter-text">Interior Design</p>
          <p @click="changeFilter('Graphic Design')" :class="{'filter-active': workTag === 'Graphic Design'}" class="filter-text">Graphic Design</p>
          <p @click="changeFilter('Web Design')" :class="{'filter-active': workTag === 'Web Design'}" class="filter-text">Web Design</p>
          <p @click="changeFilter('Branding')" :class="{'filter-active': workTag === 'Branding'}" class="filter-text">Branding</p>
          <p @click="changeFilter('Art')" :class="{'filter-active': workTag === 'Art'}" class="filter-text">Art Direction</p>
        </div>
        </Show>

        <div v-if="workTag === 'All'" class="_box-container">
          <div v-for="work in worksOfAll" :key="work.id" class="_box">
            <Show :currentPosition="_currentPosition" :isCompletedLoad="isCompletedLoad">
            <router-link  :to="{name: 'Work', params:{id: work.id}}"  >
              <img :src="work.imageUrl" :alt="work.title" class="_box-image">
              <h3 class="_box-sub-title"><p class="_f3">{{work.title}}</p><span class="_box-sub-title-line"></span><p class=" _f3 _eg ">{{work.mainContent}}</p></h3>
              <p class="_f5">{{work.shortDescription}}</p>
            </router-link>
            </Show>
          </div>
          <div v-if="works.length%3 === 2" class="_box-dummy"></div>
        </div>
        <div v-else class="_box-container">
          <div v-for="work in works" :key="work.id" class="_box">
            <Show :currentPosition="_currentPosition" :isCompletedLoad="isCompletedLoad">
            <router-link  :to="{name: 'Work', params:{id: work.id}}"  >
              <img :src="work.imageUrl" :alt="work.title" class="_box-image">
              <h3 class="_box-sub-title"><p class="_f3">{{work.title}}</p><span class="_box-sub-title-line"></span><p class=" _f3 _eg ">{{work.mainContent}}</p></h3>
              <p class="_f5">{{work.shortDescription}}</p>
            </router-link>
            </Show>
          </div>
          <div v-if="works.length%3 === 2" class="_box-dummy"></div>
        </div>

        <Show :currentPosition="_currentPosition" :isCompletedLoad="isCompletedLoad" :isLastElement="true">
          <OtherProjectInput></OtherProjectInput>
        </Show>
      </div>
    </div>
    <Footer :pageTitle="pageTitle"></Footer>
  </div>
</template>

<script>
import {db} from '@/firebase/index.js'
import Header from '@/components/Header.vue'
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'
import OtherProjectInput from '@/components/OtherProjectInput.vue'
import Show from '@/components/Show.vue'
// import GoTop from '@/components/GoTop.vue'
import Mixin from '@/mixin/mixin.js'

export default {
  components:{
    Header,
    Navi,
    Footer,
    OtherProjectInput,
    Show
    // GoTop
  },
  props:{

},
  data(){
    return{
      pageTitle: "Design Works",
      isOpenNavi: false,
      isCompletedLoad: false, //ここがtrueなら Show 発動

      works:[],
      password: null,
      workTag: "All", 
      worksOfAll:[],
      worksOfInterior: [],
      worksOfBranding: [],
      worksOfArt: [],
      worksOfGraphic: [],
      worksOfWeb: [],
    }
  },
  mixins:[
    Mixin
  ],
  beforeCreate(){
    
  },
  created(){
    db.collection('work').where('isPublic', '==', true).get()
    .then(querySnapShot=>{
      querySnapShot.forEach(doc=>{
        this.works.push(doc.data())
      })
      this.works.sort((a, b)=>{
        if(a.completion < b.completion){return 1}
        if(a.completion > b.completion){return -1}
        return 0
      })
      this.works.forEach((work)=>{
        work.imageObjects.forEach(imageObject=>{
          // 画像のセット
          if(imageObject.order === 0){
            work.imageUrl = imageObject.url
          }
        })
        
        //フィルターのセット
        if(work.mainContent === "Interior Design" || work.mainContent === "Furniture Design"){
          this.worksOfInterior.push(work)
        }
        if(work.mainContent === "Branding"){
          this.worksOfBranding.push(work)
        }
        if(work.mainContent === "Art Direction"){
          this.worksOfArt.push(work)
        }
        if(work.mainContent === "Graphic Design"){
          this.worksOfGraphic.push(work)
        }
        if(work.mainContent === "Web Design"){
          this.worksOfWeb.push(work)
        }
      })
      this.worksOfAll = this.works
      this.isCompletedLoad = true
    })

  },
  mounted(){

  },
  beforeDestory(){
    
  },
  methods:{
    // ナビ用
    openNavi(){
      this.isOpenNavi = true
      this._stop(true)
    },
    closeNavi(){
      this.isOpenNavi = false
      this._stop(false)
    },
    changeFilter(tag){
      this.workTag = tag
      this.works = []
      if(tag === "Interior"){
        this.works = this.worksOfInterior
      }
      if(tag === "Branding"){
        this.works = this.worksOfBranding
      }
      if(tag === "Art"){
        this.works = this.worksOfArt
      }
      if(tag === "Graphic Design"){
        this.works = this.worksOfGraphic
      }
      if(tag === "Web Design"){
        this.works = this.worksOfWeb
      }
    },


  },
  watch: {

  },
  computed:{

  },

}
</script>

<style scoped>

.content{
  background-color: var(--bg);
  width: 100%;
  height: auto;
  padding-bottom: 100px;
  min-height: calc(100vh - 270px);
}

.container{
  padding: 300px 0 160px;
  margin: 0 auto;
  width: 90%;
  max-width: 1400px;
}

.filter{
  display: flex;
  margin: 0 0 30px;
  transform: translate(0, -30px);
  flex-wrap: wrap;
}
.filter-text{
  margin-right: 14px;
  text-decoration: underline;
  color: var(--gray);
  transition: 0.6s;
  cursor: pointer;
}
.filter-text:hover{
  color: var(--white);
}
.filter-active{
  color: var(--black);
}

.journal-day{
  color: var(--gray);
  margin-top: 20px;
}
.work-other{
  margin: 0 auto 180px;;
  display: block;
  width: 30%;
}
.password-label{
  color: var(--gray);
  margin-bottom: 8px;
  display: block;
}
.password-input{
  width: 100%;
  display: block;
  border: 1px solid #b3a69f;
  height: 30px;
  text-align: center;
  color: var(--black);
}
.password-input::placeholder{
  color: var(--gray);
}



@media screen and (max-width:599px){
 
  .content{
    padding-bottom: 50px;
    min-height: calc(100vh - 270px);
  }

  .container{
    padding-top: 120px;
  }
  .work-other{
    width: 60%;
    margin-bottom: 160px;
  }

}
@media screen and (min-width:600px) and (max-width:1100px){

}

/* タブレット */
@media screen and (min-width:600px) and (max-width:860px){

}
</style>