<template>
  <div class="_base">
    <Header :isOpen="isOpenNavi" :pageTitle="pageTitle" @open="openNavi()" :currentPosition="_currentPosition"></Header>
    <Navi :isOpen="isOpenNavi" @close="closeNavi"></Navi>
    <!-- <GoTop></GoTop> -->

    <div class="title">
      <h2>{{element.title}}</h2><p class="">{{element.subTitle}}</p>
    </div>
    <img v-if="element.topImageObjects.length === 1" :src="element.topImageObjects[0].url" :alt="element.title" class="top-image">
    <div v-else class="h_top-images">
      <div  class="h_top-image-container">
        <img v-for="(image, index) in element.topImageObjects" :key="index" class="h_top-image" :src="image.url" :class="{'h_top-image-active': imageMark === index}">
      </div>
      <div class="h_image-switch-container">
        <div  class="h_image-switch">
          <div v-for="(image, index) in element.topImageObjects" :key="image.name" @click="imageChange(index)" class="h_switch"><div class="h_switch-line" :class="{'h_switch-active': imageMark === index}"></div></div>
        </div>
      </div>
    </div>
    <div  class="content" >
      <div class="container">
        <div class="_left">
          <div class="_left-content">
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <p class=" _text _marginS">
              {{element.firstText}}
            </p>
            <img v-if="element.isSubImage === true && separateTopImageObject !== null" :src="separateTopImageObject.url" alt="" class="sub-image">
            <p class="_text _marginS">
              {{element.secondText}}
            </p>

            <div class="images">
              <div v-if="element.imageVPosition === 'top'" class="images-box">
                <img v-for="image in element.verticalImageObjects" :key="image.order" :src="image.url" alt="" class="imageV-one" :class="{'imageV-two': element.verticalImageObjects.length === 2}">
              </div>
              <img v-for="image in subImageObjects" :key="image.order" :src="image.url" alt="" class="image-one">
              <div v-if="element.imageVPosition === 'bottom'" class="images-box">
                <img v-for="image in element.verticalImageObjects" :key="image.order" :src="image.url" alt="" class="imageV-one" :class="{'imageV-two': element.verticalImageObjects.length === 2}">
              </div>
            </div>

            <div v-for="(movieId, index) in element.movieIds" :key="movieId" class="h_movie _marginSS">
              <iframe :id="`video${index}`" :src="`https://player.vimeo.com/video/${movieId}?badge=0&amp;autopause=0&amp;player_id=0`"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>
            <a v-if="element.url" :href="element.url" target="_blank" rel="noopener noreferrer" class="h_shop">購入</a>
            <p class="journal-day _eg">{{$store.getters['getMonths'](element.month)}} {{element.day}} {{element.year}}</p>
            
            <!-- <div class="h_move">
              <router-link :to="{name: 'Hinamori2'}" class="h_move-box" >
                <img src="@/assets/images/arrow-l.png" alt="">
                <p class="h_move-text _eg">Back</p>
              </router-link >
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <Footer :pageTitle="pageTitle"></Footer>
    
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'
// import GoTop from '@/components/GoTop.vue'
import Mixin from '@/mixin/mixin.js'
import Element from '@/class/element.js'
import {db} from '@/firebase/index.js'

export default {
  components:{
    Header,
    Navi,
    Footer,
    // GoTop
  },
  props:{

},
  data(){
    return{
      pageTitle: "",
      isOpenNavi: false,

      element: new Element(),

      imageMark: 0,
      timer: null,
      topImageCounter: null,

      separateTopImageObject: null,
      subImageObjects:[],


    }
  },
  mixins:[
    Mixin
  ],
  beforeCreate(){
    
  },
  created(){
    db.collection('element').doc(this.$route.params.id).get()
    .then((doc)=>{
      this.element = doc.data()
      if(this.element.type === 'collection'){
        this.pageTitle = 'Collection'
      }
      if(this.element.type === 'experiment'){
        this.pageTitle = 'Experiment'
      }
      this.setImageObjects(this.element.topImageObjects)
      this.setImageObjects(this.element.subImageObjects)
      this.setImageObjects(this.element.verticalImageObjects)

      this.topImageCounter = this.element.topImageObjects.length
      
      this.element.subImageObjects.forEach(imageObject=>{
        if(this.element.isSubImage){
          if(imageObject.order === 0){
            this.separateTopImageObject = imageObject
          }else{
            this.subImageObjects.push(imageObject)
          }
        }else{
          this.subImageObjects.push(imageObject)
        }
      })
    })
    .catch((error)=>{
      console.log(error.messege)
    })
    
  },
  mounted(){

  },
  beforeDestory(){
    
  },
  methods:{
    // ナビ用
    openNavi(){
      this.isOpenNavi = true
      this._stop(true)
    },
    closeNavi(){
      this.isOpenNavi = false
      this._stop(false)
    },
    setImageObjects(imageObjects){
      if(imageObjects.length > 0){
        imageObjects.sort((a,b)=>{
          if(a.order < b.order){return -1}
          if(a.order > b.order){return 1}
          return 0
        })
      }
    },
    imageChange(mark){
      clearTimeout(this.timer)
      this.imageMark = mark
    },
    setTopImage(){
      if(this.imageMark < this.topImageCounter - 1){
        this.imageMark ++
      }else{
        this.imageMark = 0
      }
    },
  },
  watch: {
    imageMark:{
      immediate: true,
      handler: function(){
        clearTimeout(this.timer)
        this.timer = setTimeout(this.setTopImage, 3000)
      },
    },
  },
  computed:{

  },

}
</script>

<style scoped>
.title{
  margin-left: 42%;
  display: flex;
  padding: 180px 0 40px;
}
.title h2{
  font-size: 2.0rem;
  letter-spacing: 1px;
}
.title p{
  font-size: 1.4rem;
  color: var(--gray);
  -webkit-transform: translate(15px, 7px);
  transform: translate(15px, 7px);
}

.top-image{
  width: 100%;
  height: 600px;
  object-fit: cover;
}






.content{
  width: 100%;
  padding: 60px 0 100px;
}
.container{
  width: 100%;
  padding-bottom: 160px;
  display: flex;
}

.movie {
  width: 100%;
  aspect-ratio: 16 / 9;
  /* aspect-ratio: 1 / 1; */
  position: relative;
}
.movie iframe {
  width: 100%;
  height: 100%;
}
.movie-cover-top{
  position: absolute;
  width: 100%;
  height: 22%;
  top: 0;
  left: 0;
  background-color: var(--bg);
}
.movie-cover-bottom{
  position: absolute;
  width: 100%;
  height: 22%;
  bottom: 0;
  left: 0;
  background-color: var(--bg);
}
.sub-image{
  width: 100%;
  object-fit: cover;
  aspect-ratio: 5 / 3;
}

.images{
  margin-bottom: 60px;
}
.image-one{
  width: 100%;
  aspect-ratio: 5 / 3;
  object-fit: cover;
  margin-bottom: 10px;
  display: block;
}
.images-box{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.imageV-one{
  width: 100%;
  display: block;
  aspect-ratio: 3 / 4;
  object-fit: cover;
}
.imageV-two{
  width: calc(50% - 5px);
}
.journal-day{
  color: var(--gray);
  font-size: 1.5rem;
  margin: 40px 0 25px ;
}
.move{
  display: flex;
  justify-content: space-between;
  width: 170px;
}
.move-box{
  display: flex;
  justify-content: space-between;
  width: 50px;
  transition: 0.3s;
}
.move-box:hover{
  filter: brightness(10%);
}
.move-box img{
  width: 15px;
  height: 15px;
}
.move-text{
  color: var(--gray);
  font-size: 1.4rem;
  line-height: 15px;
}
.unpublic{
  padding-top: 100px;
}


.appear{
  opacity: 1;
}
@media screen and (max-width:599px){

  .title{
    width: 90%;
    margin: 0 auto;
    padding: 120px 0 40px;
    flex-direction: column;
  }
  .title p{
    -webkit-transform: translate(0px, -6px);
    transform: translate(0px, -6px);
  }
  .container{
    width: 90%;
    margin: 0 auto;
    display: block;
    padding-bottom: 100px;
    
  }
  .top-image{
    height: 250px;
  }
  .unpublic{
  padding-top: 0px;
  }




}



/* タブレット */
@media screen and (min-width:600px) and (max-width:860px){

}
/* タブレット */
@media screen and (min-width:1200px) {
  .image{
    height: 700px;
  }
}
</style>