<template>
  <div class="m_base">
    <ul>
      <li v-for="work in works" :key="work.id" class="container">
        <router-link :to="{name: 'Work', params:{id: work.id}}" class="left">
          <p :class="{'m_unpublic': !work.isPublic}">{{work.title.substr(0, 40)}}</p>
        </router-link>
        <router-link :to="{name: 'ManagerEditWork', params:{id: work.id}}" class="edit _eg">Edit</router-link>
      </li>
    </ul>
    <router-link :to="{name: 'ManagerCreateWork'}" class="m_go _eg">Create New Work</router-link>
  </div>
</template>

<script>

import "@/css/manager.css"
import {db} from '@/firebase/index.js'

export default {
  components:{

  },
  props:{
    is_sign: Boolean
  },
  data(){
    return{
      pageName: "work",
      isError: false,

      works:[],

    }
  },
  beforeCreate(){

  },
  created(){
    this.$store.commit('managerPageName', this.pageName)
    if(!this.is_sign){
      this.$router.push({name: 'Manager'})
    }
    db.collection('work').orderBy('completion', 'desc').get()
    .then((querySnapShot)=>{
      querySnapShot.forEach(doc => {
        this.works.push(doc.data())   
      });
    })

  },
  mounted(){

  },
  methods:{

    
  },
  watch: {

  },
  computed:{

  },

}
</script>


<style scoped>
ul {
  margin-bottom: 50px;
}
.container{
  display: flex;
  justify-content: space-between;
  height: 20px;
  margin-bottom: 30px;
}
.container:hover{
  box-sizing: border-box;
  border-bottom: 1px solid var(--black);
}
.left{
  display: flex;
}
.left p{
  line-height: 20px;
  margin-right: 10px;
}

.edit{
  background-color: var(--gray-sub);
  color: var(--white);
  display: block;
  width: 50px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 1.2rem;
}


@media screen and (min-width:600px){

}

</style>