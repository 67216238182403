export default{
  data(){
    return{
      _currentPosition: null, 
      _innerHeight:null, //使う時は + "px" をつけて 
    }
  },
  methods:{
    // スクロールロック 止めたい箇所に_stopScroll 解除したい箇所に_startScroll
    disableScroll(event) {
      event.preventDefault()
    },
    _stopScroll(){
      // document.addEventListener('touchmove', this.disableScroll, { passive: false })
      // document.addEventListener('mousewheel', this.disableScroll, { passive: false })
    },
    _startScroll(){
      // document.removeEventListener('touchmove', this.disableScroll, { passive: false });
      // document.removeEventListener('mousewheel', this.disableScroll, { passive: false });
    },

    //スクロール位置取得
    setCurrentPosition(){
      this._currentPosition = window.scrollY
    },

    _stop(fixed){
      /**
       * 表示されているスクロールバーとの差分を計測し、背面固定時はその差分body要素に余白を生成する
       */
      const scrollbarWidth = window.innerWidth - document.body.clientWidth;
      document.body.style.paddingRight = fixed ? `${scrollbarWidth}px` : '';
    
      /**
       * スクロール位置を取得する要素を出力する(`html`or`body`)
       */
      const scrollingElement = () => {
        const browser = window.navigator.userAgent.toLowerCase();
        if ('scrollingElement' in document) return document.scrollingElement;
        if (browser.indexOf('webkit') > 0) return document.body;
        return document.documentElement;
      };
    
      /**
       * 変数にスクロール量を格納
       */
      const scrollY = fixed
        ? scrollingElement().scrollTop
        : parseInt(document.body.style.top || '0');
    
      /**
       * CSSで背面を固定
       */
      const styles = {
        height: '100vh',
        left: '0',
        overflow: 'hidden',
        position: 'fixed',
        top: `${scrollY * -1}px`,
        width: '100vw',
      };
    
      Object.keys(styles).forEach((key) => {
        document.body.style[key] = fixed ? styles[key] : '';
      });
    
      /**
       * 背面固定解除時に元の位置にスクロールする
       */
      if (!fixed) window.scrollTo(0, scrollY * -1);
    },

    _resetHeight(){
      if(window.innerWidth > 600){
        this._innerHeight =  window.innerHeight
      }
    },

  },
  created(){
    this._innerHeight =  String(window.innerHeight) 
  },
  mounted(){
    window.addEventListener('scroll', this.setCurrentPosition)
    window.addEventListener('resize', this._resetHeight)
  },
  beforeDestory(){
    window.removeEventListener('scroll', this.setCurrentPosition)
    window.removeEventListener('resize', this._resetHeight)
  },

}