<template>
  <div class="m_base">
    <ul>
      <transition-group name="list" tag="tbody">
        <li v-for="journal in journalDisp"
            :key="journal.id"
            draggable="true"
            @dragstart="dragstart(journal, $event)"
            @dragenter="dragenter(journal)"
            @dragover.stop.prevent="dragover"
            @dragend.stop.prevent="dragend"
            class="container">
            <p class="day _eg">{{journal.month}} {{journal.day}} {{journal.year}}</p>
            <p class="title" :class="{'m_unpublic': !journal.isPublic}">{{journal.title}} {{journal.subTitle}}</p>
        </li>
      </transition-group>
    </ul>
    <button @click="changeOrder()"  class="m_submit _eg">Save Order</button>
  </div>
</template>

<script>

import "@/css/manager.css"
import {db} from '@/firebase/index.js'

export default {
  components:{

  },
  props:{
    is_sign: Boolean
  },
  data(){
    return{
      pageName: "journal",
      isError: false,

      journals:[],
      draggingItem: null, // ドラッグ中の要素を保持するための変数

    }
  },
  beforeCreate(){

  },
  created(){
    this.$store.commit('managerPageName', this.pageName)
    if(!this.is_sign){
      this.$router.push({name: 'Manager'})
    }
    db.collection('journal').orderBy('order', "desc").get()
    .then((querySnapShot)=>{
      querySnapShot.forEach(doc => {
        this.journals.push(doc.data())
        
      });
    })

  },
  mounted(){

  },
  methods:{
    changeOrder(){
      let batch = db.batch();
      this.journals.forEach(journal =>{
        const ref = db.collection('journal').doc(journal.id)
        batch.update(ref, {order: journal.order})
      })
      batch.commit()
      .then(()=>{
        this.$router.push({name: 'ManagerJournal'})
      })

    },
    dragstart(item, e) {
      this.draggingItem = item; // ドラッグ中の要素を保持
      e.dataTransfer.effectAllowed = 'move'; // 移動モードに設定
      e.target.style.opacity = 0.5; // ドラッグ中要素のスタイルを変更
    },
    dragenter(item) {
      // ドラッグ中の要素とドラッグ先の要素の表示順を入れ替える
      [item.order, this.draggingItem.order] = [this.draggingItem.order, item.order];
    },
    dragover(e) {
      e.dataTransfer.dropEffect = 'move'; // 移動モードに設定
    },
    dragend(e) {
      e.target.style.opacity = 1; // ドラッグ中要素のスタイルを変更（元に戻す）
      this.draggingItem = null; // ドラッグ中の要素をクリア
    },
    
  },
  watch: {

  },
  computed:{
    journalDisp() {
      return [...this.journals].sort((a, b) => b.order - a.order);
    },
  },

}
</script>


<style scoped>
ul {
  margin-bottom: 50px;
}
.container{
  display: flex;
  height: 20px;
  margin-bottom: 30px;
}
.container:hover{
  box-sizing: border-box;
  border-bottom: 1px solid var(--black);
}
.left{
  display: flex;
}
.left p{
  line-height: 20px;
  margin-right: 10px;
}
.unpublic{
  color: brown;
}

.list-move {
  transition: transform 0.3s;
}
.day{
  line-height: 30px;
  margin-right: 10px;
}

@media screen and (min-width:600px){

}

</style>