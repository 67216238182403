<template>
  <div class="_base">
    <Header :isOpen="isOpenNavi" :pageTitle="pageTitle" @open="openNavi()" :currentPosition="_currentPosition"></Header>
    <Navi :isOpen="isOpenNavi" @close="closeNavi"></Navi>
    <!-- <GoTop> </GoTop> -->
    
    <div  class="_content" >
      <div class="_container">
        <div class="_left">
          <div class="_left-content">
            <h2 class="_left-title _eg-b">Recruit</h2>
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <p class="_text _marginLL">現在、JIBIでは以下のスタッフを募集しています。</p>
            <div class="recruit-box  _marginLL">
              <p class="recruit-left">インテリアデザイナー</p>
              <p class="recruit-right">
                空間デザイン（FF&amp;E やアートを含む）全般を行います<br>
                実務経験者<br>
                VectorWorks/Photoshop/Illustrator 必須<br>
                3Dパース作成/建築士関連の資格保有者 優遇<br>
              </p>
            </div>
            <div class="recruit-box _marginLL">
              <p class="recruit-left">ブランドスタッフ</p>
              <p class="recruit-right">
                陽森やmuiのブランド運営・企画を行います<br>
                自然環境の中で働きたい方<br>
                虫への耐性/草刈りなどの重労働 必須<br>
                船舶免許保有者/チェーンソー作業資格保有者 優遇<br>
              </p>
            </div>
            <p class="_text">
              勤務地は大津島となります。<br>
              島内に移住される場合、住居提供いたします。（家賃不要）<br>
              雇用条件などの詳細につきましては、下記までお問い合わせください。<br>
              担当 | 西村 尚倫<a class="_mail" href="mailto:naotoshi.n@jibi.co.jp?subject=リクルートの問い合わせ">naotoshi.n@jibi.co.jp</a>
            </p>
          </div>
        </div>
      </div>
      <div class="_container">
        <div class="_left">
          <div class="_left-content">
            <h2 class="_left-title _eg-b">Privacy Policy</h2>
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <p class="_text">個人情報の取り扱いについては<router-link :to="{name: 'Privacy'}" class="link">こちら</router-link>をご確認ください。</p>
          </div>
        </div>
      </div>
    </div>
    <Footer :pageTitle="pageTitle"></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'
// import GoTop from '@/components/GoTop.vue'
import Mixin from '@/mixin/mixin.js'

export default {
  components:{
    Header,
    Navi,
    Footer,
    // GoTop
  },
  props:{

},
  data(){
    return{
      pageTitle: "Information",
      isOpenNavi: false,
    }
  },
  mixins:[
    Mixin
  ],
  beforeCreate(){
    
  },
  created(){
    
    
  },
  mounted(){

  },
  beforeDestory(){
    
  },
  methods:{
    // ナビ用
    openNavi(){
      this.isOpenNavi = true
      this._stop(true)
    },
    closeNavi(){
      this.isOpenNavi = false
      this._stop(false)
    },


  },
  watch: {

  },
  computed:{

  },

}
</script>

<style scoped>
.recruit-box{
  display: flex;
}
.recruit-left{
  width: 40%;
  max-width: 240px;
}
.recruit-right{
  width: 60%;
  text-align: justify;
}
.link{
  text-decoration:underline;
  text-decoration-color:var(--black);
  letter-spacing: 0px;
}

@media screen and (max-width:599px){
  .recruit-box{
    display: block;
  }
  .recruit-left{
    width: 100%;
    margin-bottom: 14px;
  }
  .recruit-right{
    width:100%;
  }
}



/* タブレット */
@media screen and (min-width:600px) and (max-width:860px){
  .recruit-box{
    display: block;
  }
  .recruit-left{
    width: 100%;
    margin-bottom: 14px;
  }
  .recruit-right{
    width: 100%;
  }
}
</style>