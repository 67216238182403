<template>
  <div class="_base">
    <Header :isOpen="isOpenNavi" :pageTitle="pageTitle" @open="openNavi()" :currentPosition="_currentPosition" :backPath="`JournalLineup`"></Header>
    <Navi :isOpen="isOpenNavi" @close="closeNavi"></Navi>
    <!-- <GoTop></GoTop> -->
    <div v-if="isPublic" class="public">

      <div v-if="journal.style === 'normal' || journal.style === 'plural'" class="style-normal">
        <div class="title">
          <h2>{{journal.title}}</h2><p class="">{{journal.subTitle}}</p>
        </div>
        <img v-if="journal.style === 'normal'" :src="journal.imageUrl" :alt="journal.title" class="image">
        <div v-if="journal.style === 'plural'" class="top-images">
          <div  class="top-image-container">
            <img v-for="image in journal.imageObjects" :key="image.order"  :src="image.url" alt="" :class="{'top-image-active': imageMark === image.order}" class="top-image">
          </div>
          <div class="image-switch-container">
            <div class="image-switch">
              <div v-for="image in journal.imageObjects" :key="image.order" @click="imageChange(image.order)" class="switch"><div class="switch-line" :class="{'switch-active': imageMark === image.order}"></div></div>
            </div>
          </div>
        </div>
        <div  class="content" >
          <div class="container">
            <div class="_left">
              <div class="_left-content">
              </div>
            </div>
            <div class="_right">
              <div class="_right-content">
                <p class=" _text">{{journal.text}}</p>
                <a :href="journal.link" target="_blank" class="journal-link">{{journal.link}}</a>
                <p class="journal-day _eg">{{journal.month}} {{journal.day}} {{journal.year}}</p>
                <div class="move">
                  <div v-if="!isFirstJournal" @click="goBack()" class="move-box" >
                    <img src="@/assets/images/arrow-l.png" alt="">
                    <p class="move-text _eg">Back</p>
                  </div>
                  <div v-if="!isLastJournal" @click="goNext()" class="move-box">
                    <p class="move-text _eg">Next</p>
                    <img src="@/assets/images/arrow-r.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer :pageTitle="pageTitle"></Footer>
      </div>

      <div v-else-if="journal.style === 'haiku'" class="style-haiku" :style="{'background-image': 'url(' + backgroundImage +')'}">
        <p class="haiku-title haiku-position" :class="{'appear': isShownHaikuTitle}" :style="{'color': journal.color}">{{journal.title}}</p>
        <p class="haiku-text haiku-position" :class="{'appear': isShownHaikuText}" :style="{'color': journal.color}">{{journal.text}}</p>
        <Footer :pageTitle="pageTitle" :style="'include'"></Footer>
      </div>

    </div>
    <div v-else class="unpublic">
      <div class="title">
        <h2>この記事は非公開です。</h2>
      </div>
      <Footer :pageTitle="pageTitle"></Footer>
    </div>
    
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'
// import GoTop from '@/components/GoTop.vue'
import Mixin from '@/mixin/mixin.js'
import Journal from '@/class/journal.js'
import {db} from '@/firebase/index.js'



export default {
  components:{
    Header,
    Navi,
    Footer,
    // GoTop
  },
  props:{

},
  data(){
    return{
      pageTitle: "Journal",
      isOpenNavi: false,

      journal: new Journal(),
      vol: null,
      isLastJournal: false,
      isFirstJournal: false,
      nextJournalId: null,
      isPublic: true,
      lastJournalOrder: null,

      backgroundImage: null,
      currentPosition: null,

      // 俳句用
      isShownHaikuTitle: false,
      isShownHaikuText: false,
      isShownHaikuNavi: false,

      // 複数画像用
      imageMark: 0,
      timer:null,

      isSafari: false, //created で safariかどうか判別
    }
  },
  mixins:[
    Mixin
  ],
  beforeCreate(){
    
  },
  async created(){
    await db.collection('journal').orderBy('order', 'desc').limit(1).get()
    .then((querySnapShot)=>{
      if(!querySnapShot.empty){
        querySnapShot.forEach((doc)=>{
          this.lastJournalOrder = doc.data().order
        })
      }
    })
    db.collection('journal').doc(this.$route.params.id).get()
    .then((doc)=>{
      this.journal = doc.data()
      this.backgroundImage = this.journal.imageUrl
      this.imageObjects = this.journal.imageObjects
    })
    .catch((error)=>{
      console.log(error.message)
    })
   
    // ブラウザチェック safariならトップが自動移り変わりをさせない
    var userAgent = window.navigator.userAgent.toLowerCase();
    if(userAgent.indexOf('msie') != -1 ) {
        this.isSafari = false
    } else if(userAgent.indexOf('edge') != -1) {
        this.isSafari = false
    } else if(userAgent.indexOf('chrome') != -1) {
        this.isSafari = false
    } else if(userAgent.indexOf('safari') != -1) {
        console.log('ブラウザはSafariです')
        this.isSafari = true
    } else if(userAgent.indexOf('firefox') != -1) {
        this.isSafari = false
    }else{
      this.isSafari = false
    }
    if(!this.isSafari){
      this.timer = setTimeout(this.setImage, 6000)
    }
  },
  mounted(){

  },
  beforeDestory(){
    
  },
  methods:{
    // ナビ用
    openNavi(){
      this.isOpenNavi = true
      this._stop(true)
    },
    closeNavi(){
      this.isOpenNavi = false
      this._stop(false)
    },
    journalSetup(){

    },
    goBack(){
      db.collection('journal').where('order', '==', this.journal.order - 1).get()
      .then((querySnapShot)=>{
        querySnapShot.forEach(doc => {
          this.journal = doc.data()
          this.backgroundImage = this.journal.imageUrl
        })
        this.$router.push({name: 'Journal', params: {id: this.journal.id}, query:{from: "back"}})
      })
    },
    goNext(){
      db.collection('journal').where('order', '==', this.journal.order +1).get()
      .then((querySnapShot)=>{
        querySnapShot.forEach(doc => {
          this.journal = doc.data()
          this.backgroundImage = this.journal.imageUrl
        })
        this.$router.push({name: 'Journal', params: {id: this.journal.id}, query:{from: "next"}})
      })   
    },
    imageChange(mark){
      this.imageMark = mark
    },
    setImage(){
      let imageLength = this.journal.imageObjects.length
      if(this.imageMark < imageLength - 1 ){
        this.imageMark += 1
      }else{
        this.imageMark = 0
      }
    }


  },
  watch: {
    journal:{
      immediate: true,
      deep: true,
      handler: function(){
        
        if(this.journal.isPublic){
          // 公開の場合 諸々のセットアップ
          if(this.journal.order === 1){
            this.isFirstJournal = true
          }else{
            this.isFirstJournal = false
          }
          if(this.journal.order === this.lastJournalOrder){
            this.isLastJournal = true
          }else{
            this.isLastJournal = false
          }
        }else{
          // 非公開の場合 遷移先選定
          if(this.journal.order === this.lastJournalOrder){
            // 非公開かつ最新記事の場合
            if(this.journal.order === 1){
              // かつ最初の投稿の場合
              this.$router.push({name: 'Home'})
            }else{
              //Next押されてきた場合
              if(this.$route.query.from === "next"){
                this.isPublic = false
              }else{
                db.collection('journal').where('order', '==', this.journal.order - 1).get()
                .then((querySnapShot)=>{
                  querySnapShot.forEach(doc => {
                    this.journal = doc.data()
                  })
                  this.imageMark = 0
                  this.$router.push({name: 'Journal', params:{id: this.journal.id}})
                })
              }
            }
          }else{
            // 非公開かつ最新ではない場合
            if(this.$route.query.from === "back"){
              if(this.journal.order === 1){
                this.isPublic = false
              }else{
                
                db.collection('journal').where('order', '==', this.journal.order - 1).get()
                .then((querySnapShot)=>{
                  querySnapShot.forEach(doc => {
                    this.journal = doc.data()
                  })
                  this.imageMark = 0
                  this.$router.push({name: 'Journal', params:{id: this.journal.id}})
                })
              }

            }else if(this.$route.query.from === "next"){
              
              db.collection('journal').where('order', '==', this.journal.order + 1).get()
              .then((querySnapShot)=>{
                querySnapShot.forEach(doc => {
                  this.journal = doc.data()
                })
                this.imageMark = 0
                this.$router.push({name: 'Journal', params:{id: this.journal.id}})
              })
            }else{
              this.isPublic = false
            }

          }
        }
      }
    },
    // 俳句用のスクロールアクション
    _currentPosition:{
      handler: function(){
        if(window.innerWidth > 600){
          // PC
          if(this._currentPosition < 150 ){
            this.isShownHaikuTitle = false
          }else if(this._currentPosition >= 150 && this._currentPosition < 800){
            this.isShownHaikuTitle = true
          }else if(this._currentPosition >= 800 && this._currentPosition < 1800){
            this.isShownHaikuTitle = false
            this.isShownHaikuText = false
          }else if(this._currentPosition >= 1800 && this._currentPosition < 4000){
            this.isShownHaikuText = true
          }else if(this._currentPosition >= 4000 && this._currentPosition < 5000){
            this.isShownHaikuText = false
          }
        }else{
          // SP
          if(this._currentPosition < 200 ){
            this.isShownHaikuTitle = false
          }else if(this._currentPosition >= 200 && this._currentPosition < 800){
            this.isShownHaikuTitle = true
          }else if(this._currentPosition >= 800 && this._currentPosition < 1800){
            this.isShownHaikuTitle = false
            this.isShownHaikuText = false
          }else if(this._currentPosition >= 1800 && this._currentPosition < 4000){
            this.isShownHaikuText = true
          }else if(this._currentPosition >= 4000 && this._currentPosition < 5000){
            this.isShownHaikuText = false
          }
        }
      }
    },
    imageMark:{
      handler: function(){
        if(!this.isSafari){
          clearTimeout(this.timer)
          this.timer = setTimeout(this.setImage, 6000)
        }
      },
    },


  },
  computed:{

  },

}
</script>

<style scoped>
.title{
  margin-left: 42%;
  display: flex;
  padding: 180px 0 40px;
}
.title h2{
  font-size: 2.0rem;
  letter-spacing: 1px;
}
.title p{
  font-size: 1.4rem;
  color: var(--gray);
  -webkit-transform: translate(15px, 7px);
  transform: translate(15px, 7px);
}
.image{
  width: 100%;
  height: 600px;
  object-fit: cover;
}




/* 複数画像用 */
.top-images{
  width: 100vw;
  height: 600px;
  position: relative;
}
.top-image-container{
  width: 100%;
  height: 100%;
  background-color: var(--black);
  position: relative;
  top: 0;
  left: 0;
  z-index: 10;
}
.top-image{
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 1.5s;
}
.top-image-active{
  opacity: 1;
}
.image-switch-container{
  position: absolute;
  right: 40px;
  bottom: 40px;
  width: auto;
  height: 10px;
  z-index: 20;
}
.image-switch{
  display: flex;
  justify-content: space-between;
  width: auto;
  height: 1px;

}
.switch{
  width: 60px;
  height: 40px;
  margin: 0 3px;
}
.switch-line{
  height: 1px;
  background-color: var(--white);
  -webkit-transform: translate(0, 20px);
  transform: translate(0, 20px);
}
.switch-active{
  background-color: #808080;
}


.content{
  width: 100%;
  padding: 40px 0 100px;
}
.container{
  width: 100%;
  padding-bottom: 200px;
  display: flex;
}
.journal-link{
  margin-top: 20px;
  display: block;
  font-size: 1.4rem;
  transition: 0.3s;
}
.journal-link:hover{
  opacity: 0.6;
}
.journal-day{
  color: var(--gray);
  font-size: 1.5rem;
  margin: 40px 0 50px ;
}
.move{
  display: flex;
  justify-content: space-between;
  width: 170px;
}
.move-box{
  display: flex;
  justify-content: space-between;
  width: 50px;
  transition: 0.3s;
}
.move-box:hover{
  filter: brightness(10%);
}
.move-box img{
  width: 15px;
  height: 15px;
}
.move-text{
  color: var(--gray);
  font-size: 1.4rem;
  line-height: 15px;
}
.unpublic{
  padding-top: 100px;
}

/* 俳句用 */
.style-haiku{
  width: 100%;
  height: 4000px;
  background-position: center;
  background-size: cover;
}
.haiku-position{
  position: fixed;
  top: 50%;
  left:  50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

}
.haiku-title{
  font-size: 2.2rem;
  letter-spacing: 3px;
  opacity: 0;
  transition: 1.5s;
}
.haiku-text{
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  white-space: pre-wrap;
  font-size: 1.8rem;
  letter-spacing: 3px;
  opacity: 0;
  transition: 1.5s;
}
.appear{
  opacity: 1;
}
@media screen and (max-width:599px){

  .title{
    width: 90%;
    margin: 0 auto;
    padding: 120px 0 40px;
    flex-direction: column;
  }
  .title p{
    -webkit-transform: translate(0px, -6px);
    transform: translate(0px, -6px);
  }
  .container{
    width: 90%;
    margin: 0 auto;
    display: block;
    padding-bottom: 100px;
    
  }
  .image{
    height: 250px;
  }
  .unpublic{
  padding-top: 0px;
  }


  .top-images{
    height: 250px;
  }
  .image-switch-container{
    right: 20px;
    bottom: 30px;
  }
  .image-switch{
    margin: 0 0px 0 auto;
  }
  .switch{
    width: 30px;
    height: 40px;
    margin: 0 3px;
  }
}



/* タブレット */
@media screen and (min-width:600px) and (max-width:860px){

}
/* タブレット */
@media screen and (min-width:1200px) {
  .image{
    height: 700px;
  }
  .top-images{
    height: 700px;
  }

}
</style>