<template>
  <div class="m_base">
    <ul>
      <li v-for="journal in journals" :key="journal.id" class="container">
        <router-link :to="{name: 'Journal', params:{id: journal.id}}" class="left">
          <p class=" _eg">{{journal.month}} {{journal.day}} {{journal.year}}</p>
          <p class="" :class="{'m_unpublic': !journal.isPublic}">{{journal.title.substr(0, 20)}}</p>
        </router-link>
        <router-link :to="{name: 'ManagerEditJournal', params:{id: journal.id}}" class="edit _eg">Edit</router-link>
      </li>
    </ul>
    <router-link :to="{name: 'ManagerCreateJournal'}" class="m_go _eg">Create New Journal</router-link>
    <router-link :to="{name: 'ManagerOrderJournal'}" class="m_go _eg">Change Journal Order</router-link>
  </div>
</template>

<script>

import "@/css/manager.css"
import {db} from '@/firebase/index.js'

export default {
  components:{

  },
  props:{
    is_sign: Boolean
  },
  data(){
    return{
      pageName: "journal",
      isError: false,

      journals:[],

    }
  },
  beforeCreate(){

  },
  created(){
    this.$store.commit('managerPageName', this.pageName)
    if(!this.is_sign){
      this.$router.push({name: 'Manager'})
    }
    db.collection('journal').orderBy('order', 'desc').get()
    .then((querySnapShot)=>{
      querySnapShot.forEach(doc => {
        this.journals.push(doc.data())
        
      });
    })

  },
  mounted(){

  },
  methods:{

    
  },
  watch: {

  },
  computed:{

  },

}
</script>


<style scoped>
ul {
  margin-bottom: 50px;
}
.container{
  display: flex;
  justify-content: space-between;
  height: 20px;
  margin-bottom: 30px;
}
.container:hover{
  box-sizing: border-box;
  border-bottom: 1px solid var(--black);
}
.left{
  display: flex;
}
.left p{
  line-height: 20px;
  margin-right: 10px;
}
.unpublic{
  color: brown;
}

.edit{
  background-color: var(--gray-sub);
  color: var(--white);
  display: block;
  width: 50px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 1.2rem;
}


@media screen and (min-width:600px){

}

</style>