export default class Work{

  id = null 
  title = ""
  text = ""
  textEg = ""
  client = ""
  location = ""
  completion = null
  contents = ""
  description = ""
  imageObjectsV = []
  imageObjects = []
  mainContent = ""
  shortDescription = ""
  url = ""
  isPublic = true
  createdAt = null
  updatedAt = null
  updateCounter = 0

  constructor(work){
    if(work == null){return}
    this.id = work.id
    this.title = work.title
    this.text = work.text
    this.textEg = work.textEg
    this.client = work.client
    this.location = work.location
    this.completion = work.completion
    this.contents = work.contents
    this.description = work.description
    this.imageObjectsV = work.imageObjectsV
    this.imageObjects = work.imageObjects
    this.mainContent = work.mainContent
    this.shortDescription = work.shortDescription
    this.url = work.url
    this.isPublic = work.isPublic
    this.createdAt = work.createdAt
    this.updatedAt = work.updatedAt
    this.updateCounter = work.updateCounter
  }

}