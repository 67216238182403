<template>
  <div @click="go()" class="arrow" :class="{'hide': isHide}"></div>
</template>


<script>


export default {
  props:{
    currentPosition: Number
  },
  data(){
    return{
      isHide: true,
      positionY:0
    }
  },
  created(){
  },
  mounted(){

  },
  beforeUnmount(){
  },

  methods:{
    go(){
      const duration = 600;  // 移動速度（1秒で終了）
      const interval = 25;    // 0.025秒ごとに移動
      const step = -window.scrollY / Math.ceil(duration / interval); // 1回に移動する距離
      const timer = setInterval(() => {

          window.scrollBy(0, step);   // スクロール位置を移動

          if(window.scrollY <= 0) {

              clearInterval(timer);

          }

      }, interval);
    }
  },
  computed: {
    
  },
  watch:{
    currentPosition:{
      handler: function(){
        if(500 < window.scrollY){
          this.isHide = false
        }else{
          this.isHide = true
        }

      },
    }
  }
}
</script>

<style scoped>
.arrow{
  position: fixed;
  z-index: 40;
  bottom: 130px;
  right: 60px;
  width: 30px;
  height: 30px;
  border-right: 1px solid var(--green);
  border-top: 1px solid var(--green);
  opacity: 1;
  transition: 0.6s;
-webkit-transform: translate(0, 0px) rotate(-45deg);
  transform: translate(0, 0px) rotate(-45deg);
}
.hide{
  z-index: -10;
  opacity: 0;
  -webkit-transform: translate(0, 100px) rotate(-45deg);
  transform: translate(0, 50px) rotate(-45deg);
  transform: translate(0, 50px) rotate(-45deg);
}

@media screen and (max-width:599px) {
.arrow{
    width: 20px;
  height: 20px;
    bottom: 70px;
  right: 6.2%;
}
  
}

/* 90%調整用 */
@media screen and (min-width:600px) and (max-width:1100px){



}
</style>