<template>
  <div class="m_base">

    <p class="m_title">New Experiment</p>

    <div class="m_tag">
      <div class="m_tag-box">
        <input type="radio" id="public" :value="true" v-model="element.isPublic">
        <label for="public" class="m_tag-label"><span v-if="element.isPublic" class="m_tag-active"></span></label>
        <label for="public" class="m_tag-text _eg">Public</label>
      </div>
      <div class="m_tag-box">
        <input type="radio" id="unpublic" :value="false" v-model="element.isPublic">
        <label for="unpublic" class="m_tag-label"><span v-if="!element.isPublic" class="m_tag-active"></span></label>
        <label for="unpublic" class="m_tag-text _eg">Unpublic</label>
      </div>
    </div>

    <div class="m_input-box m_box">
      <label for="title" class="m_input-label _f4 _eg">Title</label>
      <input id="title" v-model="element.title" placeholder="" class="m_input _f4 _eg">
    </div>

    <div class="m_tag">
      <p class="m_tag-box">Page Type:</p>
      <div class="m_tag-box">
        <input type="radio" id="collection" :value="'collection'" v-model="element.type">
        <label for="collection" class="m_tag-label"><span v-if="element.type === 'collection'" class="m_tag-active"></span></label>
        <label for="collection" class="m_tag-text _eg">Collection</label>
      </div>
      <div class="m_tag-box">
        <input type="radio" id="experiment" :value="'experiment'" v-model="element.type">
        <label for="experiment" class="m_tag-label"><span v-if="element.type === 'experiment'" class="m_tag-active"></span></label>
        <label for="experiment" class="m_tag-text _eg">Experiment</label>
      </div>
    </div>

    <div class="m_input-box m_box">
      <label for="order" class="m_input-label _f4 _eg">Order</label>
      <input id="order" type="number" v-model="element.order" placeholder="" class="m_input _f4 _eg">
    </div>

    <div class="image-upload">
      <label for="imageTop" class="m_input-label _f4 _eg">Top Photos</label>
      <div class="image-upload-container">
        <div v-for="(imageObject, index) in topImageObjects"
              :key="index"
              class="box preview">
          <div class="preview-image" :style="{'background-image': 'url(' + imageObject.url +')'}"></div>
          <img @click="deleteImageObject(index, topImageObjects)" src="@/assets/images/cross-button-w.png" alt="" class="image-delete">
        </div>
        <div class="box image-upload-button">
          <input type="file" ref="previewV" @change="setImage($event, topImageObjects)" accept="image/png, image/jpeg" id="imageTop" class="_hide">
          <label  v-if="topImageObjects.length <= 2" for="imageTop" class="image-upload-icon">
            <img  src="@/assets/images/camera-g.png" alt="カメラアイコン" class="camera-icon">
          </label>
        </div>
      </div>
    </div>

    <div class="m_input-box m_box">
      <label for="shotText" class="m_input-label _f4 _eg">Short Text</label>
      <textarea id="shotText" v-model="element.shotText" placeholder="" class="m_textarea"></textarea>
    </div>

    <div class="m_input-box m_box">
      <label for="firstText" class="m_input-label _f4 _eg">First Text</label>
      <textarea id="firstText" v-model="element.firstText" placeholder="" class="m_textarea"></textarea>
    </div>

    <div class="m_input-box m_box">
      <label for="secondText" class="m_input-label _f4 _eg">Second Text</label>
      <textarea id="secondText" v-model="element.secondText" placeholder="" class="m_textarea"></textarea>
    </div>

    <div class="m_input-box m_box">
      <label for="movie" class="m_input-label _f4 _eg">Movies</label>
      <div class="movieInput">
        <input type="text" id="movie" v-model="movieText" placeholder="VIMEO Video ID" class="movieInput_input  _f4 _eg">
        <button @click="addMovie()" :disabled="movieText === null" class="movieInput_add" :class="{'validMovie':movieText !== null}">+</button>
      </div>
      <div class="movieList">
        <div v-for="(movieId, index) in element.movieIds" :key="movieId" class="movieList_box">
          <iframe :id="index" :src="movieUrl(movieId)"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen class="movieList_preview" width="120" height="67.5"></iframe>
          <div @click="deleteMovie(index)" class="movieList_delete">X</div>
        </div>
      </div>
    </div>

    <div class="image-upload">
      <label for="image" class="m_input-label _f4 _eg">Sub Photos</label>
      <div class="image-upload-container">
        <div v-for="(imageObject, index) in imageDisp"
              :key="index"
              draggable="true"
              @dragstart="dragstart(imageObject, $event)"
              @dragenter="dragenter(imageObject)"
              @dragover.stop.prevent="dragover"
              @dragend.stop.prevent="dragend"
              class="box preview">
          <div class="preview-image" :style="{'background-image': 'url(' + imageObject.url +')'}"></div>
          <img @click="deleteImageObject(index, subImageObjects)" src="@/assets/images/cross-button-w.png" alt="" class="image-delete">
        </div>
        <div class="box image-upload-button">
          <input type="file" ref="preview" @change="setImage($event, subImageObjects)" accept="image/png, image/jpeg" id="image" class="_hide">
          <label for="image" class="image-upload-icon">
            <img  src="@/assets/images/camera-g.png" alt="カメラアイコン" class="camera-icon">
          </label>
        </div>
      </div>
    </div>

    <div class="m_tag">
      <p class="m_tag-box"> Is Top of Sub Photo Inside Text:</p>
      <div class="m_tag-box">
        <input type="radio" id="subPhotoTrue" :value="true" v-model="element.isSubImage">
        <label for="subPhotoTrue" class="m_tag-label"><span v-if="element.isSubImage" class="m_tag-active"></span></label>
        <label for="subPhotoTrue" class="m_tag-text _eg">Yes</label>
      </div>
      <div class="m_tag-box">
        <input type="radio" id="subPhotoFalse" :value="false" v-model="element.isSubImage">
        <label for="subPhotoFalse" class="m_tag-label"><span v-if="!element.isSubImage" class="m_tag-active"></span></label>
        <label for="subPhotoFalse" class="m_tag-text _eg">No</label>
      </div>
    </div>

    <div class="image-upload">
      <label for="imageV" class="m_input-label _f4 _eg">Vertical Photos</label>
      <div class="image-upload-container">
        <div v-for="(imageObject, index) in verticalImageObjects"
              :key="index"
              class="box preview">
          <div class="preview-image" :style="{'background-image': 'url(' + imageObject.url +')'}"></div>
          <img @click="deleteImageObject(index, verticalImageObjects)" src="@/assets/images/cross-button-w.png" alt="" class="image-delete">
        </div>
        <div class="box image-upload-button">
          <input type="file" ref="previewV" @change="setImage($event, verticalImageObjects)" accept="image/png, image/jpeg" id="imageV" class="_hide">
          <label  v-if="verticalImageObjects.length <= 1" for="imageV" class="image-upload-icon">
            <img  src="@/assets/images/camera-g.png" alt="カメラアイコン" class="camera-icon">
          </label>
        </div>
      </div>
    </div>

    <div class="m_tag">
      <p class="m_tag-box">Vertical Photos Position:</p>
      <div class="m_tag-box">
        <input type="radio" id="top" :value="'top'" v-model="element.imageVPosition">
        <label for="top" class="m_tag-label"><span v-if="element.imageVPosition === 'top'" class="m_tag-active"></span></label>
        <label for="top" class="m_tag-text _eg">Top</label>
      </div>
      <div class="m_tag-box">
        <input type="radio" id="bottom" :value="'bottom'" v-model="element.imageVPosition">
        <label for="bottom" class="m_tag-label"><span v-if="element.imageVPosition === 'bottom'" class="m_tag-active"></span></label>
        <label for="bottom" class="m_tag-text _eg">Bottom</label>
      </div>
    </div>

    <div class="m_input-box m_box">
      <label for="subTitle" class="m_input-label _f4 _eg">Date</label>
      <div class="m_select-container">
        <select v-model="exportPreparation.date.year"  class="m_select-2" >
          <option :value="null" disabled >年</option>
          <option v-for="n in 4" :key="n" :value="n + currentYear - 3" :style="{'color': '#131313'}" >{{n + currentYear - 3}}年</option>
        </select>
        <select v-model="exportPreparation.date.month" class="m_select-2" >
          <option :value="null" disabled >月</option>
          <option v-for="n in 12" :key="n" :value="n" :style="{'color': '#131313'}" >{{n}}月</option>
        </select>
        <select v-model="exportPreparation.date.day"  class="m_select-2" >
          <option :value="null" disabled >日</option>
          <option v-for="n in daysMax" :key="n" :value="n" :style="{'color': '#131313'}" >{{n}}日</option>
        </select>
      </div>
    </div>

    <div class="m_input-box m_box">
      <label for="url" class="m_input-label _f4 _eg">Item URL</label>
      <input id="url" v-model="element.url" placeholder="" class="m_input _f4 _eg">
    </div>

    <button v-if="mode === 'create'" @click="saveImageAndCreate()" :disabled="v$.element.$invalid || isButtonLock"  :class="{'m_invalid-button': v$.element.$invalid}" class="m_submit _eg">Create</button>
    <button v-if="mode === 'edit'" @click="edit()" :disabled="v$.element.$invalid || isButtonLock"  :class="{'m_invalid-button': v$.element.$invalid}" class="m_submit _eg">Edit</button>
    <button v-if="mode === 'edit'" @click="deleteElement()" :disabled="isButtonLock" class="m_delete _eg">Delete</button>
    
    <p v-for="message in errorMeeages" :key="message" class="m_error">{{ message }}</p>
    
  </div>
</template>

<script>

import "@/css/manager.css"
import { db, storage, currentTime} from '@/firebase/index.js'
import Element from "@/class/element.js"
import imageCompression from 'browser-image-compression'
import  useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
// import draggable from "vuedraggable"

export default {
  components:{
    // draggable
  },
  props:{
    is_sign: Boolean
  },
  data(){
    return{
      pageName: "element",
      errorMessages: [],
      isButtonLock: false,
      mode: "create", //"create" or "edit"

      element: new Element(),
      compressedImage: null,
      previewImageUrl: null,
      
      topImageObjects:[],

      subImageObjects:[],
      draggingItem: null, // ドラッグ中の要素を保持するための変数

      verticalImageObjects:[],

      imageTrashBox:[],

      currentYear:null,
      daysMax: null,
      exportPreparation:{
        date:{
          year: null,
          month: null,
          day: null
        },
      },

      movieText: null,
    }
  },
  setup(){
    return { v$: useVuelidate()}
  },
  beforeCreate(){

  },
  created(){
    this.$store.commit('managerPageName', this.pageName)
    if(this.$route.params.id == null){
      this.mode = "create"
      this.element = new Element()
    }else{
      this.mode = "edit"
      db.collection('element').doc(this.$route.params.id).get()
      .then((doc)=>{
        this.element = new Element(doc.data())
        this.topImageObjects = this.element.topImageObjects
        this.subImageObjects = this.element.subImageObjects
        this.verticalImageObjects = this.element.verticalImageObjects
        this.setImageObjects(this.topImageObjects)
        this.setImageObjects(this.subImageObjects)
        this.setImageObjects(this.verticalImageObjects)

        this.exportPreparation.date.day = this.element.day
        this.exportPreparation.date.month = this.element.month
        this.exportPreparation.date.year = this.element.year
        // this.exportPreparation.date.month = this.element.year
      })
    }

    const date = new Date()
    this.currentYear = date.getFullYear()
  },
  mounted(){

  },
  methods:{
    setImageObjects(imageObjects){
      if(imageObjects.length > 0){
        imageObjects.sort((a,b)=>{
          if(a.order < b.order){return -1}
          if(a.order > b.order){return 1}
          return 0
        })
      }
    },

    async saveImageAndCreate(){
      this.isButtonLock = true
      this.errorMeeages = []

      this.element.createdAt = currentTime
      this.element.updatedAt = currentTime
     
      this.element.month = this.exportPreparation.date.month
      this.element.day = this.exportPreparation.date.day
      this.element.year = this.exportPreparation.date.year

      let docRef = db.collection('element').doc()
      this.element.id = docRef.id

      this.element.topImageObjects = await this.uploadImages(this.element.id, this.topImageObjects, 'top')
      this.element.subImageObjects = await this.uploadImages(this.element.id, this.subImageObjects, 'sub')
      this.element.verticalImageObjects = await this.uploadImages(this.element.id, this.verticalImageObjects, 'vertical')

      docRef.set({...this.element})
      .then(()=>{
        this.isButtonLock = false
        this.$router.push({name: 'ManagerElement'})
      })
      .catch((error)=>{
        console.log(error.message)
        this.isButtonLock = false
        this.errorMessages.push("保存に失敗しました。")
      })
    },

    async edit(){
      this.isButtonLock = true
      this.errorMessages = []
      this.element.updateCounter ++

      this.element.month = this.exportPreparation.date.month
      this.element.day = this.exportPreparation.date.day
      this.element.year = this.exportPreparation.date.year
      
      this.element.topImageObjects = await this.uploadImages(this.element.id, this.topImageObjects, 'top')
      this.element.subImageObjects = await this.uploadImages(this.element.id, this.subImageObjects, 'sub')
      this.element.verticalImageObjects = await this.uploadImages(this.element.id, this.verticalImageObjects, 'vertical')

      this.deleteImages(this.imageTrashBox)

      this.element.updatedAt = currentTime
      db.collection('element').doc(this.element.id).update({...this.element})
      .then(()=>{
        this.isButtonLock = false
        this.$router.push({name: 'ManagerElement'})
      })
      .catch((error)=>{
        console.log(error.message)
        this.isButtonLock = false
        this.errorMessages.push('データの更新に失敗しました')
        
      })
    },

    async deleteElement(){
      this.deleteImages(this.element.topImageObjects)
      this.deleteImages(this.element.subImageObjects)
      this.deleteImages(this.element.verticalImageObjects)

      db.collection('element').doc(this.element.id).delete()
      .then(()=>{
        this.$router.push({name: 'ManagerElement'})
      })
      .catch((error)=>{
        console.log(error.message)
        this.errorMessages.push('画像の削除は成功しましたが、データ削除に失敗しました')
      })
    },

    async setImage(event, imageObjects){
      const originImage = event.target.files[0]
      const options = {
        maxSizeMB: 0.7, //最大ファイルサイズ
        maxWidthOrHeight: 2000, //最大縦横値
      }
      const compressedImage = await imageCompression(originImage, options)
      imageObjects.push({url: URL.createObjectURL(originImage), file: compressedImage})
      imageObjects.forEach((object, index)=>{
        object.order = index
      })
    },

    deleteImageObject(index, imageObjects){
      if(imageObjects[index].name){
        const imageTrash = imageObjects[index]
        this.imageTrashBox.push(imageTrash)
        imageObjects.splice(index, 1)
      }else{
        imageObjects.splice(index, 1)
      }
      imageObjects.forEach((object, index)=>{
        object.order = index
      })
    },

    dragstart(object, e) {
      this.draggingItem = object; // ドラッグ中の要素を保持
      e.dataTransfer.effectAllowed = 'move'; // 移動モードに設定
      e.target.style.opacity = 0.5; // ドラッグ中要素のスタイルを変更
    },
    dragenter(object) {
      // ドラッグ中の要素とドラッグ先の要素の表示順を入れ替える
      [object.order, this.draggingItem.order] = [this.draggingItem.order, object.order];
    },
    dragover(e) {
      e.dataTransfer.dropEffect = 'move'; // 移動モードに設定
    },
    dragend(e) {
      e.target.style.opacity = 1; // ドラッグ中要素のスタイルを変更（元に戻す）
      this.draggingItem = null; // ドラッグ中の要素をクリア
    },

    uploadImages(elementId, imageObjects, imageType){
      if(imageObjects.length > 0){
        const imageObjectsToReturn = []
        const promises = []
        imageObjects.forEach((imageObject, index)=>{
          imageObject.order = index
          if(imageObject.name){
            imageObjectsToReturn.push(imageObject)
          }else{
            const fileName = `element/${elementId + "_" + imageType  + "_" + String(this.element.updateCounter) + "_" + String(index)}`
            promises.push(storage.ref().child(fileName).put(imageObject.file)
            .then( async (snapShot)=>{
              const url = await snapShot.ref.getDownloadURL()
              imageObjectsToReturn.push({url: url, order: imageObject.order, name: fileName})
            }))
          }
        })
        return Promise.all(promises)
        .then(()=>{return imageObjectsToReturn})
        .catch(error=>{
          console.log(error.message)
          this.errorMessages.push(`画像のアップロードに失敗しました：${imageType}`)
        })
      }else{
        return []
      }
    },

    deleteImages(imageTrashBox){
      imageTrashBox.forEach((object)=>{
        storage.ref().child(object.name).delete()
        .then(()=>{
        })
        .catch((error)=>{
          console.log(error.message)
          this.errorMessages.push(`${object.name}の削除に失敗しました。`)
        })
      })
    },

    addMovie(){
      this.element.movieIds.push(this.movieText)
      this.movieText = null
    },
    movieUrl(movieId){
      return `https://player.vimeo.com/video/${String(movieId)}?badge=0&amp;autopause=0&amp;player_id=0&amp;`
    },
    deleteMovie(index) {
      this.element.movieIds.splice(index, 1)
    },
    
    setDate(){
      if(this.exportPreparation.date.day !== null){
        this.element.year = this.exportPreparation.date.year
        this.element.month = this.$store.getters['getMonths'](this.exportPreparation.date.month)
        this.element.day = this.exportPreparation.date.day
      }
    },

  },

  computed:{
    imageDisp() {

      return [...this.subImageObjects].sort((a, b) => a.order - b.order)
    },


  },
  watch: {
    'exportPreparation.date.year': function(){
      this.daysMax = new Date(this.exportPreparation.date.year, this.exportPreparation.date.month, 0).getDate()
    },
    'exportPreparation.date.month': function(){
      this.daysMax = new Date(this.exportPreparation.date.year, this.exportPreparation.date.month, 0).getDate()
    },
    imageDisp:{
      deep: true,
      handler: function (){
        return this.subImageObjects.sort((a, b) => a.order - b.order)
      }
    },
  },
  validations(){
    return{
      element:{
        title:{
          required
        },
      },

    }
  },
}
</script>


<style scoped>
.image{
  width: 100%;
}

.image-upload{
  margin-bottom: 22px;

}
.image-upload-label{
  font-size: 1.3rem;
  color: var(--gray-hi);
}
.image-upload-container{
  margin-top: 5px;
  display: flex;
  white-space:nowrap;
  overflow-x: auto;
}
.box{
  margin-right: 10px;
  display: block;
  width: 100px;  
}
.image-upload-icon{
  display: block;
  background-color: var(--white);
  width: 100px;
  height: 100px;
  position: relative;
  box-sizing: border-box;
    border: 1px solid #b3a69f;
}

.camera-icon{
  position: absolute;
  width: 34px;
  height: 34px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.image-upload-icon p{
  color: var(--gray-hi);
  position: absolute;
  top: 0px;
  left: 5px;
  font-weight: 700;
}
.preview{
  position: relative;
}
.preview-image{
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: cover;
}
.image-delete{
  width: 18px;
  height: 18px;
  position: absolute;
  top: 4px;
  right: 4px;
}
.movieInput{
  display: flex;
  justify-content: space-between;
}
.movieInput_input{
  width: calc(100% - 40px);
  display: block;
  border: 1px solid #b3a69f;
  height: 30px;
  padding: 0 10px;
  color: var(--black);
}
.movie_input::placeholder{
  color: var(--gray);
}
.movieInput_add{
  width: 30px;
  height: 30px;
  line-height: 0.9;
  color: var(--white);
  appearance:unset;
  border: none;
  background-color: var(--black);
  font-size: 2.6rem;
  text-align: center;
  opacity: 0.3;
  transition: 0.2s;
}
.validMovie{
  cursor: pointer;
  opacity: 1;
}
.movieList{
  display: flex;
  margin-top: 5px;
}
.movieList_box{
  margin-top: 5px;
  position: relative;
  width: 120px;
  height: 67.5px;
  aspect-ratio: 19/6;
  margin-right: 10px;
}
.movie_preview{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
}
.movieList_delete{
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 20;
  width: 15px;
  height: 15px;
  line-height: 1.5;
  color: var(--white);
  appearance:unset;
  border: none;
  background-color: var(--black);
  cursor: pointer;
  font-size: 1.0rem;
  text-align: center;
  margin-left: 5px;
  font-weight: 700;

}

@media screen and (min-width:600px){

}

</style>