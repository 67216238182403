<template>
  <div class="_base">
    <Header :isOpen="isOpenNavi" :pageTitle="pageTitle" @open="openNavi()" :currentPosition="_currentPosition"></Header>
    <Navi :isOpen="isOpenNavi" @close="closeNavi"></Navi>
    <!-- <GoTop></GoTop> -->
    <div class="h_title">
      <h2>枯葉 - Element【火】【森】</h2>
    </div>
    <div class="h_top-images">
      <div class="h_top-image-container">
        <div class="h_top-image" :class="{'h_top-image-active': imageMark === 'a'}" :style="{ 'background-image': 'url(' + image1 +')'}"></div>
        <div class="h_top-image" :class="{'h_top-image-active': imageMark === 'b'}" :style="{ 'background-image': 'url(' + image2 +')'}"></div>
        <div class="h_top-image" :class="{'h_top-image-active': imageMark === 'c'}" :style="{ 'background-image': 'url(' + image3 +')'}"></div>
      </div>
      <div class="h_image-switch-container">
        <div class="h_image-switch">
          <div @click="imageChange('a')" class="h_switch"><div class="h_switch-line" :class="{'h_switch-active': imageMark === 'a'}"></div></div>
          <div @click="imageChange('b')" class="h_switch"><div class="h_switch-line" :class="{'h_switch-active': imageMark === 'b'}"></div></div> 
          <div @click="imageChange('c')" class="h_switch"><div class="h_switch-line" :class="{'h_switch-active': imageMark === 'c'}"></div></div> 
        </div>
      </div>
    </div>
    <div  class="h_content" >
      <div class="h_container">
        <div class="_left">
          <div class="_left-content">
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <p class=" _text _marginS">
              森に茂っている沢山の葉っぱや花たちは、移ろう季節の中でやがて枯れ落ち、あるいは風や鳥によって落とされ、人に剪定され、様々な理由で「枯葉」になってゆきます。「木」でいる間は一枚一枚、ひと束ひと束、目立つことはなくても、枯葉になると、くるくるしたり、ばりばりしたり、個性がグッと出てきます。
            </p>
            <div class="h_images _marginS">
              <img src="@/assets/images/hinamori/experiment/1/kareha1.jpg" alt="" class="h_image-one _marginSS">
              <div class="h_images-box _marginSS">
                <img src="@/assets/images/hinamori/experiment/1/kareha3.jpg" alt="" class="h_image-two">
                <img src="@/assets/images/hinamori/experiment/1/kareha2.jpg" alt="" class="h_image-two">
              </div>
              <img src="@/assets/images/hinamori/experiment/1/kareha4.jpg" alt="" class="h_image-one">
            </div>
            <!-- <img src="@/assets/images/hinamori/collection/umi.jpg" class="h_image-one _marginS"> -->
            <p class="_text _marginS">
              この枯葉たちが焚き火で燃えゆく時、見た目だけではなく音や香り、火の行方や残照まで全てが異なることに気がつき、その姿を残してゆく方法がないか実験的に模索することにしました。それぞれが刹那に燃えゆき、線香花火のような愛おしい余韻が漂う姿に、ただただ自然の摂理を感じたのです。
            </p>
            <div class="h_movie _marginSS"><iframe id="video1" src="https://player.vimeo.com/video/692116819?h=b78ab30ed8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Scenery.mov"></iframe></div>
            <div class="h_movie _marginSS"><iframe id="video2" src="https://player.vimeo.com/video/692117549?h=94e0a812c2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Scenery.mov"></iframe></div>
            <div class="h_movie "><iframe id="video3" src="https://player.vimeo.com/video/692117053?h=b9c02162d3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Scenery.mov"></iframe></div>
            <p class="h_day _eg">March 26 2022</p>
            <div class="h_move">
              <router-link :to="{name: 'Hinamori2'}" class="h_move-box" >
                <img src="@/assets/images/arrow-l.png" alt="">
                <p class="h_move-text _eg">Experiment</p>
              </router-link >
            </div>

          </div>
        </div>
      </div>
      <Footer :pageTitle="pageTitle"></Footer>
    </div>    
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'
// import GoTop from '@/components/GoTop.vue'
import Mixin from '@/mixin/mixin.js'
// import {db} from '@/firebase/index.js'
// import Player from "@vimeo/player";


export default {
  components:{
    Header,
    Navi,
    Footer,
    // GoTop
  },
  props:{

},
  data(){
    return{
      pageTitle: "Experiment",
      isOpenNavi: false,

      // vol: null,
      // isLastJournal: false,
      // isFirstJournal: false,
      // nextJournalId: null,
      // isPublic: true,

      image1: require('@/assets/images/hinamori/experiment/1/top1.jpg'),
      image2: require('@/assets/images/hinamori/experiment/1/top2.jpg'),
      image3: require('@/assets/images/hinamori/experiment/1/top3.jpg'),
      imageMark: "a",
      timer: null,
    }
  },
  mixins:[
    Mixin
  ],
  beforeCreate(){
    
  },
  created(){
    // db.collection('journal').doc(this.$route.params.id).get()
    // .then((doc)=>{
    //   this.journal = doc.data()
    //   this.backgroundImage = this.journal.imageUrl
    // })
    // .catch((error)=>{
    //   console.log(error.messege)
    // })
    
  },
  mounted(){
      // var iframe = document.querySelector("iframe");
      // var player = new Player(iframe);
      // player.on("play", function() {
      //     console.log("Played the video");
      // });

      // player.on("ended", function() {
      //     console.log("Ended the video");
      // });

      // player.getVideoTitle().then(function(title) {
      //     console.log("title:", title);
      // });
  },
  beforeDestory(){
    
  },
  methods:{
    // ナビ用
    openNavi(){
      this.isOpenNavi = true
      this._stop(true)
    },
    closeNavi(){
      this.isOpenNavi = false
      this._stop(false)
    },

    imageChange(mark){
      clearTimeout(this.timer)
      this.imageMark = mark
    },
    setImage(){
      if(this.imageMark === "a"){
        this.imageMark = "b"
      }else if(this.imageMark === "b"){
        this.imageMark = "c"
      }else if(this.imageMark === "c"){
        this.imageMark = "a"
      }
    },


  },
  watch: {
    imageMark:{
      immediate: true,
      handler: function(){
        clearTimeout(this.timer)
        this.timer = setTimeout(this.setImage, 3000)
      },
    },
  },
  computed:{

  },

}
</script>

<style scoped>


@media screen and (max-width:599px){





}



/* タブレット */
@media screen and (min-width:600px) and (max-width:860px){

}
/* タブレット */
@media screen and (min-width:1200px) {

}
</style>