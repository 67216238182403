<template>
  
  <div ref="refName" class="show" :class="{'appear': isShow}">
    <slot ></slot>
  </div>
</template>


<script>


export default {
  props:{
    currentPosition: Number,
    isCompletedLoad: Boolean, //firebaseからデータ取得するものがあるページに使う時は、データ取得完了時にこれに true を渡して。非同期取得じゃないものはtrueをもらうようにしとく
    isLastElement: Boolean, //一番したの要素は true を渡してもらう。そうしないと下の要素はスクロールラインに届かず表示されない
  },
  data(){
    return{
      position: null,
      isShow: false
    }
  },
  created(){
  },
  mounted(){
    this.position = this.$refs.refName.getBoundingClientRect().top
    if(this.isCompletedLoad){
      if(this.isLastElement){
        this.isShow = true
      }
      if(this.position < 900){
        // 要素の最初の位置が900px以内にあるやつは最初から表示
        this.isShow = true
      }
    }
  },
  beforeUnmount(){
  },

  methods:{

  },
  computed: {
    
  },
  watch: {
    currentPosition:{
      handler: function(){
        if(!this.isShow){
          if(window.innerWidth > 600){
            // PC
            if(this.currentPosition > this.position - 700){
              this.isShow = true
            }   
          }else{
            // SP
            if(this.currentPosition > this.position - 500){
              this.isShow = true
            }  
          }
 
        }
      }
    },
    isCompletedLoad:{
      handler: function(){ 
        this.position = this.$refs.refName.getBoundingClientRect().top
        if(this.isLastElement){
          this.isShow = true
        }
        if(this.position < 900){
          // 要素の最初の位置が900px以内にあるやつは最初から表示
          this.isShow = true
        }
      }
    },
  }
}
</script>

<style scoped>
.show{
  opacity: 0;
  transition: 2s;
  -webkit-transform: translate(0, 100px);
  transform: translate(0, 100px);
}
.appear{
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  opacity: 1;
}


@media screen and (max-width:599px) {

  
}

/* 90%調整用 */
@media screen and (min-width:600px) and (max-width:1100px){



}
</style>