<template>
  <div class="_base">
    <Header :isOpen="isOpenNavi" :pageTitle="pageTitle" @open="openNavi()" :currentPosition="_currentPosition"></Header>
    <Navi :isOpen="isOpenNavi" @close="closeNavi"></Navi>
    <!-- <GoTop></GoTop> -->
    <div class="content">
      <div class="title">
        <p>
          Thank you for subscribing.<br>
          We’ll keep you posted on our latest news.
        </p>
        <p>
          ご登録ありがとうございます。
        </p>
      </div>
      
    </div>
    <Footer :pageTitle="pageTitle"></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'
// import GoTop from '@/components/GoTop.vue'
import Mixin from '@/mixin/mixin.js'



export default {
  components:{
    Header,
    Navi,
    Footer,
    // GoTop
  },
  props:{

},
  data(){
    return{
      pageTitle: "Membership",
      isOpenNavi: false,
    
    }
  },
  mixins:[
    Mixin
  ],
  beforeCreate(){
    
  },
  created(){

  },
  mounted(){

  },
  beforeDestory(){
    
  },
  methods:{
    // ナビ用
    openNavi(){
      this.isOpenNavi = true
      this._stop(true)
    },
    closeNavi(){
      this.isOpenNavi = false
      this._stop(false)
    },

  },
  watch: {

  },
  computed:{

  },

}
</script>

<style scoped>
.title{
  margin-left: 42%;
  padding: 180px 0 40px;
}
.title h2{
  font-size: 2.0rem;
  letter-spacing: 1px;
}
.title p{
  margin-bottom: 40px;
}

.content{
  padding-top: 100px;
}

@media screen and (max-width:599px){

  .title{
    width: 90%;
    margin: 0 auto;
    padding: 120px 0 40px;
    flex-direction: column;
  }
  .title p{

  }

  .content{
    padding-top: 0px;
  }
}



/* タブレット */
@media screen and (min-width:600px) and (max-width:860px){

}
/* タブレット */
@media screen and (min-width:1200px) {

}
</style>