<template>
  <div class="navi" :class="{'open': isOpen}">
    <div class="header">
      <div class="header-container">
        <router-link :to="{name: 'Home'}"><img src="@/assets/images/logo.png" @click="close()" alt="JIBI" class="logo"></router-link>
        <div @click="close()" class="close"></div>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <div class="left-top">
          <p class="message-jp">
            いつくし風景、花を添える<br>
            そこに在る限り<br>
            自ずから美しいように
          </p>
          <p class="message-eg _eg">
            The creations of human beings should be seen as part of a larger relation to nature,<br>
            just as a flower compliments the landscape around it elegantly, rather than being in competition with it.
          </p>
        </div>
        <div class="left-bottom">
          <div class="letter">
            <label for="letter" class="letter-label _f4 _eg">Newsletter</label>
            <div class="_input-box">
              <input id="letter" type="email" v-model="member.email" placeholder="E-mail Address" class="_input _f5 _eg">
              <div v-if="!v$.member.$invalid" @click="saveEmail()"  class="_input-button">→</div>
            </div>
          </div>
          <a href="https://www.instagram.com/jibi_ig/?hl=ja" target="_blank"><img src="@/assets/images/insta.png" alt="インスタグラム" class="insta"></a>
        </div>


      </div>
      <div class="right">
        <ul class="right-top">
          <li><router-link :to="{name: 'About'}" @click="close()" class="link  _eg">About</router-link></li>
          <li><router-link :to="{name: 'WorkLineup'}" @click="close()" class="link  _eg">Design Works</router-link></li>
          <li><router-link :to="{name: 'JournalLineup'}" @click="close()" class="link  _eg">Journal</router-link></li>
          <li><router-link :to="{name: 'Hinamori2'}" @click="close()" class="link mini-link ">陽森</router-link></li>
          <li><router-link :to="{name: 'Mui'}" @click="close()" class="link mui _eg">mui</router-link></li>
          <li><router-link :to="{name: 'Yado'}" @click="close()" class="link mini-link">宿 yado</router-link></li>
        </ul>
        <ul class="right-bottom">
          <li><router-link :to="{name: 'Contact'}" @click="close()" class="link-sub _f3 _eg">Contact</router-link></li>
          <li><router-link :to="{name: 'Information'}" @click="close()" class="link-sub _f3 _eg">Information</router-link></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {db, currentTime} from '@/firebase/index.js'
import Member from "@/class/member.js"
import  useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
  props:{
    isOpen: Boolean
  },
  data(){
    return{
      member: new Member()
    }
  },
  setup(){
    return { v$: useVuelidate()}
  },
  mounted(){

  },
  beforeUnmount(){

  },
  methods:{
    close(){
      this.$emit('close')
    },
    saveEmail(){
      this.member.createdAt = currentTime
      this.member.updatedAt = currentTime
      const memberRef = db.collection('member').doc()
      this.member.id = memberRef.id
      memberRef.set({...this.member})
      .then(()=>{
        this.$router.push({name: 'Thanks'})
      })
    }
  },
  computed: {
    
  },
  validations(){
    return{
      member:{
        email:{
          required,
          email
        },
      },

    }
  },
}
</script>

<style scoped>

.navi{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--gray-bg);
  overflow-x: hidden;
  transition: 0.6s;
  opacity: 0;
  z-index: -20;
}
.open{
  opacity: 1;
  z-index: 50;
}

.header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100px;
  z-index: 40;
}
.header-container{
  display: flex;
  justify-content: space-between;
  margin: 80px auto 0;
  width: 100%;
}
.logo{
  width: 90px;
  height: 46px;
  margin-left: 60px;
}
.close{
  display: block;
  width: 36px;
  height: 36px;
  margin-right: 60px;
  position: relative;
  cursor: pointer;
}
.close::before, .close::after{
  content: "";
  display: block;
  width: 100%;/*バツ線の長さ*/
  height: 1px;/*バツ線の太さ*/
  background: var(--white);
  transform: rotate(45deg);
  transform-origin:0% 50%;
  position: absolute;
  top: calc(14% - 5px);
  left: 14%;
}
.close::after{
  transform: rotate(-45deg);
  transform-origin:100% 50%;
  left: auto;
  right: 14%;
}
.content{
  display: flex;
  justify-content: space-between;
  margin-top: 160px;
  width: 100%;
  height: calc(100vh - 160px);
  min-height: 500px;
}
.left{
  margin-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.message-jp{
  margin: 80px 0 70px;
  font-size: 1.5rem;
}
.message-eg{
  width: 100%;
  line-height: 34px;
  font-size: 1.7rem;
  margin-bottom: 70px;
}
.left-bottom{
  margin-bottom: 80px;
}
.letter{
  display: block;
  width: 300px;
  margin-bottom: 30px;
}
.letter-label{
  color: var(--black);
  margin-bottom: 8px;
  display: block;
}
.letter-input{
  width: 100%;
  display: block;
  border: 1px solid #b3a69f;
  height: 30px;
  text-align: center;
  color: var(--black);
}
.letter-input::placeholder{
  color: var(--gray);
}
.insta{
  width: 30px;
  height: 30px;
}

.right{
  margin-right: 60px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.right-top{
  width: 200px;
}
.right-top li{
  /* margin-bottom: 40px;       */
}
.link{
  display: block;
  color: var(--white);
  line-height: 18px;
  font-size: 2.0rem;
  transition: 0.3s;
  margin-bottom: 40px;
}
.link:hover{
  color: var(--black);
}
.mini-link{
  line-height: 12px;
  font-size: 1.5rem;
  margin-bottom: 30px;
}
.mui{
  line-height: 12px;
  font-size: 2.0rem;
  margin-bottom: 30px;
}
.right-bottom{
  width: 200px;
  margin-bottom: 80px;
}
.right-bottom li{
  margin-top: 15px;      
}
.link-sub{
  display: block;
  color: var(--white);
  line-height: 18px;
  font-size: 1.6rem;
  transition: 0.3s;
  margin-top: 20px;
}
.link-sub:hover{
  color: var(--black);
}


@media screen and (max-width:599px) {

  .navi{
    position: fixed;
    top: 0;
    left: 0;
    right:unset;
    bottom:unset;
    width: 100vw;
  }

  .header{
    height: 60px;
    position: unset;
  }
  .header-container{
    margin: 30px auto 0;
    width: 90%;
  }
  .logo{
    width: 60px;
    height: 30px;
    margin-left: 0;
  }
  .close{
    display: block;
    width: 28px;
    height: 28px;
    margin-right: 0;
  }

  .content{
    flex-direction:column-reverse;
    justify-content:flex-start;
    margin: 40px auto 0;
    width: 90%;
    height: auto;
    min-height: unset;
  }
  .left{
    width: 100%;
    margin-left: 0;
  }


  .message-jp{
    margin: 40px 0 20px;
    font-size: 1.3rem;
  }
  .message-eg{
    width: 65%;
    line-height: 24px;
    margin-bottom: 40px;
    font-size: 1.3rem;
  }
  .left-bottom{
    margin-bottom: 80px;
  }
  .letter{
    display: block;
    width: 300px;
    margin-bottom: 30px;
  }
  .letter-label{
    color: var(--black);
    margin-bottom: 8px;
    display: block;
  }
  .letter-input{
    width: 100%;
    display: block;
    border: 1px solid #b3a69f;
    height: 30px;
    text-align: center;
    color: var(--black);
  }
  .letter-input::placeholder{
    color: var(--gray);
  }
  .insta{
    width: 30px;
    height: 30px;
  }

  .right{
    margin-right: 0;
    display: block;
  }
  .right-top{
    width: 100%;
  }
  .right-top li{
          
  }
  .link{
    font-size: 1.8rem;
    margin-bottom: 30px;
  }
  .mini-link{
    line-height: 18px;
    font-size: 1.6rem;
    margin-bottom: 30px;
  }
  .mui{
    line-height: 18px;
    font-size: 1.8rem;
    margin-bottom: 30px;
  }
  .right-bottom{
    width: 100%;
    margin:50px 0 20px;
  }
  .right-bottom li{
    margin-top: 15px;      
  }
  .link-sub{

    font-size: 1.6rem;

  }
  
}

@media screen and (min-width:600px) and (max-width:1050px){
  .message-eg{
    width: 80%;
  }


}
</style>