<template>
  <div class="_base">
    <Header :isOpen="isOpenNavi" :pageTitle="pageTitle" @open="openNavi()" :currentPosition="_currentPosition"></Header>
    <Navi :isOpen="isOpenNavi" @close="closeNavi"></Navi>
    <!-- <GoTop></GoTop> -->
    <div class="h_title">
      <h2>塩 - Element【星】</h2>
    </div>
    <div class="h_top-images">
      <div class="h_top-image-container">
        <div class="h_top-image" :class="{'h_top-image-active': imageMark === 'a'}" :style="{ 'background-image': 'url(' + image1 +')'}"></div>
        <div class="h_top-image" :class="{'h_top-image-active': imageMark === 'b'}" :style="{ 'background-image': 'url(' + image2 +')'}"></div>
        <div class="h_top-image" :class="{'h_top-image-active': imageMark === 'c'}" :style="{ 'background-image': 'url(' + image3 +')'}"></div>
      </div>
      <div class="h_image-switch-container">
        <div class="h_image-switch">
          <div @click="imageChange('a')" class="h_switch"><div class="h_switch-line" :class="{'h_switch-active': imageMark === 'a'}"></div></div>
          <div @click="imageChange('b')" class="h_switch"><div class="h_switch-line" :class="{'h_switch-active': imageMark === 'b'}"></div></div> 
          <div @click="imageChange('c')" class="h_switch"><div class="h_switch-line" :class="{'h_switch-active': imageMark === 'c'}"></div></div> 
        </div>
      </div>
    </div>
    <div  class="h_content" >
      <div class="h_container">
        <div class="_left">
          <div class="_left-content">
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <p class=" _text _marginS">
              自然循環の中で人間が一つの関わりを為すことで生まれる「塩」は、人間が生きてゆく上で生命のバランスを体内で保つ重要な役割をしています。島国である日本では、古来より周囲の海から直接塩を得る方法を考えてきたと言われています。<br>
              そうして私たち人類が海を身体に取り込みながら現在まで生命を紡いできたことを想像すると、果てなく広がる海にグッと心を掴まれるのは必然かもしれません。<br>
              地上に降った雨は森の地下を通り、海底に湧き出る湧水(地下水)となります。それによって森や山のミネラルを海へと運び、豊かな海の環境をかたち創ってゆきます。
            </p>
            <!-- <div class="h_movie _marginS">
              <iframe src="https://www.youtube.com/embed/VDF3KJegQSw?controls=0&autoplay=1&mute=1&loop=1&playlist=VDF3KJegQSw&modestbranding=1&showinfo=0&autohide=1&playsinline=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div> -->
            <img src="@/assets/images/hinamori/collection/umi.jpg" class="h_image-one _marginS">
            <p class="_text _marginS">
              島国日本の片隅の小さな離島において、日々移りゆく季節や天体の働きによって変化する海の姿にこの星の息づかいを感じ、それをカタチに起こす振舞いとして塩を作ることにしました。<br>
              この塩は作る日のこの星の環境によって味が変わります。雨が降れば雨の味が多く混ざり、満月の日には引力によって濃度が変化します。自然の働きとしては当たり前の事ですが、塩は海の中で起こっているそうした変化に気付く手段でもあります。<br>
              万年の年月を超え人は海や森を通してこの星と結びついてきました。環境負荷が叫ばれる今、この星の息づかいとして塩を作り続けることは、人が起こせる希望の一つになれると私たちは考えています。
            </p>
            <div class="h_images">
              <img src="@/assets/images/hinamori/collection/shio1.jpg" alt="" class="h_image-one _marginSS">
              <img src="@/assets/images/hinamori/hi.jpg" alt="" class="h_image-one _marginSS">
              <div class="h_images-box">
                <img src="@/assets/images/hinamori/collection/shio2.jpg" alt="" class="h_image-two">
                <img src="@/assets/images/hinamori/collection/shio3.jpg" alt="" class="h_image-two">
              </div>
            </div>
            <a href="https://hinamori.official.ec/items/59627439" target="_blank" rel="noopener noreferrer" class="h_shop">購入</a>
            <div class="h_move">
              <router-link :to="{name: 'Hinamori2'}" class="h_move-box" >
                <img src="@/assets/images/arrow-l.png" alt="">
                <p class="h_move-text _eg">Collection</p>
              </router-link >
            </div>

          </div>
        </div>
      </div>
      <Footer :pageTitle="pageTitle"></Footer>
    </div>    
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'
// import GoTop from '@/components/GoTop.vue'
import Mixin from '@/mixin/mixin.js'
import Journal from '@/class/journal.js'
import {db} from '@/firebase/index.js'



export default {
  components:{
    Header,
    Navi,
    Footer,
    // GoTop
  },
  props:{

},
  data(){
    return{
      pageTitle: "Collection",
      isOpenNavi: false,

      journal: new Journal(),
      vol: null,
      isLastJournal: false,
      isFirstJournal: false,
      nextJournalId: null,
      isPublic: true,

      image1: require('@/assets/images/hinamori/collection/top1.jpg'),
      image2: require('@/assets/images/hinamori/collection/top2.jpg'),
      image3: require('@/assets/images/hinamori/collection/top3.jpg'),
      imageMark: "a",
      timer: null,
    }
  },
  mixins:[
    Mixin
  ],
  beforeCreate(){
    
  },
  created(){
    db.collection('journal').doc(this.$route.params.id).get()
    .then((doc)=>{
      this.journal = doc.data()
      this.backgroundImage = this.journal.imageUrl
    })
    .catch((error)=>{
      console.log(error.messege)
    })
    
  },
  mounted(){

  },
  beforeDestory(){
    
  },
  methods:{
    // ナビ用
    openNavi(){
      this.isOpenNavi = true
      this._stop(true)
    },
    closeNavi(){
      this.isOpenNavi = false
      this._stop(false)
    },

    imageChange(mark){
      clearTimeout(this.timer)
      this.imageMark = mark
    },
    setImage(){
      if(this.imageMark === "a"){
        this.imageMark = "b"
      }else if(this.imageMark === "b"){
        this.imageMark = "c"
      }else if(this.imageMark === "c"){
        this.imageMark = "a"
      }
    },


  },
  watch: {
    imageMark:{
      immediate: true,
      handler: function(){
        clearTimeout(this.timer)
        this.timer = setTimeout(this.setImage, 3000)
      },
    },
  },
  computed:{

  },

}
</script>

<style scoped>
.title{
  margin-left: 42%;
  display: flex;
  padding: 180px 0 40px;
}
.title h2{
  font-size: 2.0rem;
  letter-spacing: 1px;
}
.title p{
  font-size: 1.4rem;
  color: var(--gray);
  -webkit-transform: translate(15px, 7px);
  transform: translate(15px, 7px);
}

.top-images{
  width: 100%;
  height: 600px;
  position: relative;
}
.top-image-container{
  width: 100%;
  height: 100%;
  background-color: var(--black);
  position: relative;
}
.top-image{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  opacity: 0;
  transition: 1.5s;
}
.top-image-active{
  opacity: 1;
}
.image-switch-container{
  position: absolute;
  width: 100%;
  height: 10px;
  z-index: 20;
  bottom: 40px;
}
.image-switch{
  display: flex;
  justify-content: space-between;
  width: 120px;
  height: 1px;
  margin: 0 60px 0 auto;
}
.switch{
  width: 31%;
  height: 40px;
}
.switch-line{
  height: 1px;
  background-color: var(--white);
  -webkit-transform: translate(0, 20px);
  transform: translate(0, 20px);
}
.switch-active{
  background-color: #808080;
}

.content{
  width: 100%;
  padding: 60px 0 100px;
}
.container{
  width: 100%;
  padding-bottom: 160px;
  display: flex;
}

.movie {
  width: 100%;
  aspect-ratio: 16 / 9;
  /* aspect-ratio: 1 / 1; */
  position: relative;
}
.movie iframe {
  width: 100%;
  height: 100%;
}
.movie-cover-top{
  position: absolute;
  width: 100%;
  height: 22%;
  top: 0;
  left: 0;
  background-color: var(--bg);
}
.movie-cover-bottom{
  position: absolute;
  width: 100%;
  height: 22%;
  bottom: 0;
  left: 0;
  background-color: var(--bg);
}

.images{
  margin-bottom: 60px;
}
.image-one{
  width: 100%;
  aspect-ratio: 5 / 3;
  object-fit: cover;
  margin-bottom: 10px;
  display: block;
}
.images-box{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.image-two{
  width: calc(50% - 5px);
  display: block;
  aspect-ratio: 3 / 4;
  object-fit: cover;
}

.shop{
  padding: 4px;
  display: block;
  width: 200px;
  color: var(--white);
  background-color: var(--black);
  text-align: center;
  letter-spacing: 2px;
  margin-bottom: 40px;
}

.move{
  display: flex;
  justify-content: space-between;
  width: 170px;
}
.move-box{
  display: flex;
  justify-content: space-between;
  width: 50px;
  transition: 0.3s;
}
.move-box:hover{
  filter: brightness(10%);
}
.move-box img{
  width: 15px;
  height: 15px;
}
.move-text{
  color: var(--gray);
  font-size: 1.4rem;
  line-height: 15px;
}

@media screen and (max-width:599px){

  .title{
    width: 90%;
    margin: 0 auto;
    padding: 120px 0 40px;
    flex-direction: column;
  }
  .title p{
    -webkit-transform: translate(0px, -6px);
    transform: translate(0px, -6px);
  }
  .container{
    width: 90%;
    margin: 0 auto;
    display: block;
    padding-bottom: 100px;
    
  }
  .top-images{
    height: 250px;
  }

  .image-switch{
    width: 100px;
    height: 1px;
    bottom: 40px;
    right: 40px;
  }
  .image-switch-container{
    bottom: 50px;
  }
  .image-switch{
    margin: 0 5% 0 auto;
  }





}



/* タブレット */
@media screen and (min-width:600px) and (max-width:860px){

}
/* タブレット */
@media screen and (min-width:1200px) {

}
</style>