import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/storage'

// 本番
const firebaseConfig = {
  apiKey: "AIzaSyAFSsGoiQdaQ08VJnMWmJX6Lfi7Rpc5Imk",
  authDomain: "jibi-web.firebaseapp.com",
  projectId: "jibi-web",
  storageBucket: "jibi-web.appspot.com",
  messagingSenderId: "475987292116",
  appId: "1:475987292116:web:e3705830bb4f9e87f83041",
  measurementId: "G-5BBZPSLGLJ"
}

// テスト
// const firebaseConfig = {
//   apiKey: "AIzaSyDtq48dOH3_B4P3DFi0F0jZaCHjBLZkass",
//   authDomain: "jibi-4ecaf.firebaseapp.com",
//   projectId: "jibi-4ecaf",
//   storageBucket: "jibi-4ecaf.appspot.com",
//   messagingSenderId: "256569671270",
//   appId: "1:256569671270:web:9f973e332fc4e3625737a7"
// }

firebase.initializeApp(firebaseConfig)
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()
const generateId = function(id){
  var date = firebase.firestore.Timestamp.now()
  date = date.toDate()
  const year = date.getFullYear()
  const month = 1 + date.getMonth()
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()
  const millisecond = date.getMilliseconds()
  
  var format = 'YYYYMMDDhhmmsslll';
  format = format.replace(/YYYY/g, year);
  format = format.replace(/MM/g, month);
  format = format.replace(/DD/g, day);
  format = format.replace(/hh/g, hour);
  format = format.replace(/mm/g, minute);
  format = format.replace(/ss/g, second);
  format = format.replace(/lll/g, millisecond);
  format = Number(format)
  format = format.toString(16)
  return id + format
}
const getDate = function(){
  var date = firebase.firestore.Timestamp.now()
  date = date.toDate()
  const year = String(date.getFullYear())
  let day = ""
  if(date.getDate() < 10){
    day = "0" + String(date.getDate())
  }else{
    day = String(date.getDate())
  }

  const monthNumber = 1 + date.getMonth()
  let month = ""
  if(monthNumber === 1){
    month = "January  "
  }else if(monthNumber === 2){
    month = "February"
  }else if(monthNumber === 3){
    month = "March"
  }else if(monthNumber === 4){
    month = "April"
  }else if(monthNumber === 5){
    month = "May"
  }else if(monthNumber === 6){
    month = "June"
  }else if(monthNumber === 7){
    month = "July"
  }else if(monthNumber === 8){
    month = "August"
  }else if(monthNumber === 9){
    month = "September"
  }else if(monthNumber === 10){
    month = "October"
  }else if(monthNumber === 11){
    month = "November"
  }else if(monthNumber === 12){
    month = "December"
  }
  const dateObject = {
    month,
    day,
    year

  }
  return dateObject
}
const currentTime = firebase.firestore.FieldValue.serverTimestamp()

export {db, auth, storage, generateId, getDate, currentTime }
