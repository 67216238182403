<template>
  <div class="_base">
    <Header :isOpen="isOpenNavi" :pageTitle="pageTitle" @open="openNavi()" :currentPosition="_currentPosition" :backPath="backPath"></Header>
    <Navi :isOpen="isOpenNavi" @close="closeNavi"></Navi>
    <!-- <GoTop></GoTop> -->
    <div class="title">
      <div class="title-container">
        <h2 class="_marginL">{{work.title}}</h2>
        <p class="_text _marginL">{{work.text}}</p>
        <p class="_text _marginLL _eg">{{work.textEg}}</p>
      </div>
    </div>
    <img :src="mainImageUrl" alt="" class="image">
    <div  class="content" >
      <div class="container">
        <div class="_left">
          <div class="_left-content">
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <p class="detail">Details</p>
            <div class="detail-container">
              <div class="detail-box">
                <p class="detail-title _eg">Client</p>
                <p class="detail-content">{{work.client}}</p>
              </div>
              <div class="detail-box">
                <p class="detail-title _eg">Location</p>
                <p class="detail-content">{{work.location}}</p>
              </div>
              <div class="detail-box">
                <p class="detail-title _eg">Completion</p>
                <p class="detail-content">{{String(work.completion).slice(0,4)}}</p>
              </div>
              <div class="detail-box">
                <p class="detail-title _eg">Category</p>
                <p class="detail-content">{{work.contents}}</p>
              </div>
              <div v-if="work.url" class="detail-box">
                <p class="detail-title _eg">Website</p>
                <a class="detail-content url-link" :href="work.url" target="_blank" rel="noopener noreferrer">{{work.url}}</a>
              </div>
            </div>
            <p class="_text">{{work.description}}</p>
          </div>
        </div>
      </div>
      <div class="sub-image-container">
        <div v-for="(image, index) in subImageObjects" :key="index" class="sub-image-box">
          <img :src="image.url" :alt="image.name" class="sub-image">
          <div v-if="work.imageObjectsV.length === 2 && index === 0" class="imageV-container">
            <img :src="work.imageObjectsV[0].url" alt="" class="imageV">
            <img :src="work.imageObjectsV[1].url" alt="" class="imageV">
          </div>
          <div v-if="work.imageObjectsV.length === 1 && index === 0" class="imageV-container">
            <img :src="work.imageObjectsV[0].url" alt="" class="imageV-one">
          </div>
        </div>
        <p v-if="subImageObjectsPlus.length && !isAppearPlusImages" @click="readMore()" class="read-more _f2">See more</p>
        <div v-if="isAppearPlusImages" class="plus-image-box">
          <img  v-for="image in subImageObjectsPlus" :key="image.order"  :src="image.url" :alt="image.name" class="plus-image">
        </div>
      </div>
      <div class="list">
        <h2 class="list-title _eg-b _f2">Recent Design Works</h2>
        <div class="_box-container">
          <div v-for="work in works" @click="go(work.id)" :key="work.id" class="_box">
            <img :src="work.imageUrl" :alt="work.title" class="_box-image">
            <h3 class="_box-sub-title"><p class="_f3">{{work.title}}</p><span class="_box-sub-title-line"></span><p class=" _f3 _eg ">{{work.mainContent}}</p></h3>
            <p class="_f5">{{work.shortDescription}}</p>
          </div>
          <div v-if="works.length === 2 " class="_box-dummy"></div>
        </div>
        <OtherProjectInput></OtherProjectInput>
      </div>
    </div>
    <Footer :pageTitle="pageTitle"></Footer>
    <GoTop :currentPosition="_currentPosition"></GoTop>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'
import OtherProjectInput from '@/components/OtherProjectInput.vue'
import GoTop from '@/components/GoTop.vue'
import Mixin from '@/mixin/mixin.js'
import Work from '@/class/work.js'
import {db} from '@/firebase/index.js'



export default {
  components:{
    Header,
    Navi,
    Footer,
    OtherProjectInput,
    GoTop
  },
  props:{

},
  data(){
    return{
      pageTitle: "Design Works",
      isOpenNavi: false,
      backPath: null,

      work: new Work(),
      mainImageUrl: null,
      subImageObjects: [],
      subImageObjectsPlus:[],
      isAppearPlusImages: false,
      works: [],
      password: null

    }
  },
  mixins:[
    Mixin
  ],
  beforeCreate(){
    
  },
  async created(){
    await db.collection('work').doc(this.$route.params.id).get()
    .then((doc)=>{

      if(doc.data().isPublic){
        // 一般公開用workにアクセスしてきた時
        this.work = doc.data()
        this.randomSet()
        this.backPath = "WorkLineup"
      }else{
        // 非公開用workにアクセスしてきた時
        if(this.$store.state.inputPassword === this.$store.state.password){
          this.work = doc.data()
          this.randomSet()
          this.backPath = "OtherWorkLineup"
        }else{
          // パスワード打ってきてない人追いだし
          this.$router.push({name: 'Home'})
        }
      }
      // imageObjectsHがまだなかった記事用
      if(!this.work.imageObjectsV){
        this.work.imageObjectsV = []
      }

    })
    .catch((error)=>{
      console.log(error.message)
    })

    
    
  },
  mounted(){

  },
  beforeDestory(){
    
  },
  methods:{
    // ナビ用
    openNavi(){
      this.isOpenNavi = true
      this._stop(true)
    },
    closeNavi(){
      this.isOpenNavi = false
      this._stop(false)
    },

    readMore(){
      this.isAppearPlusImages = true
    },

    async go(workId){
      this.$router.push({name: 'Work', params: {id: workId}})
      await db.collection('work').doc(workId).get()
      .then((doc)=>{
        this.work = doc.data()
      })
      this.randomSet()
    },
    randomSet(){
      var random = Math.floor( Math.random() * 11 ) //0から10のランダムの数字を生成

      if(this.$store.state.inputPassword === this.$store.state.password){
        // パスワードが打たれている時
        if(random < 3){
          db.collection('work').orderBy('updatedAt', 'desc').limit(4).get()
          .then((querySnapShot)=>{
            this.setWorks(querySnapShot)
          })
        }else if(random >= 3 && random < 6){
          db.collection('work').orderBy('updatedAt').limit(4).get()
          .then((querySnapShot)=>{
            this.setWorks(querySnapShot)
          })
        }
        else if(random >= 6 && random < 9){
          db.collection('work').orderBy('createdAt').limit(4).get()
          .then((querySnapShot)=>{
            this.setWorks(querySnapShot)
          })
        }else{
          db.collection('work').orderBy('createdAt', 'desc').limit(4).get()
          .then((querySnapShot)=>{
            this.setWorks(querySnapShot)
          })
        }
      }else{
        // パスワードが打たれてない時
        if(random < 6){
          db.collection('work').where('isPublic', '==', true).orderBy('updatedAt', 'desc').limit(4).get()
          .then((querySnapShot)=>{
            this.setWorks(querySnapShot)
          })
        }else{
          db.collection('work').where('isPublic', '==', true).orderBy('updatedAt').limit(4).get()
          .then((querySnapShot)=>{
            this.setWorks(querySnapShot)
          })
        }
      }


    },
    setWorks(querySnapShot){
      let box = []
      querySnapShot.forEach((doc)=>{
        if(doc.data().id !== this.work.id){
          box.push(doc.data())
        }
      })
      if(box.length === 4){
        box.shift()
      }
      this.works = box
    }


  },
  watch: {
    work:{
      immediate: true,
      deep: true,
      handler: function(){
        this.isAppearPlusImages = false
        this.subImageObjects = []
        this.subImageObjectsPlus = []
        this.work.imageObjects.forEach(imageObject => {
          if(imageObject.order === 0){
            this.mainImageUrl = imageObject.url
          }else{
            this.subImageObjectsPlus.push(imageObject)
          }
        })
        this.subImageObjectsPlus.sort((a, b)=>{
          if(a.order < b.order){return -1}
          if(a.order > b.order){return 1}
          return 0
        })
        this.subImageObjects = this.subImageObjectsPlus.splice(0,5)
        
        // imageObjectsHがまだなかった記事用
        if(!this.work.imageObjectsV){
          this.work.imageObjectsV = []
        }
      }
    },
    works:{
      immediate: true,
      deep: true,
      handler: function(){
        this.works.forEach((work)=>{
        work.imageObjects.forEach(imageObject=>{
          if(imageObject.order === 0){
            work.imageUrl = imageObject.url
          }
        })
      })
      }
    },
  },
  computed:{

  },

}
</script>

<style scoped>
.title{
  margin-left: 42%;
  padding: 180px 0 0;
}
.title-container{
  width: 80%;
}
.title h2{
  font-size: 2.0rem;
  letter-spacing: 1px;
}

.image{
  width: 100%;
  height: 600px;
  object-fit: cover;
}
.content{
  width: 100%;
  padding: 40px 0 100px;
}
.container{
  width: 100%;
  display: flex;
  margin-bottom: 80px;
}
.detail{
  font-size: 2.0rem;
  margin: 20px 0;
}
.detail-container{
  margin-bottom: 20px;
}
.detail-box{
  display: flex;
  margin-bottom: 10px;
}
.detail-title{
  display: block;
  line-height: 24px;
  width: 100px;
}
.detail-content{
  line-height: 24px;
  display: block;
}
.sub-image-container{
  margin: 0 auto 140px;
  width: 76.5%;
}

.sub-image-box{
  width: 100%;
  
}
.sub-image{
  width: 100%;
  margin-bottom: 50px;
}
.imageV-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}
.imageV{
  width: 48%;
  display: block;
  aspect-ratio: 3/5;
  object-fit: cover;
}
.imageV-one{
  width: 100%;
}
.plus-image-box{

}
.plus-image{
  width: 100%;
  margin-bottom: 50px;
}

.read-more{
  color: var(--white);
  transition: 0.3s;
  cursor: pointer;
}
.read-more:hover{
  color: var(--black);
}
.list-title{
  color: var(--white);
  text-align: center;
  margin-bottom: 40px;
}
.list{
    margin: 0 auto;
  width: 76.5%;
  margin-bottom: 180px;
}

.url-link{
  cursor: pointer;
  font-size: 1.5rem;
}
.url-link:hover{
  opacity: 0.6;
}


@media screen and (max-width:599px){

  .title{
    width: 90%;
    margin: 0 auto;
    padding: 120px 0 40px;
    flex-direction: column;
  }
.title-container{
  width: 100%;
}
  .container{
    width: 90%;
    margin: 0 auto;
    display: block;
    padding-bottom: 100px;
    
  }
  .image{
    height: 250px;
  }

  .detail{
    font-size: 2.0rem;
    margin: 20px 0;
  }
  .detail-box{
    display: block;
  }
  .detail-title{
    line-height: 18px;
    font-size: 1.3rem;
  }
  .sub-image-container{
    margin: 0 auto 60px;
    width: 90%;
  }
  .sub-image-container img{
    margin-bottom: 30px;
  }
  .list{
    width: 90%;
    margin-bottom: 100px;
  }
  .work-other{
    width: 60%;
  }

}



/* タブレット */
@media screen and (min-width:600px) and (max-width:860px){

}
@media screen and (min-width:1200px) {
  .image{
    height: 700px;
  }
}
</style>