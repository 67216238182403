<template>
  <div class="hide" :class="{'appear': isShow}">
    <slot></slot>
  </div>
</template>


<script>


export default {
  props:{
    appearanceTime: Number, //表示したい時間を受け取る
  },
  data(){
    return{
      isShow: false,
      timer: null
    }
  },
  created(){
  },
  mounted(){
    this.timer = setTimeout(this.appear, this.appearanceTime)
  },
  beforeUnmount(){
    clearTimeout(this.timer)
  },

  methods:{
    appear(){
      this.isShow = true
    }
  },
  computed: {
    
  },
  watch: {


  }
}
</script>

<style scoped>
.hide{
  opacity: 0;
  transition: 1.5s;
}
.appear{
  opacity: 1;
}


@media screen and (max-width:599px) {

  
}

/* 90%調整用 */
@media screen and (min-width:600px) and (max-width:1100px){



}
</style>