<template>
  <Spinner v-if="isButtonLock" :isBackground="true"></Spinner>
  <div class="m_base">
    <p class="m_title">Edit Top Images</p>
    
    <div class="image-upload">
      <label for="image" class="m_input-label _f4 _eg">Photos</label>
      <div class="image-upload-container">
        <div v-for="(imageObject, index) in imageDisp"
              :key="index"
              draggable="true"
              @dragstart="dragstart(imageObject, $event)"
              @dragenter="dragenter(imageObject)"
              @dragover.stop.prevent="dragover"
              @dragend.stop.prevent="dragend"
              class="box preview">
          <div class="preview-image" :style="{'background-image': 'url(' + imageObject.url +')'}"></div>
          <img @click="deleteImageObject(index, )" src="@/assets/images/cross-button-w.png" alt="" class="image-delete">
        </div>
        <div v-if="imageObjects.length <= 3" class="box image-upload-button">
          <input type="file" ref="preview" @change="setImage($event)" accept="image/png, image/jpeg" id="image" class="_hide">
          <label for="image" class="image-upload-icon">
            <img  src="@/assets/images/camera-g.png" alt="カメラアイコン" class="camera-icon">
          </label>
        </div>
      </div>
    </div>

    <button v-if="mode === 'create'" @click="saveImageAndCreate()" :disabled="v$.topImages.$invalid || isButtonLock"  :class="{'m_invalid-button': v$.topImages.$invalid}" class="m_submit _eg">Create</button>
    <button v-if="mode === 'edit' && imageObjects.length == 4 " @click="edit()" :disabled="isButtonLock"  :class="{'m_invalid-button': v$.topImages.$invalid}" class="m_submit _eg">Edit</button>
    <p v-if="isError" class="m_error">エラー発生。再度リトライしてください。</p>

  </div>
</template>

<script>

import "@/css/manager.css"
import { db, storage, currentTime} from '@/firebase/index.js'
import TopImages from "@/class/topImages.js"
import imageCompression from 'browser-image-compression'
import  useVuelidate from '@vuelidate/core'
// import { required } from '@vuelidate/validators'
// import draggable from "vuedraggable"
import Spinner from '@/components/Spinner.vue'

export default {
  components:{
    // draggable
    Spinner
  },
  props:{
    is_sign: Boolean
},
  data(){
    return{
      pageName: "topImage",
      isError: false,
      isButtonLock: false,
      mode: "create", //"create" or "edit"

      topImages: new TopImages(),
      dataId: "RNlgUhqSbVJUGxiCR0qT", //初回に入れたid
      // compressedImage: null,
      // previewImageUrl: null,
      

      imageObjects:[],
      imageTrashBox:[],
      draggingItem: null, // ドラッグ中の要素を保持するための変数
      imageChangeCounter : 0,
    }
  },
  setup(){
    return { v$: useVuelidate()}
  },
  beforeCreate(){

  },
  created(){
    this.$store.commit('managerPageName', this.pageName)
    // if(this.$route.params.id == null){
    //   this.mode = "create"
    //   this.topImages = new TopImages()
    // }else{
    //   this.mode = "edit"
    //   db.collection('topImages').doc(this.$route.params.id).get()
    //   .then((doc)=>{
    //     this.topImages = new TopImages(doc.data())
    //     this.imageObjects = this.topImages.imageObjects
    //     this.imageObjects.sort((a,b)=>{
    //       if(a.order < b.order){return -1}
    //       if(a.order > b.order){return 1}
    //       return 0
    //     })
    //   })
    // }

    // 強制editのみ createしたい場合は上記と切り替える。
    this.mode = "edit"
    db.collection('topImages').doc(this.dataId).get()
    .then((doc)=>{
      this.topImages = new TopImages(doc.data())
      this.imageObjects = this.topImages.imageObjects
      this.imageObjects.sort((a,b)=>{
        if(a.order < b.order){return -1}
        if(a.order > b.order){return 1}
        return 0
      })
    })
  },
  mounted(){

  },
  methods:{
    async setImage(event){
      // プレビュー用
      const file = this.$refs.preview.files[0]
      
      // 保存用
      const originImage = event.target.files[0]
      const options = {
        maxSizeMB: 1.0, //最大ファイルサイズ
        maxWidthOrHeight: 2000, //最大縦横値
      }
      const compressedImage = await imageCompression(originImage, options)
      this.imageObjects.push({url: URL.createObjectURL(file), file: compressedImage})
      this.imageObjects.forEach((object, index)=>{
        object.order = index
      })
      this.$refs.preview.value = null
    },
    deleteImageObject(index){
      if(this.imageObjects[index].name){
        const imageTrash = this.imageObjects[index]
        this.imageTrashBox.push(imageTrash)
        this.imageObjects.splice(index, 1)
      }else{
        this.imageObjects.splice(index, 1)
      }
      this.imageObjects.forEach((object, index)=>{
        object.order = index
      })
    },

    async saveImageAndCreate(){
      this.isButtonLock = true
      let topImagesId = null
      this.imageObjects.forEach((imageObject, index)=>{
        imageObject.order = index
      })
      
      let imageObjectsForSave = []
      this.topImages.createdAt = currentTime
      this.topImages.updatedAt = currentTime
      db.collection('topImages').add({...this.topImages})
      .then(async (doc)=>{
        topImagesId = doc.id
        db.collection('topImages').doc(topImagesId).update({ id: topImagesId})

        const imageObjectsToReturn = []
        const promises = []
        this.imageObjects.forEach((imageObject, index)=>{
          const fileName = `topImages/${topImagesId + "_" + String(this.topImages.updateCounter) + "_" + String(index)}`
          promises.push(storage.ref().child(fileName).put(imageObject.file)
          .then( async (snapShot)=>{
            const url = await snapShot.ref.getDownloadURL()
            imageObjectsToReturn.push({url: url, order: imageObject.order, name: fileName})
          }))
        })
        imageObjectsForSave = await Promise.all(promises).then(()=>{return imageObjectsToReturn})
        
        db.collection('topImages').doc(topImagesId).update({ imageObjects: imageObjectsForSave})
        .then(()=>{
          this.isButtonLock = false
          this.$router.push({name: 'Manager'})
        })
        .catch((error)=>{
          console.log(error.message)
          this.isButtonLock = false
          this.isError = true
        })
      })
      .catch((error)=>{
        console.log(error.message)
        this.isButtonLock = false
        this.isError = true
      })
    },

    async edit(){
      this.isButtonLock = true
      this.topImages.updateCounter = this.topImages.updateCounter + 1
      this.topImages.updatedAt = currentTime

      this.imageObjects.forEach((imageObject, index)=>{
        imageObject.order = index
      })
      let imageObjectsForStorage = this.imageObjects.filter((object)=>{
        return object.name == null
      })
      this.imageObjects = this.imageObjects.filter((object)=>{
        return object.name != null
      })
      const imageObjectsToReturn = []
      const promises = []
      imageObjectsForStorage.forEach((imageObject, index)=>{
        const fileName = `topImagesId/${this.dataId + "_" + String(this.topImages.updateCounter) + "_" + String(index)}`
        promises.push(storage.ref().child(fileName).put(imageObject.file)
        .then( async (snapShot)=>{
          const url = await snapShot.ref.getDownloadURL()
          imageObjectsToReturn.push({url: url, order: imageObject.order, name: fileName})
        }))
      })
      let imageObjectsFromStorage = await Promise.all(promises).then(()=>{return imageObjectsToReturn})
      imageObjectsFromStorage.forEach((imageObject)=>{
        this.imageObjects.push(imageObject)
      })
      this.imageTrashBox.forEach((object)=>{
        storage.ref().child(object.name).delete()
        .then(()=>{
        })
        .catch((error)=>{
          console.log(error.message)
        })
      })
      this.topImages.imageObjects = this.imageObjects


      db.collection('topImages').doc(this.dataId).update({...this.topImages})
      .then(()=>{
        this.isButtonLock = false
        this.$router.push({name: 'ManagerTopImages'})
      })
      .catch((error)=>{
        console.log(error.message)
        this.isError = true
      })
    },

    dragstart(object, e) {
      this.draggingItem = object; // ドラッグ中の要素を保持
      e.dataTransfer.effectAllowed = 'move'; // 移動モードに設定
      e.target.style.opacity = 0.5; // ドラッグ中要素のスタイルを変更
    },
    dragenter(object) {
      // ドラッグ中の要素とドラッグ先の要素の表示順を入れ替える
      [object.order, this.draggingItem.order] = [this.draggingItem.order, object.order];
    },
    dragover(e) {
      e.dataTransfer.dropEffect = 'move'; // 移動モードに設定
    },
    dragend(e) {
      e.target.style.opacity = 1; // ドラッグ中要素のスタイルを変更（元に戻す）
      this.draggingItem = null; // ドラッグ中の要素をクリア
    },


  },

  computed:{
    imageDisp() {
      return [...this.imageObjects].sort((a, b) => a.order - b.order)
    },
  },
  watch: {
    imageDisp:{
      deep: true,
      handler: function (){
        return this.imageObjects.sort((a, b) => a.order - b.order)
      }
    },
  },
  validations(){
    return{
      topImages:{
        // title:{
        //   required
        // },
      },

    }
  },
}
</script>


<style scoped>
.image{
  width: 100%;
}

.image-upload{
  margin-bottom: 22px;

}
.image-upload-label{
  font-size: 1.3rem;
  color: var(--gray-hi);
}
.image-upload-container{
  margin-top: 5px;
  display: flex;
  white-space:nowrap;
  overflow-x: auto;
}
.box{
  margin-right: 10px;
  display: block;
  width: 100px;  
}
.image-upload-icon{
  display: block;
  background-color: var(--white);
  width: 100px;
  height: 100px;
  position: relative;
  box-sizing: border-box;
    border: 1px solid #b3a69f;
}

.camera-icon{
  position: absolute;
  width: 34px;
  height: 34px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.image-upload-icon p{
  color: var(--gray-hi);
  position: absolute;
  top: 0px;
  left: 5px;
  font-weight: 700;
}
.preview{
  position: relative;
}
.preview-image{
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: cover;
}
.image-delete{
  width: 18px;
  height: 18px;
  position: absolute;
  top: 4px;
  right: 4px;
}

@media screen and (min-width:600px){

}

</style>