<template>
  <div class="footer-base">
    <div v-if="style === 'include'" class="footer-include" :class="{'lock': pageTitle !== 'Home'}">
      <div class="footer-container">
        <ul class="links">
          <li><router-link :to="{name: 'About'}" class="link _eg">About</router-link></li>
          <li><router-link :to="{name: 'Contact'}" class="link _eg">Contact</router-link></li>
          <li><router-link :to="{name: 'Information'}" class="link _eg">Information</router-link></li>
        </ul>
        <a href="https://www.instagram.com/jibi_ig/?hl=ja" target="_blank" rel="noopener noreferrer"><img src="@/assets/images/insta-g.png" alt="インスタグラム" class="insta"></a>
      </div>
    </div>
    <div v-else class="footer" :class="{'lock': pageTitle !== 'Home'}">
      <div class="footer-container">
        <ul class="links">
          <li><router-link :to="{name: 'About'}" class="link _eg">About</router-link></li>
          <li><router-link :to="{name: 'Contact'}" class="link _eg">Contact</router-link></li>
          <li><router-link :to="{name: 'Information'}" class="link _eg">Information</router-link></li>
        </ul>
        <a href="https://www.instagram.com/jibi_ig/?hl=ja" target="_blank" rel="noopener noreferrer"><img src="@/assets/images/insta-g.png" alt="インスタグラム" class="insta"></a>
      </div>
    </div>
  </div>

</template>


<script>


export default {
  props:{
    pageTitle: String, 
    style: String, //nullなら通常、 "include"なら背景が透明
  },
  data(){
    return{

    }
  },
  mounted(){

  },
  beforeUnmount(){

  },
  methods:{

  },
  computed: {
    
  }
}
</script>

<style scoped>
.footer{
  width: 100%;
  background-color: var(--bg);
  padding: 70px 0;
}
.footer-include{
  width: 100%;
  padding: 70px 0;
}
.lock{
  position: absolute;
  bottom: 0;
}

.footer-container{
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
}
.links{
  display: flex;
  margin-left: 60px;
}
.link{
  color: var(--green);
  margin-right: 40px;
  line-height: 30px;
  font-size: 1.5rem;
}
.insta{
  width: 30px;
  height: 30px;
  margin-right: 60px;
}


@media screen and (max-width:599px) {
  .footer{
    padding: 20px 0 30px;
  }
  .footer-container{
    width: 90%;
    align-items: flex-end;
  }
  .links{
    margin-left: 0;
    flex-direction: column;
    
  }
  .link{
    margin-right: 0;
    line-height: 16px;
    font-size: 1.4rem;
  }
  .insta{
    margin-right: 0;
    -webkit-transform: translate(0, -4px);
    transform: translate(0, -4px);
  }
  
}

/* 90%調整用 */
@media screen and (min-width:600px) and (max-width:1100px){

  .footer-container{
  width: 90%;
  }
  .links{
  margin-left: 0;
  }
  .insta{
  margin-right: 0;
  }

}
</style>