<template>
  <div class="other-project">
    <label for="password" class="password-label _f4 _eg">Other Projects</label>
    <div class="_input-box">
      <input id="password" v-model="password" placeholder="Password" class="_input _f5 _eg">
      <div v-if="password === $store.state.password" @click="go()"  class="_input-button">→</div>
    </div>
  </div>
</template>

<script>


export default {
  props:{

  },
  data(){
    return{
      password:null
    }
  },

  mounted(){

  },
  beforeUnmount(){

  },
  methods:{
    go(){
      this.$store.commit('inputPassword', this.password)
      this.$router.push({name: "OtherWorkLineup"})
    }
  },
  computed: {
    
  }
}
</script>

<style scoped>
.other-project{
  margin: 0 auto;
  display: block;
  width: 30%;
}
.password-label{
  color: var(--gray);
  margin-bottom: 8px;
  display: block;
}

@media screen and (max-width:599px) {
  .other-project{
    width: 60%;
  }
}
@media screen and (min-width:600px) and (max-width:1100px){


}
</style>