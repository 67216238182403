<template>
  <Spinner v-if="isButtonLock" :isBackground="true"></Spinner>
  <div class="m_base">
    <p class="m_title">New Work</p>
    <div class="m_tag">
      <div class="m_tag-box">
        <input type="radio" id="public" :value="true" v-model="work.isPublic">
        <label for="public" class="m_tag-label"><span v-if="work.isPublic" class="m_tag-active"></span></label>
        <label for="public" class="m_tag-text _eg">Public</label>
      </div>
      <div class="m_tag-box">
        <input type="radio" id="unpublic" :value="false" v-model="work.isPublic">
        <label for="unpublic" class="m_tag-label"><span v-if="!work.isPublic" class="m_tag-active"></span></label>
        <label for="unpublic" class="m_tag-text _eg">Unpublic</label>
      </div>
    </div>
    <div class="m_input-box m_box">
      <label for="title" class="m_input-label _f4 _eg">Title</label>
      <input id="title" v-model="work.title" placeholder="" class="m_input _f4 _eg">
    </div>
    <div class="m_input-box m_box">
      <label for="text" class="m_input-label _f4 _eg">Text</label>
      <textarea id="text" v-model="work.text" placeholder="" class="m_textarea"></textarea>
    </div>
    <div class="m_input-box m_box">
      <label for="en-text" class="m_input-label _f4 _eg">English Text</label>
      <textarea id="en-text" v-model="work.textEg" placeholder="" class="m_textarea"></textarea>
    </div>
    <div class="m_input-box m_box">
      <label for="client" class="m_input-label _f4 _eg">Client</label>
      <input id="client" v-model="work.client" placeholder="" class="m_input _f4 _eg">
    </div>
    <div class="m_input-box m_box">
      <label for="location" class="m_input-label _f4 _eg">Location</label>
      <input id="location" v-model="work.location" placeholder="" class="m_input _f4 _eg">
    </div>
    <div class="m_input-box m_box">
      <label for="completion" class="m_input-label _f4 _eg">Completion</label>
      <input id="location" v-model="work.completion" placeholder="" class="m_input _f4 _eg">
    </div>
    <!-- <div class="m_input-box m_box">
      <label for="subTitle" class="m_input-label _f4 _eg">Completion</label>
      <div class="m_select-container">
        <select v-model="date.year"  class="m_select-2" >
          <option :value="null" disabled >年</option>
          <option v-for="n in 30" :key="n" :value="n + 2000" :style="{'color': '#131313'}" >{{n + 2000}}年</option>
        </select>
        <select v-model="date.month" class="m_select-2" >
          <option :value="null" disabled >月</option>
          <option v-for="n in 12" :key="n" :value="n" :style="{'color': '#131313'}" >{{n}}月</option>
        </select>
        <select v-model="date.day"  class="m_select-2" >
          <option :value="null" disabled >日</option>
          <option v-for="n in 31" :key="n" :value="n" :style="{'color': '#131313'}" >{{n}}日</option>
        </select>
      </div>
    </div> -->
    <div class="m_input-box m_box">
      <label for="contents" class="m_input-label _f4 _eg">Contents</label>
      <input id="contents" v-model="work.contents" placeholder="" class="m_input _f4 _eg">
    </div>
    <div class="m_input-box m_box">
      <label for="description" class="m_input-label _f4 _eg">Description</label>
      <textarea id="description" v-model="work.description" placeholder="" class="m_textarea"></textarea>
    </div>

    <!-- <div class="m_input-box m_box">
      <label for="contents" class="m_input-label _f4 _eg">Main Content</label>
      <input id="contents" v-model="work.mainContent" placeholder="" class="m_input _f4 _eg">
    </div> -->

    <div class="m_input-box m_box">
      <label for="contents" class="m_input-label _f4 _eg">Main Content</label>
      <div class="m_select-container">
        <select v-model="work.mainContent" required class="m_select-2">
          <option :value="null"  disabled>Select a Main Content</option>
          <option v-for="contentOption in mainContentOptions" :key="contentOption" :value="contentOption">{{contentOption}}</option>
        </select>
      </div>
    </div>

    <div class="m_input-box m_box">
      <label for="contents" class="m_input-label _f4 _eg">Website</label>
      <input id="contents" v-model="work.url" placeholder="" class="m_input _f4 _eg">
    </div>
    <div class="m_input-box m_box">
      <label for="description" class="m_input-label _f4 _eg">Shot Description</label>
      <textarea id="description" v-model="work.shortDescription" placeholder="" class="m_textarea"></textarea>
    </div>
    
    <div class="image-upload">
      <label for="image" class="m_input-label _f4 _eg">Photos</label>
      <div class="image-upload-container">
        <div v-for="(imageObject, index) in imageDisp"
              :key="index"
              draggable="true"
              @dragstart="dragstart(imageObject, $event)"
              @dragenter="dragenter(imageObject)"
              @dragover.stop.prevent="dragover"
              @dragend.stop.prevent="dragend"
              class="box preview">
          <div class="preview-image" :style="{'background-image': 'url(' + imageObject.url +')'}"></div>
          <img @click="deleteImageObject(index, )" src="@/assets/images/cross-button-w.png" alt="" class="image-delete">
        </div>
        <div class="box image-upload-button">
          <input type="file" ref="preview" @change="setImage($event)" accept="image/png, image/jpeg" id="image" class="_hide">
          <label for="image" class="image-upload-icon">
            <img  src="@/assets/images/camera-g.png" alt="カメラアイコン" class="camera-icon">
          </label>
        </div>
      </div>
    </div>

    <div class="image-upload">
      <label for="imageV" class="m_input-label _f4 _eg">Vertical Photos</label>
      <div class="image-upload-container">
        <div v-for="(imageObject, index) in imageObjectsV"
              :key="index"
              class="box preview">
          <div class="preview-image" :style="{'background-image': 'url(' + imageObject.url +')'}"></div>
          <img @click="deleteImageObjectV(index)" src="@/assets/images/cross-button-w.png" alt="" class="image-delete">
        </div>
        <div class="box image-upload-button">
          <input type="file" ref="previewV" @change="setImageV($event)" accept="image/png, image/jpeg" id="imageV" class="_hide">
          <label  v-if="imageObjectsV.length <= 1" for="imageV" class="image-upload-icon">
            <img  src="@/assets/images/camera-g.png" alt="カメラアイコン" class="camera-icon">
          </label>
        </div>
      </div>
    </div>

    <button v-if="mode === 'create'" @click="saveImageAndCreate()" :disabled="v$.work.$invalid || isButtonLock"  :class="{'m_invalid-button': v$.work.$invalid}" class="m_submit _eg">Create</button>
    <button v-if="mode === 'edit'" @click="edit()" :disabled="v$.work.$invalid || isButtonLock"  :class="{'m_invalid-button': v$.work.$invalid}" class="m_submit _eg">Edit</button>
    <button v-if="mode === 'edit'" @click="deleteWork()" :disabled="isButtonLock" class="m_delete _eg">Delete</button>
    <p v-if="isError" class="m_error">エラー発生。再度リトライしてください。</p>

  </div>
</template>

<script>

import "@/css/manager.css"
import { db, storage, currentTime} from '@/firebase/index.js'
import Work from "@/class/work.js"
import imageCompression from 'browser-image-compression'
import  useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
// import draggable from "vuedraggable"
import Spinner from '@/components/Spinner.vue'

export default {
  components:{
    // draggable
    Spinner
  },
  props:{
    is_sign: Boolean
},
  data(){
    return{
      pageName: "work",
      isError: false,
      isButtonLock: false,
      mode: "create", //"create" or "edit"

      work: new Work(),
      // compressedImage: null,
      // previewImageUrl: null,
      

      imageObjects:[],
      imageTrashBox:[],
      draggingItem: null, // ドラッグ中の要素を保持するための変数
      imageChangeCounter : 0,

      imageObjectsV:[],
      imageTrashBoxV:[],
      imageChangeCounterV : 0,

      date:{
        year: null,
        month: null,
        day: null
      },

      mainContentOptions: [
        "Interior Design", "Furniture Design", "Graphic Design","Web Design", "Branding", "Art Direction",
      ],
    }
  },
  setup(){
    return { v$: useVuelidate()}
  },
  beforeCreate(){

  },
  created(){
    this.$store.commit('managerPageName', this.pageName)
    if(this.$route.params.id == null){
      this.mode = "create"
      this.work = new Work()
    }else{
      this.mode = "edit"
      db.collection('work').doc(this.$route.params.id).get()
      .then((doc)=>{
        this.work = new Work(doc.data())
        this.imageObjects = this.work.imageObjects
        this.imageObjects.sort((a,b)=>{
          if(a.order < b.order){return -1}
          if(a.order > b.order){return 1}
          return 0
        })

        // urlがまだなかった記事用
        if(!this.work.url){
          this.work.url = ""
        }
        
        if(!this.work.imageObjectsV){
          // imageObjectsHがまだなかった記事用
          this.work.imageObjectsV = []
        }else{
          this.imageObjectsV = this.work.imageObjectsV
          this.imageObjectsV.sort((a,b)=>{
            if(a.order < b.order){return -1}
            if(a.order > b.order){return 1}
            return 0
          })
        }
      })
    }
  },
  mounted(){

  },
  methods:{
    async setImage(event){      

      const originImage = event.target.files[0]
      const options = {
        maxSizeMB: 1.0, //最大ファイルサイズ
        maxWidthOrHeight: 2000, //最大縦横値
      }
      const compressedImage = await imageCompression(originImage, options)
      this.imageObjects.push({url: URL.createObjectURL(originImage), file: compressedImage})
      this.imageObjects.forEach((object, index)=>{
        object.order = index
      })
      this.$refs.preview.value = null
    },
    async setImageV(event){
      // プレビュー用
      const file = this.$refs.previewV.files[0]
      
      // 保存用
      const originImage = event.target.files[0]
      const options = {
        maxSizeMB: 0.6, //最大ファイルサイズ
        maxWidthOrHeight: 800, //最大縦横値
      }
      const compressedImage = await imageCompression(originImage, options)
      this.imageObjectsV.push({url: URL.createObjectURL(file), file: compressedImage})
      this.imageObjectsV.forEach((object, index)=>{
        object.order = index
      })
      this.$refs.previewV.value = null
    },
    deleteImageObject(index){
      if(this.imageObjects[index].name){
        const imageTrash = this.imageObjects[index]
        this.imageTrashBox.push(imageTrash)
        this.imageObjects.splice(index, 1)
      }else{
        this.imageObjects.splice(index, 1)
      }
      this.imageObjects.forEach((object, index)=>{
        object.order = index
      })
    },
    deleteImageObjectV(index){
      if(this.imageObjectsV[index].name){
        const imageTrash = this.imageObjectsV[index]
        this.imageTrashBoxV.push(imageTrash)
        this.imageObjectsV.splice(index, 1)
      }else{
        this.imageObjectsV.splice(index, 1)
      }
      this.imageObjectsV.forEach((object, index)=>{
        object.order = index
      })
    },

    async saveImageAndCreate(){
      this.isButtonLock = true
      let workId = null
      this.work.completion = Number(this.work.completion)
      this.imageObjects.forEach((imageObject, index)=>{
        imageObject.order = index
      })
      this.imageObjectsV.forEach((imageObject, index)=>{
        imageObject.order = index
      })
      
      let imageObjectsForSave = []
      let imageObjectsVForSave = []
      this.work.createdAt = currentTime
      this.work.updatedAt = currentTime
      db.collection('work').add({...this.work})
      .then(async (doc)=>{
        workId = doc.id
        db.collection('work').doc(workId).update({ id: workId})

        
        const imageObjectsToReturn = []
        const promises = []
        this.imageObjects.forEach((imageObject, index)=>{
          const fileName = `work/${workId + "_" + String(this.work.updateCounter) + "_" + String(index)}`
          promises.push(storage.ref().child(fileName).put(imageObject.file)
          .then( async (snapShot)=>{
            const url = await snapShot.ref.getDownloadURL()
            imageObjectsToReturn.push({url: url, order: imageObject.order, name: fileName})
          }))
        })
        imageObjectsForSave = await Promise.all(promises).then(()=>{return imageObjectsToReturn})

        
        const imageObjectsVToReturn = []
        const promisesV = []
        this.imageObjectsV.forEach((imageObject, index)=>{
          const fileName = `work/${workId + "_" + "v" + String(this.work.updateCounter) + "_" + String(index)}`
          promisesV.push(storage.ref().child(fileName).put(imageObject.file)
          .then( async (snapShot)=>{
            const url = await snapShot.ref.getDownloadURL()
            imageObjectsVToReturn.push({url: url, order: imageObject.order, name: fileName})
          }))
        })
        imageObjectsVForSave = await Promise.all(promisesV).then(()=>{return imageObjectsVToReturn}) 
        
        db.collection('work').doc(workId).update({ imageObjects: imageObjectsForSave, imageObjectsV: imageObjectsVForSave})
        .then(()=>{
          this.isButtonLock = false
          this.$router.push({name: 'ManagerWork'})
        })
        .catch((error)=>{
          console.log(error.message)
          db.collection('work').doc(workId).delete()
          this.isButtonLock = false
          this.isError = true
        })
      })
      .catch((error)=>{
        console.log(error.message)
        this.isButtonLock = false
        this.isError = true
      })
    },

    async edit(){
      this.isButtonLock = true
      this.work.completion = Number(this.work.completion)
      this.work.updateCounter = this.work.updateCounter + 1
      const workId = this.$route.params.id
      this.work.updatedAt = currentTime


      this.imageObjects.forEach((imageObject, index)=>{
        imageObject.order = index
      })
      let imageObjectsForStorage = this.imageObjects.filter((object)=>{
        return object.name == null
      })
      this.imageObjects = this.imageObjects.filter((object)=>{
        return object.name != null
      })
      const imageObjectsToReturn = []
      const promises = []
      imageObjectsForStorage.forEach((imageObject, index)=>{
        const fileName = `work/${workId + "_" + String(this.work.updateCounter) + "_" + String(index)}`
        promises.push(storage.ref().child(fileName).put(imageObject.file)
        .then( async (snapShot)=>{
          const url = await snapShot.ref.getDownloadURL()
          imageObjectsToReturn.push({url: url, order: imageObject.order, name: fileName})
        }))
      })
      let imageObjectsFromStorage = await Promise.all(promises).then(()=>{return imageObjectsToReturn})
      imageObjectsFromStorage.forEach((imageObject)=>{
        this.imageObjects.push(imageObject)
      })
      this.imageTrashBox.forEach((object)=>{
        storage.ref().child(object.name).delete()
        .then(()=>{
        })
        .catch((error)=>{
          console.log(error.message)
        })
      })
      this.work.imageObjects = this.imageObjects


      this.imageObjectsV.forEach((imageObject, index)=>{
        imageObject.order = index
      })
      let imageObjectsVForStorage = this.imageObjectsV.filter((object)=>{
        return object.name == null
      })
      this.imageObjectsV = this.imageObjectsV.filter((object)=>{
        return object.name != null
      })
      const imageObjectsVToReturn = []
      const promisesV = []
      imageObjectsVForStorage.forEach((imageObject, index)=>{
        const fileName = `work/${workId + "_" + "v" + String(this.work.updateCounter) + "_" + String(index)}`
        promisesV.push(storage.ref().child(fileName).put(imageObject.file)
        .then( async (snapShot)=>{
          const url = await snapShot.ref.getDownloadURL()
          imageObjectsVToReturn.push({url: url, order: imageObject.order, name: fileName})
        }))
      })
      let imageObjectsVFromStorage = await Promise.all(promisesV).then(()=>{return imageObjectsVToReturn})
      imageObjectsVFromStorage.forEach((imageObject)=>{
        this.imageObjectsV.push(imageObject)
      })
      this.imageTrashBoxV.forEach((object)=>{
        storage.ref().child(object.name).delete()
        .then(()=>{
        })
        .catch((error)=>{
          console.log(error.message)
        })
      })
      this.work.imageObjectsV = this.imageObjectsV


      db.collection('work').doc(this.$route.params.id).update({...this.work})
      .then(()=>{
        this.isButtonLock = false
        this.$router.push({name: 'ManagerWork'})
      })
      .catch((error)=>{
        console.log(error.message)
        this.isError = true
      })
    },

    async deleteWork(){
      const promises = []
      this.work.imageObjects.forEach((object)=>{
        promises.push(storage.ref().child(object.name).delete()
        .then(()=>{
        })
        .catch((error)=>{
          console.log(error.message)
        }))
      })
      this.work.imageObjectsV.forEach((object)=>{
        promises.push(storage.ref().child(object.name).delete()
        .then(()=>{
        })
        .catch((error)=>{
          console.log(error.message)
        }))
      })
      await Promise.all(promises)
      .then(()=>{
        db.collection('work').doc(this.work.id).delete()
        .then(()=>{
          this.$router.push({name: 'ManagerWork'})
        })
        .catch((error)=>{
          console.log(error.message)
          this.isError = true
        })
      })
    },

    dragstart(object, e) {
      this.draggingItem = object; // ドラッグ中の要素を保持
      e.dataTransfer.effectAllowed = 'move'; // 移動モードに設定
      e.target.style.opacity = 0.5; // ドラッグ中要素のスタイルを変更
    },
    dragenter(object) {
      // ドラッグ中の要素とドラッグ先の要素の表示順を入れ替える
      [object.order, this.draggingItem.order] = [this.draggingItem.order, object.order];
    },
    dragover(e) {
      e.dataTransfer.dropEffect = 'move'; // 移動モードに設定
    },
    dragend(e) {
      e.target.style.opacity = 1; // ドラッグ中要素のスタイルを変更（元に戻す）
      this.draggingItem = null; // ドラッグ中の要素をクリア
    },


  },

  computed:{
    imageDisp() {
      return [...this.imageObjects].sort((a, b) => a.order - b.order)
    },
  },
  watch: {
    imageDisp:{
      deep: true,
      handler: function (){
        return this.imageObjects.sort((a, b) => a.order - b.order)
      }
    },
  },
  validations(){
    return{
      work:{
        title:{
          required
        },
      },

    }
  },
}
</script>


<style scoped>
.image{
  width: 100%;
}

.image-upload{
  margin-bottom: 22px;

}
.image-upload-label{
  font-size: 1.3rem;
  color: var(--gray-hi);
}
.image-upload-container{
  margin-top: 5px;
  display: flex;
  white-space:nowrap;
  overflow-x: auto;
}
.box{
  margin-right: 10px;
  display: block;
  width: 100px;  
}
.image-upload-icon{
  display: block;
  background-color: var(--white);
  width: 100px;
  height: 100px;
  position: relative;
  box-sizing: border-box;
    border: 1px solid #b3a69f;
}

.camera-icon{
  position: absolute;
  width: 34px;
  height: 34px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.image-upload-icon p{
  color: var(--gray-hi);
  position: absolute;
  top: 0px;
  left: 5px;
  font-weight: 700;
}
.preview{
  position: relative;
}
.preview-image{
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: cover;
}
.image-delete{
  width: 18px;
  height: 18px;
  position: absolute;
  top: 4px;
  right: 4px;
}

@media screen and (min-width:600px){

}

</style>