import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/css/share.css'
import '@/css/reset.css'
import '@/css/hinamori.css'
import store from './store'
// googleアナリティクス用
import VueGtag from "vue-gtag-next"

createApp(App)
  .use(store)
  .use(router)
  .use(VueGtag, { property: { id: 'G-5BBZPSLGLJ', router } })
  .mount('#app')
