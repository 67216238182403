import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Information from '@/views/Information.vue'
import Contact from '@/views/Contact.vue'
import Journal from '@/views/Journal.vue'
import JournalLineup from '@/views/JournalLineup.vue'
import Work from '@/views/Work.vue'
import WorkLineup from '@/views/WorkLineup.vue'
import OtherWorkLineup from '@/views/OtherWorkLineup.vue'
import Thanks from '@/views/Thanks.vue'
import Hinamori from '@/views/Hinamori.vue'
import Hinamori2 from '@/views/Hinamori2.vue'
import Collection1 from '@/views/Collection.vue'
import Element from '@/views/Element.vue'
import Experiment1 from '@/views/experiment/Experiment1.vue'
import HinamoriSite from '@/views/HinamoriSite.vue'
import Mui from '@/views/Mui.vue'
import Yado from '@/views/Yado.vue'
import Privacy from '@/views/Privacy.vue'
import Manager from '@/views/manager/Home.vue'
import ManagerSignUp from '@/views/manager/SignUp.vue'
import ManagerSignIn from '@/views/manager/SignIn.vue'
import ManagerJournal from '@/views/manager/Journal.vue'
import ManagerCreateJournal from '@/views/manager/CreateJournal.vue'
import ManagerOrderJournal from '@/views/manager/OrderJournal.vue'
import ManagerWork from '@/views/manager/Work.vue'
import ManagerCreateWork from '@/views/manager/CreateWork.vue'
import ManagerElement from '@/views/manager/Element.vue'
import ManagerCreateElement from '@/views/manager/CreateElement.vue'
import ManagerMember from '@/views/manager/Member.vue'
import ManagerTopImages from '@/views/manager/TopImages.vue'
import ManagerEditTopImages from '@/views/manager/EditTopImages.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props:true
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    props:true
  },
  {
    path: '/information',
    name: 'Information',
    component: Information,
    props:true
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    props:true
  },
  {
    path: '/journal/:id',
    name: 'Journal',
    component: Journal,
    props:true
  },
  {
    path: '/journal/lineup',
    name: 'JournalLineup',
    component: JournalLineup,
    props:true
  },
  {
    path: '/works/:id',
    name: 'Work',
    component: Work,
    props:true
  },
  {
    path: '/works/lineup',
    name: 'WorkLineup',
    component: WorkLineup,
    props:true
  },
  {
    path: '/works/other/lineup',
    name: 'OtherWorkLineup',
    component: OtherWorkLineup,
    props:true
  },
  {
    path: '/thanks',
    name: 'Thanks',
    component: Thanks,
    props:true
  },
  {
    path: '/hinamori-2021',
    name: 'Hinamori',
    component: Hinamori,
    props:true
  },
  {
    path: '/hinamori',
    name: 'Hinamori2',
    component: Hinamori2,
    props:true
  },
  {
    path: '/collection',
    name: 'Collection1',
    component: Collection1,
    props:true
  },
  {
    path: '/experiment/kareha',
    name: 'Experiment1',
    component: Experiment1,
    props:true
  },
  {
    path: '/experiment/:id',
    name: 'Experiment',
    component: Element,
    props:true
  },
  {
    path: '/collection/:id',
    name: 'Collection',
    component: Element,
    props:true
  },
  {
    path: '/hinamori-official',
    name: 'HinamoriSite',
    component: HinamoriSite,
    props:true
  },
  {
    path: '/mui',
    name: 'Mui',
    component: Mui,
    props:true
  },
  {
    path: '/yado',
    name: 'Yado',
    component: Yado,
    props:true
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    props:true
  },
  {
    path: '/kdsaibiqofisbe2nide3e1manager',
    name: 'Manager',
    component: Manager,
    children:[
      {
        path: '/kdsaibiqofisbe2nide3e1manager/sign-up',
        name: 'ManagerSignUp',
        component: ManagerSignUp,
        props:true
      },
      {
        path: '/kdsaibiqofisbe2nide3e1manager/sign-in',
        name: 'ManagerSignIn',
        component: ManagerSignIn,
        props:true
      },
      {
        path: '/kdsaibiqofisbe2nide3e1manager/journal/create',
        name: 'ManagerCreateJournal',
        component: ManagerCreateJournal,
        props:true
      },
      {
        path: '/kdsaibiqofisbe2nide3e1manager/journal/edit/:id',
        name: 'ManagerEditJournal',
        component: ManagerCreateJournal,
        props:true
      },
      {
        path: '/kdsaibiqofisbe2nide3e1manager/journal',
        name: 'ManagerJournal',
        component: ManagerJournal,
        props:true
      },
      {
        path: '/kdsaibiqofisbe2nide3e1manager/journal/order',
        name: 'ManagerOrderJournal',
        component: ManagerOrderJournal,
        props:true
      },
      {
        path: '/kdsaibiqofisbe2nide3e1manager/work/create',
        name: 'ManagerCreateWork',
        component: ManagerCreateWork,
        props:true
      },
      {
        path: '/kdsaibiqofisbe2nide3e1manager/work/edit/:id',
        name: 'ManagerEditWork',
        component: ManagerCreateWork,
        props:true
      },
      {
        path: '/kdsaibiqofisbe2nide3e1manager/work',
        name: 'ManagerWork',
        component: ManagerWork,
        props:true
      },
      {
        path: '/kdsaibiqofisbe2nide3e1manager/experiment/create',
        name: 'ManagerCreateElement',
        component: ManagerCreateElement,
        props:true
      },
      {
        path: '/kdsaibiqofisbe2nide3e1manager/experiment/edit/:id',
        name: 'ManagerEditElement',
        component: ManagerCreateElement,
        props:true
      },
      {
        path: '/kdsaibiqofisbe2nide3e1manager/experiment',
        name: 'ManagerElement',
        component: ManagerElement,
        props:true
      },
      {
        path: '/kdsaibiqofisbe2nide3e1manager/member',
        name: 'ManagerMember',
        component: ManagerMember,
        props:true
      },
      {
        path: '/kdsaibiqofisbe2nide3e1manager/top-images',
        name: 'ManagerTopImages',
        component: ManagerTopImages,
        props:true
      },
      {
        path: '/kdsaibiqofisbe2nide3e1manager/top-images/edit',
        name: 'ManagerEditTopImages',
        component: ManagerEditTopImages,
        props:true
      },
      
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  //リンク後にスクロールをトップに戻す
  scrollBehavior () {
    return document.getElementById('app').scrollIntoView();
  }
})

// googleアナリティクス用
import { trackRouter } from "vue-gtag-next"
trackRouter(router)

export default router
