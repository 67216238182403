export default class Element{

  id = null 
  type = "collection" //or "experiment" 
  title = ""
  shotText = ""
  firstText = ""
  secondText = ""
  isSubImage = false
  movieIds = []
  month = null
  day = null
  year = null
  topImageObjects = []
  subImageObjects = []
  verticalImageObjects = []
  imageVPosition = "top" // or "bottom"
  url = ""
  order = null
  isPublic = true
  createdAt = null
  updatedAt = null
  updateCounter = 0

  constructor(element){
    if(element == null){return}
    this.id = element.id
    this.type = element.type
    this.title = element.title
    this.shotText = element.shotText
    this.firstText = element.firstText
    this.secondText = element.secondText
    this.isSubImage = element.isSubImage
    this.movieIds = element.movieIds
    this.month = element.month
    this.day = element.day
    this.year = element.year
    this.topImageObjects = element.topImageObjects
    this.subImageObjects = element.subImageObjects
    this.verticalImageObjects = element.verticalImageObjects
    this.imageVPosition = element.imageVPosition
    this.url = element.url
    this.order = element.order
    this.isPublic = element.isPublic
    this.createdAt = element.createdAt
    this.updatedAt = element.updatedAt
    this.updateCounter = element.updateCounter
  }

}