<template>
  <div class="header _eg" :class="{'hide': isHide}">
    <div class="header-container">
      <div class="header-left">
        <router-link :to="{name: 'Home'}"><img src="@/assets/images/logo.png" alt="JIBI" class="logo" :class="{'hide': isOpen}"></router-link>
        <div v-if="backPath"  class="title">
          <router-link :to="{name: backPath}" >{{ pageTitle }}</router-link>
        </div>
        <div v-else  class="title">
          <h1 v-if="pageTitle !== 'Home' && pageTitle !== 'NoTitle'">{{pageTitle}}</h1>
        </div>
        
      </div>
      <div @click="open()" class="navi" :class="{'hide': isOpen}"><span class="line1"></span><span class="line2"></span><span class="line3"></span></div>
    </div>

  </div>
  
</template>

<script>


export default {
  props:{
    isOpen: Boolean,
    pageTitle: String, //"Notitle"を入れると左部分を非表示
    currentPosition: Number,
    backPath: String
  },
  data(){
    return{
      isHide: false,
      positionY:0
    }
  },

  // mounted(){
  //   window.addEventListener('scroll', this.onScroll)
  // },
  // beforeUnmount(){
  //   window.removeEventListener('scroll', this.onScroll)
  // },
  methods:{
    open(){
      this.$emit('open')
    },

  },
  watch: {
    currentPosition:{
      handler: function(){
        if(!this.isOpen){
          if(this.positionY > window.scrollY){
            this.isHide = false
          }else{
            if(window.scrollY - this.positionY > 30){
              this.isHide = true
            }
          }
          this.positionY = window.scrollY
        }


      },
    }
  },
  computed: {
    
  }
}
</script>

<style scoped>
.header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100px;
  z-index: 40;
  transition: 1s;
}
.hide{
  -webkit-transform: translate(0, -100px);
  transform: translate(0, -100px);
}
.header-container{
  display: flex;
  justify-content: space-between;
  margin: 80px auto 0;
  width: 100%;
}
.header-left{
  display: flex;
}
.logo{
  width: 90px;
  height: 46px;
  margin-left: 60px;
  opacity: 1;
  transition: 0.3s;
}
.title{
  font-size: 2rem;
  color: var(--gray-sub);
  -webkit-transform: translate(40px, 8px);
  transform: translate(40px, 8px);
}
.navi{
  width: 36px;
  height: 36px;
  margin-right: 60px;
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
}
.hide{
  opacity: 0;
}
.line1{
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--black);
}
.line2{
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--black);
  -webkit-transform: translate(0, 12px);
  transform: translate(0, 12px);
}
.line3{
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--black);
  -webkit-transform: translate(0, 24px);
  transform: translate(0, 24px);
}


@media screen and (max-width:599px) {
  .header-container{
    margin: 30px auto 0;
    width: 90%;
  }
  .logo{
    width: 60px;
    height: 30px;
    margin-left: 0;
  }
  .title{
    font-size: 1.7rem;
    color: var(--gray-sub);
    -webkit-transform: translate(20px, -1px);
    transform: translate(20px, -1px);
  }
  .navi{
    width: 28px;
    height: 28px;
    margin-right: 0;
  }
  .line2{
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
  .line3{
    display: block;
    width: 100%;
    height: 1px;
    background-color: var(--black);
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
  }
}
@media screen and (min-width:600px) and (max-width:1100px){
  .header-container{
    width: 90%;
  }
  .logo{
    margin-left: 0;
  }
  .navi{
    margin-right: 0;
  }

}
</style>