<template>
  <div class="_bland-base" :style="{ 'background-image': 'url(' + image +')'}">
    <HeaderWhite :isOpen="isOpenNavi" :pageTitle="pageTitle" @open="openNavi()" :currentPosition="_currentPosition"></HeaderWhite>
    <Navi :isOpen="isOpenNavi" @close="closeNavi"></Navi>
    <!-- <GoTop></GoTop> -->
    
    <div  class="_bland-content" >
      <div class="_bland-container">
        <div class="_left">
          <div class="_left-content">

          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <TimeShowing :appearanceTime="1000">
              <p class="_bland-title">この星の息づかい</p>
            </TimeShowing>
            <TimeShowing :appearanceTime="2500">
              <div class="_bland-info">
                <p class="_bland-text">
                  この星の、<br>
                  この自然の、<br>
                  この人間の、<br>
                  息づく姿を、<br>
                  幾重に重ねるように、<br>
                  心の記憶に、<br>
                  そっと微笑む。<br><br>
                  それはきっと、愛おしさそのもの。
                </p>
                <p class="_bland-date">2022. 3. 26 OPEN</p>
                <a href="https://www.instagram.com/jibi_ig/?hl=ja" target="_blank"><img src="@/assets/images/insta-w.png" alt="インスタグラム" class="insta"></a>
              </div>
            </TimeShowing>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import HeaderWhite from '@/components/HeaderWhite.vue'
import Navi from '@/components/Navi.vue'
import TimeShowing from '@/components/TimeShowing.vue'
// import GoTop from '@/components/GoTop.vue'
import Mixin from '@/mixin/mixin.js'

export default {
  components:{
    HeaderWhite,
    Navi,
    TimeShowing,
    // GoTop
  },
  props:{

},
  data(){
    return{
      pageTitle: "陽森",
      isOpenNavi: false,

      image: require('@/assets/images/hinamori_main.jpg'),
    }
  },
  mixins:[
    Mixin
  ],
  beforeCreate(){
    
  },
  created(){
    
    
  },
  mounted(){

  },
  beforeDestory(){
    
  },
  methods:{
    // ナビ用
    openNavi(){
      this.isOpenNavi = true
      this._stop(true)
    },
    closeNavi(){
      this.isOpenNavi = false
      this._stop(false)
    },


  },
  watch: {

  },
  computed:{

  },

}
</script>

<style scoped>


.insta{
  width: 30px;
  height: 30px;
}

@media screen and (max-width:599px){



}



/* タブレット */
@media screen and (min-width:600px) and (max-width:860px){

}
</style>