<template>
  <div class="_base">
    <Header :isOpen="isOpenNavi" :pageTitle="pageTitle" @open="openNavi()" :currentPosition="_currentPosition"></Header>
    <Navi :isOpen="isOpenNavi" @close="closeNavi"></Navi>
    <!-- <GoTop></GoTop> -->
    
    <div  class="_content" >
      <div class="container">
        <div class="_left">
          <div class="_left-content">
            <h2 class="_left-title _eg-b">Job Offer</h2>
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <p class="_text _marginL">
              お仕事のご依頼はこちらまで。<br>
              担当 | 伊藤 有美<a class="_mail" href="mailto:info@jibi.co.jp?subject=問い合わせ">info@jibi.co.jp</a>
            </p>
            <p class="_text">
              海外のお仕事のご依頼はこちらまで。<br>
              For work-related requests from abroad, please contact us using the e-mail address below.<br>
              担当 | 花塚 明菜<a class="_mail" href="mailto:akina.h@jibi.co.jp?subject=依頼">akina.h@jibi.co.jp</a>
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="_left">
          <div class="_left-content">
            <h2 class="_left-title _eg-b">Public Relations</h2>
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <p class="_text">
              JIBI に関する取材依頼及びその他のお問い合わせは下記までお願い致します。<br>
              担当 | 伊藤 有美<a class="_mail" href="mailto:info@jibi.co.jp?subject=問い合わせ">info@jibi.co.jp</a>
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="_left">
          <div class="_left-content">
            <h2 class="_left-title _eg-b">Head Office</h2>
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <p class="_text">
              〒745-0057 山口県周南市大津島1456
            </p>
            <p class="_text">
              1456 Ohzu-island, Shunan, Yamaguchi,<br>
              745-0057, Japan
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer :pageTitle="pageTitle"></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'
// import GoTop from '@/components/GoTop.vue'
import Mixin from '@/mixin/mixin.js'

export default {
  components:{
    Header,
    Navi,
    Footer,
    // GoTop
  },
  props:{

},
  data(){
    return{
      pageTitle: "Contact",
      isOpenNavi: false,
    }
  },
  mixins:[
    Mixin
  ],
  beforeCreate(){
    
  },
  created(){
    
    
  },
  mounted(){

  },
  beforeDestory(){
    
  },
  methods:{
    // ナビ用
    openNavi(){
      this.isOpenNavi = true
      this._stop(true)
    },
    closeNavi(){
      this.isOpenNavi = false
      this._stop(false)
    },


  },
  watch: {

  },
  computed:{

  },

}
</script>

<style scoped>
.container{
  width: 100%;
  display: flex;
  padding-bottom: 80px;
}

@media screen and (max-width:599px){
  .container{
    width: 90%;
    display: block;
    padding-bottom: 50px;
    margin: 0 auto;
  }
}



/* タブレット */
@media screen and (min-width:600px) and (max-width:860px){

}
</style>