export default class Member{

  id = null
  email = null
  createdAt = null
  updatedAt = null

  constructor(member){
    if(member == null){return}
    this.id = member.id
    this.email = member.email
    this.createdAt = member.createdAt
    this.updatedAt = member.updatedAt
    
  }

}