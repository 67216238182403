<template>
  <div class="m_base">
    <p class="m_title">Sign Up</p>
    <div class="m_input-box m_box">
      <label for="email" class="m_input-label _f4 _eg">Email</label>
      <input id="email" v-model="email" placeholder="Email" class="m_input _f5 _eg">
    </div>
    <div class="m_input-box m_box">
      <label for="password" class="m_input-label _f4 _eg">Pass Word</label>
      <input id="password" v-model="password" placeholder="Pass Word" class="m_input _f5 _eg">
    </div>
    <button @click="signup()" class="m_submit _eg">Submit</button>
    <p v-if="isError" class="m_error">エラー発生。再度リトライしてください。</p>
  </div>
</template>

<script>

import "@/css/manager.css"
import {auth} from '@/firebase/index.js'

export default {
  components:{

  },
  props:{

},
  data(){
    return{
      isError: false,
      email:null,
      password:null
    }
  },
  beforeCreate(){

  },
  created(){
  },
  mounted(){

  },
  methods:{
    signup(){
      auth.createUserWithEmailAndPassword(this.email, this.password)
      .then(()=>{
        this.$router.push({name: 'Manager'})
      })
      .catch((error)=>{
        console.log(error.message)
        this.isError = true
      })
    }
    
  },
  watch: {

  },
  computed:{

  },

}
</script>


<style scoped>



@media screen and (min-width:600px){

}

</style>