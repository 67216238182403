export default class Journal{

  // 手動で order を変える時は 一番最後の記事の isNew をtrue にして 、最後じゃなくなったものを false に
  //改編で orderをuiでいじれるようにしたのでisNewはつかわなくした

  id = null
  order= null //Number create するときに isNew が true のorder に +1 したものがつく。
  title = ""
  subTitle = ""
  text = ""
  month = null
  day = null
  year = null
  imageName = null
  imageUrl = null
  imageObjects = []
  link = null
  isPublic = true
  isNew = false
  style = "normal" //"normal", "haiku", "scroll", "plural"
  color = "#4c4948"
  createdAt = null
  updatedAt = null
  updateCounter = 0

  constructor(journal){
    if(journal == null){return}
    this.id = journal.id
    this.order = journal.order
    this.title = journal.title
    this.subTitle = journal.subTitle
    this.text = journal.text
    this.month = journal.month
    this.day = journal.day
    this.year = journal.year
    this.imageName = journal.imageName
    this.imageUrl = journal.imageUrl
    this.imageObjects = journal.imageObjects
    this.link = journal.link
    this.isPublic = journal.isPublic
    this.isNew = journal.isNew
    this.style = journal.style
    this.color = journal.color
    this.createdAt = journal.createdAt
    this.updatedAt = journal.updatedAt
    this.updateCounter = journal.updateCounter
    
  }

}