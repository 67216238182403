<template>
  <div class="_base">
    <Header :isOpen="isOpenNavi" :pageTitle="pageTitle" @open="openNavi()" :currentPosition="_currentPosition"></Header>
    <Navi :isOpen="isOpenNavi" @close="closeNavi"></Navi>
    <!-- <GoTop></GoTop> -->

    <div class="content">
      <div class="container">
        <div class="_box-container">
          <router-link v-for="journal in journals" :to="{name: 'Journal', params:{id: journal.id}}" :key="journal.id" class="_box">
            <img :src="journal.imageUrl" :alt="journal.title" class="_box-image">
            <p class="_box-journal-day _eg">{{journal.month}} {{journal.day}} {{journal.year}}</p>
            <h2 class="_box-journal-title _f3">{{journal.title}}</h2>
            <p class="_box-journal-sub-title">{{journal.subTitle}}</p>
          </router-link>
          <div v-if="journals.length%3 === 2" class="_box-dummy"></div>
        </div>
      </div>
    </div>
    <Footer :pageTitle="pageTitle"></Footer>

  </div>
</template>

<script>
import {db} from '@/firebase/index.js'
import Header from '@/components/Header.vue'
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'
// import GoTop from '@/components/GoTop.vue'
import Mixin from '@/mixin/mixin.js'

export default {
  components:{
    Header,
    Navi,
    Footer,
    // GoTop
  },
  props:{

},
  data(){
    return{
      pageTitle: "Journal",
      isOpenNavi: false,
      
      journals:[],
    }
  },
  mixins:[
    Mixin
  ],
  beforeCreate(){
    
  },
  created(){
    db.collection('journal').where('isPublic', '==', true).orderBy('order', 'desc').get()
    .then(querySnapShot=>{
      querySnapShot.forEach(doc=>{
        this.journals.push(doc.data())
      })
      this.journals.forEach(journal=>{
        if(journal.style === 'plural'){
          journal.imageObjects.forEach(imageObject=>{
            if(imageObject.order === 0){
              journal.imageUrl = imageObject.url
            }
          })
        }
      })
    })

  },
  mounted(){

  },
  beforeDestory(){
    
  },
  methods:{
    // ナビ用
    openNavi(){
      this.isOpenNavi = true
      this._stop(true)
    },
    closeNavi(){
      this.isOpenNavi = false
      this._stop(false)
    },


  },
  watch: {

  },
  computed:{

  },

}
</script>

<style scoped>

.content{
  background-color: var(--bg);
  width: 100%;
  height: auto;
  padding-bottom: 100px;
  min-height: calc(100vh - 270px);
}

.container{
  padding-top: 300px;
  margin: 0 auto;
  width: 90%;
  max-width: 1400px;
}




@media screen and (max-width:599px){
 
  .content{
    padding-bottom: 50px;
    min-height: calc(100vh - 270px);
  }

  .container{
    padding-top: 120px;
  }


}
@media screen and (min-width:600px) and (max-width:1100px){

}

/* タブレット */
@media screen and (min-width:600px) and (max-width:860px){

}
</style>