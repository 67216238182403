<template>
  <div class="_bland-base" :style="{ 'background-image': 'url(' + image +')'}">
    <Header :isOpen="isOpenNavi" :pageTitle="pageTitle" @open="openNavi()" :currentPosition="_currentPosition"></Header>
    <Navi :isOpen="isOpenNavi" @close="closeNavi"></Navi>
    <!-- <GoTop></GoTop> -->
    
    <!-- <div  class="_bland-content" >
      <div class="_bland-container">
        <div class="_left">
          <div class="_left-content">

          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <p class="_bland-title">この星の息づかい</p>
            <div class="_bland-info">
              <p class="_bland-text">
                この星の、<br>
                この自然の、<br>
                この人間の、<br>
                息づく姿を、<br>
                幾重に重ねるように、<br>
                心の記憶に、<br>
                そっと微笑む。<br><br>
                それはきっと、愛おしさそのもの。
              </p>
              <p class="_bland-date">2022. 2. 3 OPEN</p>
              <a href="https://www.instagram.com/jibi_ig/?hl=ja" target="_blank"><img src="@/assets/images/insta-w.png" alt="インスタグラム" class="insta"></a>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <p class="coming _eg">Coming soon</p>

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Navi from '@/components/Navi.vue'
// import GoTop from '@/components/GoTop.vue'
import Mixin from '@/mixin/mixin.js'

export default {
  components:{
    Header,
    Navi,
    // GoTop
  },
  props:{

},
  data(){
    return{
      pageTitle: "宿 yado",
      isOpenNavi: false,

      image: require('@/assets/images/yado.jpg'),
    }
  },
  mixins:[
    Mixin
  ],
  beforeCreate(){
    
  },
  created(){
    
    
  },
  mounted(){

  },
  beforeDestory(){
    
  },
  methods:{
    // ナビ用
    openNavi(){
      this.isOpenNavi = true
      this._stop(true)
    },
    closeNavi(){
      this.isOpenNavi = false
      this._stop(false)
    },


  },
  watch: {

  },
  computed:{

  },

}
</script>

<style scoped>
.coming{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: var(--white);
  font-size: 2.2rem;
  letter-spacing: 1px;

}

.insta{
  width: 30px;
  height: 30px;
}

@media screen and (max-width:599px){



}



/* タブレット */
@media screen and (min-width:600px) and (max-width:860px){

}
</style>