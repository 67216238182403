export default class TopImages{

  id = null 
  imageObjects = []
  isPublic = true
  createdAt = null
  updatedAt = null
  updateCounter = 0

  constructor(topImages){
    if(topImages == null){return}
    this.id = topImages.id
    this.imageObjects = topImages.imageObjects
    this.createdAt = topImages.createdAt
    this.updatedAt = topImages.updatedAt
    this.updateCounter = topImages.updateCounter
  }

}