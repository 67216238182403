<template>
  <div class="base">
    <Spinner v-if="isLoadTop"  :isBackground="false"></Spinner>
    <Navi :isOpen="isOpenNavi" @close="closeNavi"></Navi>
    <Header :isOpen="isOpenNavi" :pageTitle="pageTitle" @open="openNavi()" :currentPosition="_currentPosition"></Header>
    <!-- <GoTop> </GoTop> -->
    <scrollParallax :speed="0.6">
      <div class="top-images" >
        <div class="top-image-container">
          <!-- <img :src="" class="top-image" :class="{'top-image-active': imageMark === 'a'}" :style="{ 'background-image': 'url(' + imageA +')'}"></div>
          <img class="top-image" :class="{'top-image-active': imageMark === 'b'}" :style="{ 'background-image': 'url(' + imageB +')'}"></div>
          <img class="top-image" :class="{'top-image-active': imageMark === 'c'}" :style="{ 'background-image': 'url(' + imageC +')'}"></div>
          <img class="top-image" :class="{'top-image-active': imageMark === 'd'}" :style="{ 'background-image': 'url(' + imageD +')'}"></div> -->
          
          <img :src="imageA" class="top-image" :class="{'top-image-active': imageMark === 'a', 'top-image-safari': isSafari}" >
          <img :src="imageB" class="top-image" :class="{'top-image-active': imageMark === 'b', 'top-image-safari': isSafari}" >
          <img :src="imageC" class="top-image" :class="{'top-image-active': imageMark === 'c', 'top-image-safari': isSafari}">
          <img :src="imageD" class="top-image" :class="{'top-image-active': imageMark === 'd', 'top-image-safari': isSafari}">
        </div>
        <div class="image-switch-container">
          <div class="image-switch">
            <div @click="imageChange('a')" class="switch"><div class="switch-line" :class="{'switch-active': imageMark === 'a'}"></div></div>
            <div @click="imageChange('b')" class="switch"><div class="switch-line" :class="{'switch-active': imageMark === 'b'}"></div></div> 
            <div @click="imageChange('c')" class="switch"><div class="switch-line" :class="{'switch-active': imageMark === 'c'}"></div></div> 
            <div @click="imageChange('d')" class="switch"><div class="switch-line" :class="{'switch-active': imageMark === 'd'}"></div></div> 
          </div>
        </div>
      </div>
    </scrollParallax>
  
    <scrollParallax :speed="0.15">
      <div class="content">
        <div class="message">
          <Show :currentPosition="_currentPosition" :isCompletedLoad="isCompletedLoad">
            <div class="message-jp">
              <p>
                自然と向き合う時に感じる、<br>
                美しい気配を手許へ引き寄せ、<br>
                自らの手を動かし、<br>
                そこから発現するものを表現したい。
              </p>
              <p>
                それは、自然との関わりの中で<br>
                人の振舞いが起こす、希望そのもの。
              </p>
              <p>
                いつくし風景、花を添える。
              </p>
              <div class="message-last-box">
                <p>そこに在る限り、</p>
                <div class="message-last">
                  <p>自ずから美しいように</p><span class="message-line"></span>
                </div>
              </div>
            </div>
          </Show>
          <Show :currentPosition="_currentPosition" :isCompletedLoad="isCompletedLoad">
            <div class="message-eg">
              <p class="_eg">
                The beautiful sensations felt upon engaging with nature motivate my own hands,<br>
                and what emerges becomes my form of expression.<br>
                The creations of human beings should be seen as part of a larger relation to nature,<br>
                just as a flower compliments the landscape around it elegantly, rather than being in competition with it.
              </p>
            </div>
          </Show>
        </div>
        

        <Show :currentPosition="_currentPosition" :isCompletedLoad="isCompletedLoad">
          <div class="about container">
            <h2 class="title _eg-b _f2 SP-about-title">About</h2>
            <router-link :to="{name: 'About'}" class="about-left">
              <img src="@/assets/images/about.jpg" alt="">
            </router-link>
            <router-link :to="{name: 'About'}" class="about-right">
              <h2 class="about-title  _f2 _eg-b">About</h2>
              <div class="about-content">
                <!-- <h3 class="_eg _f3">Concept | Profile</h3> -->
                <p class="_f5 _marginSS about-text">
                  自然をインスピレーションに、インテリアデザイン、スペースデザイン、家具デザイン、アートディレクション、ブランディング、グラフィックデザインを通じた表現を行うクリエイティブチームです。瀬戸内海に浮かぶ離島、大津島を拠点に、日本全国および海外のプロジェクトに参画しています。
                </p>
                <p class="_f5  about-text-eg">
                  JIBI is an innovative company with a diverse creative team, finding inspiration in the unique natural and historical surroundings for each project. Specialising in interior design, furniture design, large spaces, branding, art direction and graphic design, the creative flair of our team can help you find your creative solutions. We are based on a small island in the Setouchi region of Japan, in a place called Ohzushima. We work throughout Japan and abroad.
                </p>
              </div>
            </router-link>
          </div>
        </Show>

        <Show :currentPosition="_currentPosition" :isCompletedLoad="isCompletedLoad">
          <div class="work container">
            <h2 class="title _eg-b _f2">Recent Design Works</h2>
            <div class="_box-container">
              <router-link v-for="work in works" :to="{name: 'Work', params:{id: work.id}}" :key="work.id" class="_box">
                <img :src="work.imageUrl" :alt="work.title" class="_box-image">
                <h3 class="_box-sub-title"><p class="_f3">{{work.title}}</p><span class="_box-sub-title-line"></span><p class=" _f3 _eg ">{{work.mainContent}}</p></h3>
                <p class="_f5">{{work.shortDescription}}</p>
              </router-link>
              <div v-if="works.length%3 === 2" class="_box-dummy"></div>
            </div>
            <p class="work-ex">
              Projects as a senior designer at SIMPLICITY | Interior Design | Hotel the Celestine, Kyoto Japan, 2017/ Bohemian house, Hongkong, 2017 / marumasu, 2016, Tokyo Japan / Aesop Yokohama, Kanagawa Japan, 2016 / Aesop Tokyo, Tokyo Japan, 2016 / NISHIKIYA MARIE MARIE, Tokyo Japan, 2015 / Sai, Oita Japan, 2015 / B-SPEAK, Oita Japan, 2015 / Aesop Tenjin, Fukuoka Japan, 2015 / Aesop Hakata, Fukuoka Japan, 2015 / Aesop Aoyama,Tokyo Japan, 2015 / Sakurai Japanese Tea Experience, Tokyo Japan, 2014 / Andaz Tokyo AO, Tokyo Japan, 2014 / Aesop Shinsaibashi, Osaka Japan, 2014 / Aesop Kyoto, Kyoto Japan, 2013 / Taikoen, Osaka Japan, 2013 / The theo, Oita Japan, 2011
            </p>
            <router-link :to="{name: 'WorkLineup'}" class="more-link">View more</router-link>
            <OtherProjectInput></OtherProjectInput>
          </div>
        </Show>

        <Show :currentPosition="_currentPosition" :isCompletedLoad="isCompletedLoad">
          <div class="contents container">
            <h2 class="title _eg-b _f2">Our Brands</h2>
            <div class="_box-container">
              <router-link :to="{name: 'Hinamori2'}" class="content-box">
                <div class="contents-cover">
                  <img src="@/assets/images/hinamori/top.jpg" alt="" class="_box-image contents-image">
                  <img src="@/assets/images/hinamori/hinamori-logo-white-h.png" alt="" class="contents-cover-image">
                </div>
                <h3 class="_box-bland-sub-title"><p class="_f3">陽森</p><span class="_box-bland-sub-title-line"></span><p class="_f3">ひなもり</p></h3>
                <p class="_f5">自然循環の中で人間が一つの関わりを為すことで生まれるプロダクトの提案を行っています。</p>
              </router-link>
              <router-link :to="{name: 'Mui'}" class="content-box">
                <img src="@/assets/images/mui.jpg" alt="" class="_box-image contents-image">
                <h3 class="_box-bland-sub-title"><p class="_f3 _eg">mui</p><span class="_box-bland-sub-title-line"></span><p class="_f3">むい</p></h3>
                <p class="_f5">自然のままにして作為せず、ありのままを受け入れながら、衝動を重ねたものづくりをしています。</p>
              </router-link>
              <router-link :to="{name: 'Yado'}" class="content-box">
                <img src="@/assets/images/yado.jpg" alt="" class="_box-image contents-image">
                <h3 class="_box-bland-sub-title"><p class="_f3">宿</p><span class="_box-bland-sub-title-line"></span><p class="_f3 _eg">yado</p></h3>
                <p class="_f5">ヒトが自然と共にある事を感じながら、遊びに還る場としての宿づくりを目指します。</p>
              </router-link>
            </div>
          </div>
        </Show>

        <Show :currentPosition="_currentPosition" :isCompletedLoad="isCompletedLoad">
          <div class="journal container">
            <h2 class="title _eg-b _f2">Journal</h2>
            <div class="_box-container">
              <router-link v-for="journal in journals" :to="{name: 'Journal', params:{id: journal.id}}" :key="journal.id" class="_box">
                <img :src="journal.imageUrl" :alt="journal.title" class="_box-image">
                <p class="_box-journal-day _eg">{{journal.month}} {{journal.day}} {{journal.year}}</p>
                <h2 class="_box-journal-title _f3">{{journal.title}}</h2>
                <p class="_box-journal-sub-title">{{journal.subTitle}}</p>
              </router-link>
              <div v-if="journals.length === 2 || journals.length === 5" class="_box-dummy"></div>
            </div>
            <router-link :to="{name: 'JournalLineup'}" class="more-link">View more</router-link>
          </div>
        </Show>
        
        <Footer :pageTitle="pageTitle"></Footer>
      </div>

    </scrollParallax>
  </div>
</template>

<script>
import {db} from '@/firebase/index.js'
import Header from '@/components/Header.vue'
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'
import OtherProjectInput from '@/components/OtherProjectInput.vue'
// import GoTop from '@/components/GoTop.vue'
import scrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue';
import Show from '@/components/Show.vue'
import Mixin from '@/mixin/mixin.js'
import Spinner from '@/components/Spinner.vue'

export default {
  components:{
    Header,
    Navi,
    Footer,
    scrollParallax,
    OtherProjectInput,
    Show,
    Spinner

    // GoTop
  },
  props:{

},
  data(){
    return{
      pageTitle: "Home",
      isOpenNavi: false,

      // それぞれそのデータを取得完了を監視
      isLoadJournal: false,
      isLoadWork: false,
      isCompletedLoad: false, //ここがtrueなら Show 発動

      topImagesId: "RNlgUhqSbVJUGxiCR0qT",
      topImages: null,
      imageA: null,
      imageB: null,
      imageC: null,
      imageD: null,
      imageMark: "a",
      timer: null,
      isLoadTop: true,
      innerHeight: null,

      isSafari: false, //created で safariかどうか判別
      
      journals:[],
      works:[],
      password: "",

  
    }
  },
  mixins:[
    Mixin
  ],
  beforeCreate(){
    
  },
  async created(){
    this.innerHeight =  window.innerHeight

    // トップがランダム
    await db.collection('topImages').doc(this.topImagesId).get()
    .then(doc=>{
      this.topImages = doc.data()
      const randomNumber = Math.floor(Math.random()*4)
      if(randomNumber === 0){
        this.imageA = this.topImages.imageObjects[0].url
        this.imageB = this.topImages.imageObjects[1].url
        this.imageC = this.topImages.imageObjects[2].url
        this.imageD = this.topImages.imageObjects[3].url
      }else if(randomNumber === 1){
        this.imageA = this.topImages.imageObjects[1].url
        this.imageB = this.topImages.imageObjects[3].url
        this.imageC = this.topImages.imageObjects[2].url
        this.imageD = this.topImages.imageObjects[0].url
      }else if(randomNumber === 3){
        this.imageA = this.topImages.imageObjects[2].url
        this.imageB = this.topImages.imageObjects[0].url
        this.imageC = this.topImages.imageObjects[3].url
        this.imageD = this.topImages.imageObjects[1].url
      }else{
        this.imageA = this.topImages.imageObjects[3].url
        this.imageB = this.topImages.imageObjects[2].url
        this.imageC = this.topImages.imageObjects[0].url
        this.imageD = this.topImages.imageObjects[1].url
      }
      
      
    })
    await db.collection('journal').where('isPublic', '==', true).orderBy('order', 'desc').limit(6).get()
    .then(querySnapShot=>{
      querySnapShot.forEach(doc=>{
        this.journals.push(doc.data())
      
      })
      this.journals.forEach(journal=>{
        if(journal.style === 'plural'){
          journal.imageObjects.forEach(imageObject=>{
            if(imageObject.order === 0){
              journal.imageUrl = imageObject.url
            }
          })
        }
      })
      this.isLoadJournal = true
    })

    



    let worksBox = []
    await db.collection('work').where('isPublic', '==', true).orderBy('completion', 'desc').get()
    .then((querySnapShot)=>{
      querySnapShot.forEach((doc)=>{
        worksBox.push(doc.data())
      })
      worksBox.sort((a, b)=>{
        if(a.completion < b.completion){return 1}
        if(a.completion > b.completion){return -1}
        return 0
      })
      this.works = worksBox.splice(0, 9)
      this.works.forEach((work)=>{
        work.imageObjects.forEach(imageObject=>{
          if(imageObject.order === 0){
            work.imageUrl = imageObject.url
          }
        })
      })
      this.isLoadWork = true
    })



    // ブラウザチェック safariならトップが自動移り変わりをさせない
    var userAgent = window.navigator.userAgent.toLowerCase();
    if(userAgent.indexOf('msie') != -1 ) {
        this.isSafari = false
    } else if(userAgent.indexOf('edge') != -1) {
        this.isSafari = false
    } else if(userAgent.indexOf('chrome') != -1) {
        this.isSafari = false
    } else if(userAgent.indexOf('safari') != -1) {
        console.log('ブラウザはSafariです')
        this.isSafari = true
    } else if(userAgent.indexOf('firefox') != -1) {
        this.isSafari = false
    }else{
      this.isSafari = false
    }
    if(!this.isSafari){
      this.timer = setTimeout(this.setImage, 5000)
    }
    // this.timer = setTimeout(this.setImage, 5000)
    setTimeout(()=>{
      this.isLoadTop = false
    }, 500)
    
  },
  mounted(){

  },
  beforeDestory(){
  },
  methods:{
    // ナビ用
    openNavi(){
      this.isOpenNavi = true
      this._stop(true)
    },
    closeNavi(){
      this.isOpenNavi = false
      this._stop(false)
    },
    imageChange(mark){
      clearTimeout(this.timer)
      this.imageMark = mark
    },
    setImage(){
      if(this.imageMark === "a"){
        this.imageMark = "b"
      }else if(this.imageMark === "b"){
        this.imageMark = "c"
      }else if(this.imageMark === "c"){
        this.imageMark = "d"
      }else if(this.imageMark === "d"){
        this.imageMark = "a"
      }
    },



  },
  watch: {
    imageMark:{
      handler: function(){
        if(!this.isSafari){
          clearTimeout(this.timer)
          this.timer = setTimeout(this.setImage, 5000)
        }
      },
    },
    isLoadJournal:{
      handler: function(){
        if(this.isLoadJournal && this.isLoadWork){
          this.isCompletedLoad = true
        }
      },
    },
    isLoadWork:{
      handler: function(){
        if(this.isLoadJournal && this.isLoadWork){
          this.isCompletedLoad = true
        }
      },
    },
    

  },
  computed:{

  },

}
</script>

<style scoped>
.base{
  background-color: var(--bg);
}

.container{
  margin: 0 auto;
  width: 90%;
  max-width: 1400px;
}
.title{
  color: var(--white);
  text-align: center;
  margin-bottom: 40px;
}


.top-images{
  width: 100vw;
  height: 100vh;
  height: 100svh;
}
.top-image-container{
  width: 100%;
  height: 100%;
  background-color: var(--black);
  position: relative;
}
.top-image{
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: 2s;
}
.top-image-safari{
  transition: 0s;
}
.top-image-active{
  opacity: 1;
}
.image-switch-container{
  position: absolute;
  bottom: 100px;
  right: 0;
  left: 0;
  width: 100%;
  height: 10px;
  z-index: 20;
}
.image-switch{
  display: flex;
  justify-content: space-between;
  width: 160px;
  height: 1px;
  margin: 0 60px 0 auto;
}
.switch{
  width: 22%;
  height: 40px;
}
.switch-line{
  height: 1px;
  background-color: var(--white);
  -webkit-transform: translate(0, 20px);
  transform: translate(0, 20px);
}
.switch-active{
  background-color: #808080;
}

.more-link{
  display: block;
  margin: 0 auto 60px;
  width: 200px;
  text-align: center;
  text-decoration: underline;
  font-size: 1.4rem;
  color: var(--black);
  transition: 0.6s;
}
.more-link:hover{
  color: var(--white);
}
.content{
  background-color: var(--bg);
  width: 100%;
  height: auto;

}
.message{

}
.message-jp{
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  width: 490px;
  margin: 0 auto 120px;
  padding-top: 120px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.message-jp p{
  letter-spacing: 3px;
  line-height: 40px;
}
.message-last{
  display: flex;
}
.message-line{
  display: block;
  height: 50px;
  width: 0.5px;
  background-color: var(--black);
  -webkit-transform: translate(-21px, 2px);
  transform: translate(-21px, 2px);
}
.message-eg{
  text-align: center;
  margin:  0 0 140px;
}
.message-eg p{
  line-height: 40px;
}

.about{
  display: flex;
  justify-content: space-between;
  margin-bottom: 180px;
}
.SP-about-title{
  display: none;
}
.about-left{
  width: 60%;
  
}
.about-left img{
  object-fit: cover;
  width: 100%;
  height: 380px;
}
.about-right{
  width: calc(40% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.about-title{
  margin: 0 0 20px;
  color: var(--white);
}
.about-content h3{
  margin-bottom: 10px;
}
.about-text-eg{
  opacity: 0.8;
  line-height: 1.8;
  text-align: left;
}

.work{
  margin-bottom: 180px;
}
.work-ex{
  font-size: 1.3rem;
  margin: 0 auto 60px;
  text-align: justify;
  width: 100%;
  max-width: 800px;
}


.contents{
  margin-bottom: 180px;
}
.content-box{
  width: 30%;
}
.contents-image{
  height: 440px;
}
.contents-cover{
  position: relative;
}
.contents-cover-image{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 30%;

}

.journal{
  margin-bottom: 60px;
}


@media screen and (max-width:599px){
  .top-images{
    height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
    height: fill-available;
  }
  .image-switch{
    width: 100px;
    height: 1px;
    bottom: 40px;
    right: 40px;
  }
  .image-switch-container{
    bottom: 50px;
  }
  .image-switch{
    margin: 0 5% 0 auto;
  }
  .message-jp{
    width: 80%;
    margin: 80px auto;
  }
  .message-jp p{
    font-size: 1.3rem;
    line-height: 25px;
  }
  .message-line{
    -webkit-transform: translate(-13px, 2px);
    transform: translate(-13px, 2px);
  }
  .message-eg{
    width: 80%;
    text-align: center;
    margin:  0 auto 100px;
  }
  .message-eg p{
    line-height: 30px;
  }
  .about{
    flex-wrap: wrap;
    margin-bottom: 100px;
  }
  .SP-about-title{
    display: block;
    margin: 0 auto 40px;
  }
  .about-left{
    width: 100%;
  }
  .about-left img{
    height: 240px;
    margin-bottom: 10px;
  }
  .about-right{
    width: 100%;
    display: block;
  }
  .about-title{
    display: none;
  }
  .about-content h3{
    margin-bottom: 10px;
  }

  .work{
    margin-bottom: 100px;
  }
  .work-other{
    width: 60%;
  }

  .contents{
    margin-bottom: 40px;
  }
  .content-box{
    width: 100%;
    margin-bottom: 60px;
  }
  .contents-image{
    height: 200px;
  }

  .journal{
    margin-bottom: 0px;
  }
  .journal-box{
    width: 100%;
    height: 160px;
  }
  .journal-box-dummy{
    display: none;
  }


}
@media screen and (min-width:600px) and (max-width:1100px){
  .image-switch{
    margin: 0 5% 0 auto;
  }
  .about{
    flex-wrap: wrap;
    margin-bottom: 100px;
  }
  .SP-about-title{
    display: block;
    margin: 0 auto 40px;
  }
  .about-left{
    width: 100%;
  }
  .about-left img{
    height: 240px;
    margin-bottom: 10px;
  }
  .about-right{
    width: 100%;
    display: block;
  }
  .about-title{
    display: none;
  }
  .about-content h3{
    margin-bottom: 10px;
  }
}

/* タブレット */
@media screen and (min-width:600px) and (max-width:860px){
  .image-switch{
    margin: 0 5% 0 auto;
  }
  .journal-box{
    width: 47%;
    height: 160px;
  }

}
</style>