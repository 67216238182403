<template>
  <div class="_base">

    <!-- <div v-if="cover" class="cover">
      <div class="password">
        <input type="text" v-model="password" placeholder="password">
        <button @click="go()" >Go</button>
      </div>
    </div> -->

    <Header :isOpen="isOpenNavi" :pageTitle="pageTitle" @open="openNavi()" :currentPosition="_currentPosition"></Header>
    <Navi :isOpen="isOpenNavi" @close="closeNavi"></Navi>
    <!-- <GoTop></GoTop> -->
    <div class="top" :style="{ 'background-image': 'url(' + topImage +')', 'height': topHeight + 'px'  }">
      <div class="top-top" :style="{'height': _innerHeight  + 'px'}">
        <img src="@/assets/images/hinamori/hinamori-logo-white.png" class="top-logo">
        <a href="https://hinamori.official.ec/" target="_blank" rel="noopener noreferrer" class="top-shop _eg">ONLINE STORE</a>
      </div>
      
      <div class="top-container">
        <div class="_left">
          <div class="_left-content">
            <h2 class="_left-title bland-left-title _eg-b">Concept</h2>
          </div>
        </div>
        <div class="_right">
          <div class="_right-content bland-content">
            <Show :currentPosition="_currentPosition" :isCompletedLoad="isCompletedLoad">
              <p class="bland-title">この星の息づかい</p>
            </Show>
            <Show :currentPosition="_currentPosition" :isCompletedLoad="isCompletedLoad">
              <div class="bland-info">
                <p class="bland-text">
                  この星の、<br>
                  この自然の、<br>
                  この人間の、<br>
                  息づく姿を、<br>
                  幾重に重ねるように、<br>
                  心の記憶に、<br>
                  そっと微笑む。<br><br>
                  それはきっと、愛おしさそのもの。
                </p>
              </div>
            </Show>
          </div>
        </div>
      </div>
    </div>
    <div  class="content" >

      <Show :currentPosition="_currentPosition" :isCompletedLoad="isCompletedLoad">
      <div class="message">
        <img src="@/assets/images/hinamori/message-mori.jpg" class="message-left">
        <div class="message-right">
          <div class="message-content">
            <div class="message-title">
              <h1>陽森</h1>
              <p>ひなもり</p>
            </div>
            <div class="message-box">
              <p class="message-text">
                陽は夜の向こうに隠されている。<br>
                古人はそう感じ取り、「ひな」という言葉に、<br>
                「隠されている愛らしいもの」<br>
                という意味を込めた。
              </p>
              <p class="message-text">
                夜が明け、陽光が差し込む時、<br>
                生きとし生けるものは、<br>
                生命のあたたかさを感じ、自然を育む。<br>
                そうして陽は巡り、森や海を豊かにする。<br>
                愛おしい想いを込めて
              </p>
            </div>
          </div>
        </div>
      </div>
      </Show>
      <Show :currentPosition="_currentPosition" :isCompletedLoad="isCompletedLoad">
      <div class="collection">
        <h2 class="title _eg-b _f2">Collection</h2>
        <div class="_box-container" :class="{'_box-container-one': collections.length === 1}">
          <router-link v-for="collection in collections" :key="collection.id" :to="{name: 'Collection', params:{id: collection.id}}" class="collection-box">
            <img v-if="collection.topImageObjects.length > 0" :src="collection.topImageObjects.find(image=>image.order === 0).url" alt="" class="_box-image contents-image">
            <div v-else class="_box-image contents-image"></div>
            <h3 class="_box-sub-title"><p class="_f3">{{ collection.title }}</p></h3>
            <p class="_f5">{{ collection.shotText }}</p>
          </router-link>
          <div v-if="collections.length%3 === 2" class="_box-dummy"></div>
        </div>
      </div>
      </Show>
      <Show :currentPosition="_currentPosition" :isCompletedLoad="isCompletedLoad">
      <div class="contents">
        <h2 class="title _eg-b _f2">Experiment</h2>
        <div class="_box-container" :class="{'_box-container-one': experiments.length === 1}">
          <router-link v-for="experiment in experiments" :key="experiment.id" :to="{name: 'Experiment', params:{id: experiment.id}}" class="_box">
            <img v-if="experiment.topImageObjects.length > 0" :src="experiment.topImageObjects.find(image=>image.order === 0).url" alt="" class="_box-image">
            <div v-else class="_box-image"></div>
            <h3 class="_box-sub-title"><p class="_f3">{{ experiment.title }}</p></h3>
            <p class="_f5">{{ experiment.shotText }}</p>
          </router-link>
          <div v-if="experiments.length%3 === 2" class="_box-dummy"></div>
        </div>
      </div>
      <!-- <div class="contents">
        <h2 class="title _eg-b _f2">Experiment</h2>
        <div class="_box-container">
          <router-link v-for="element in elements" :to="{name: 'Element', params:{id: element.id}}" :key="element.id" class="_box">
            <img :src="element.imageObjects[0].url" :alt="element.title" class="_box-image">
            <h3 class="_box-sub-title"><p class="_f3">{{element.title}}</p></h3>
            <p class="_f5">{{element.firstText}}</p>
          </router-link>
          <div v-if="elements.length%3 === 2" class="_box-dummy"></div>
        </div>
      </div> -->
      </Show>
      <Show :currentPosition="_currentPosition" :isCompletedLoad="isCompletedLoad">
      <div class="scenery">
        <div class="scenery-right">
          <p class="scenery-title _eg-b _f2">Scenery</p> 
        </div>
        <div class="scenery-left"><iframe src="https://player.vimeo.com/video/691838098?h=496e71f71a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Scenery.mov"></iframe></div>
      </div>

      </Show>


    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import {db} from '@/firebase/index.js'
import Header from '@/components/Header.vue'
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'
import Show from '@/components/Show.vue'
import Mixin from '@/mixin/mixin.js'

import Player from "@vimeo/player";

export default {
  components:{
    Header,
    Navi,
    Footer,
    Show,
    // GoTop
  },
  props:{

},
  data(){
    return{
      pageTitle: "NoTitle",
      isOpenNavi: false,

      topImage: require('@/assets/images/hinamori/top.jpg'),
      topHeight: 0,
      elements: [],
      collections: [],
      experiments: [],
      isCompletedLoad: false,//ここがtrueなら Show 発動

      // 仮パス用
      cover: true,
      password: null, //illustrious
    }
  },
  mixins:[
    Mixin
  ],
  beforeCreate(){
    
  },
  created(){
    db.collection('element').where('isPublic', '==', true).get()
    .then(querySnapShot=>{
      querySnapShot.forEach(doc=>{
        if(doc.data().type === 'collection'){
          this.collections.push(doc.data())
        }
        if(doc.data().type === 'experiment'){
          this.experiments.push(doc.data())
        }
        this.setOrder(this.collections)
        this.setOrder(this.experiments)

      })
      this.isCompletedLoad = true
    })
    this.topHeight = Number(this._innerHeight) + 600
  },
  mounted(){
    var iframe = document.querySelector("iframe");
    var player = new Player(iframe);
    player.on("play", function() {
        console.log("Played the video");
    });

    player.on("ended", function() {
        console.log("Ended the video");
    });

    player.getVideoTitle().then(function(title) {
        console.log("title:", title);
    });
  },
  beforeDestory(){
    
  },
  methods:{
    // ナビ用
    openNavi(){
      this.isOpenNavi = true
      this._stop(true)
    },
    closeNavi(){
      this.isOpenNavi = false
      this._stop(false)
    },
    setOrder(element){
      if(element.length > 0){
        element.sort((a,b)=>{
          if(a.order < b.order){return 1}
          if(a.order > b.order){return -1}
          return 0
        })
      }
    },
    
    // 仮パス用
    go(){
      if(this.password === "illustrious"){
        this.cover = false
      }
    }

  },
  watch: {
    _innerHeight:{
      immediate: true,
      handler: function(){
        this.topHeight = this._innerHeight + 600
      }
      
    }
  },
  computed:{
    // topHeight:function(){
    //     return this._innerHeight + 200
    // }
  },

}
</script>

<style scoped>
.cover{
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--bg);
}
.password{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.content{
  width: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
}
.container{
  width: 100%;
  display: flex;
  padding-bottom: 120px;
}
.container-s{
  margin: 0 auto;
  width: 90%;
  max-width: 1400px;
}
.title{
  color: var(--white);
  text-align: center;
  margin-bottom: 40px;
}

.top{
  width: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
}
.top-top{
  position: relative;
  top: 0;
  left: 0;
}
.top-logo{
  position: absolute;
  top:50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 70px;
}
.top-shop{
  position: absolute;
  left: 60px;
  bottom: 50px;
  display: block;
  padding: 5px;
  width: 140px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: 0.6s;
  text-align: center;
}
.top-shop:hover{
  background-color: rgba(255, 255, 255, 1);
}

.top-container{
  width: 100%;
  max-width: 1400px;
  display: flex;
  position: relative;
  left: 50%;
  top: 80px;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.bland-left-title{
  color: var(--white);
}
.bland-content{
  -webkit-transform: translate(0, -5px);
  transform: translate(0, -5px);
}
.bland-title{
  font-size: 2.2rem;
  font-weight: 700;
  color: var(--white);
  margin-bottom: 30px;
  letter-spacing: 3px;
}
.bland-info{
  margin-left: 100px;
}
.bland-text{
  color: var(--white);
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 1px;
  margin-bottom: 50px;
}


.message{
  display: flex;
  height: 450px;
  margin: 0 auto;
  width: 90%;
  max-width: 1400px;
  padding-bottom: 180px;
}
.message-left{
  width: 60%;
  object-fit: cover;
}
.message-right{
  width: 40%;
  position: relative;
}
.message-content{
  position: relative;
}
.message-title{
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  height: 200px;
  width: 40px;
  position: absolute;
  top: 30px;
  right: 10px;
}
.message-title h1{
  color: var(--black);
  font-size: 2.2rem;
  letter-spacing: 2px;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.message-title p{
  color: var(--black);
  font-size: 1.4rem;
  letter-spacing: 1px;
  position: absolute;
  top: 70px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.message-box{
  position: absolute;
  top: 60px;
  right: 70px;
  display: flex;
  flex-direction: row-reverse;
}
.message-text{
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  font-size: 1.4rem;
  letter-spacing: 1px;
  margin-left: 14px;
}

.collection{
  width: 90%;
  display: block;
  margin: 0 auto 100px;
  max-width: 1400px;
}
.collection-box{
  display: block;
  width: 30%;
  margin-bottom: 80px;
}


.contents{
  width: 90%;
  display: block;
  margin: 0 auto 80px;
  max-width: 1400px;
}
.content-box{
  width: 30%;
}
.contents-image{
  height: 440px;
}

.scenery{
  display: flex;
  width: 90%;
  margin: 0 auto 140px;
  max-width: 1400px;
  height: 500px;
}
.scenery-right{
  width: 35%;
  position: relative;
}
.scenery-title{
  color: var(--white);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.scenery-left{
  width: 65%;
  /* padding:56.25% 0 0 0; */
  position:relative;
}
.scenery-left iframe{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}



@media screen and (max-width:599px){

  .top-logo{
    width: 50px;
  }
  .top-shop{
    left: 20px;
    bottom: 20px;
    width: 100px;
  }
  .top-container{
    width: 90%;
    display: block;
  }
  .container{
    width: 90%;
    display: block;
    padding-bottom: 50px;
    margin: 0 auto;
  }
  .bland-info{
    margin-left: 0;
  }

  .content{
    width: 100%;
    padding-top: 0px;
    padding-bottom: 60px;
  }

  .message{
    flex-direction: column;
    width: 100%;
    height: auto;
    padding-bottom: 50px;
  }
  .message-left{
    width: 100%;
    height: 330px;
    margin-bottom: 20px;
    
  }
  .message-right{
    width: 100%;
    height: 400px;
  }
  .message-content{
    width: 300px;
    margin: 0 auto;
  }
  .message-title{
    right: 0;
  }

  .collection-box{
    width: 100%;
    margin-bottom: 60px;
  }

  .contents{
    margin-bottom: 40px;
  }
  .content-box{
    width: 100%;
    margin-bottom: 60px;
  }
  .contents-image{
    height: 200px;
  }
  .scenery{
    display: block;
    width: 90%;
    height: auto;
    margin: 0 auto 120px;

  }
  .scenery-right{
    width: 100%;
    position:static;
  }
  .scenery-title{
    position: static;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    top:auto;
    left: auto;
    text-align: center;
    margin-bottom: 40px;
  }
  .scenery-left{
    width: 100%;
    padding:56.25% 0 0 0;
  }
  .scenery-left iframe{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    aspect-ratio: 16/9;
  }
}


/* タブレット */
@media screen and (min-width:600px) and (max-width:860px){
  .collection-box{
    width: 47%;
  }
}


@media screen and (min-width:600px) and (max-width:729px){
  .message{
    flex-direction: column;
    width: 100%;
    height: auto;
  }
  .message-left{
    width: 100%;
    height: 330px;
    margin-bottom: 20px;
    
  }
  .message-right{
    width: 100%;
    height: 400px;
  }
  .message-content{
    width: 300px;
    margin: 0 auto;
  }
  .message-title{
    right: 0;
  }

}

/* タブレット */
@media screen and (min-width:730px) and (max-width:960px){
  .message-left{
    width: 50%;
  }
  .message-right{
    width: 50%;
  }

}
</style>