<template>
  <div class="_base">

    <!-- <div v-if="cover" class="cover">
      <div class="password">
        <input type="text" v-model="password" placeholder="password">
        <button @click="go()" >Go</button>
      </div>
    </div> -->

    <Header :isOpen="isOpenNavi" :pageTitle="pageTitle" @open="openNavi()" :currentPosition="_currentPosition"></Header>
    <Navi :isOpen="isOpenNavi" @close="closeNavi"></Navi>
    <!-- <GoTop></GoTop> -->
    <div class="top" :style="{ 'background-image': 'url(' + topImage +')'}">
      <div class="top-title">
        <h1>陽森</h1>
        <span></span>
        <p>ひなもり</p>
      </div>
    </div>
    <div  class="content" >
      <div class="container">
        <div class="_left">
          <div class="_left-content">
            <h2 class="_left-title bland-left-title _eg-b">Concept</h2>
          </div>
        </div>
        <div class="_right">
          <div class="_right-content bland-content">
            <p class="bland-title">この星の息づかい</p>
            <div class="bland-info">
              <p class="bland-text">
                この星の、<br>
                この自然の、<br>
                この人間の、<br>
                息づく姿を、<br>
                幾重に重ねるように、<br>
                心の記憶に、<br>
                そっと微笑む。<br><br>
                それはきっと、愛おしさそのもの。
              </p>
            </div>
          </div>
        </div>
      </div>
      
      <div class="message">
        <img src="@/assets/images/hinamori/mori.jpg" class="message-left">
        <div class="message-right">
          <div class="message-content">
            <div class="message-title">
              <h1>陽森</h1>
              <p>ひなもり</p>
            </div>
            <div class="message-box">
              <p class="message-text">
                陽は夜の向こうに隠されている。<br>
                古人はそう感じ取り、「ひな」という言葉に、<br>
                「隠されている愛らしいもの」<br>
                という意味を込めた。
              </p>
              <p class="message-text">
                夜が明け、陽光が差し込む時、<br>
                生きとし生けるものは、<br>
                生命のあたたかさを感じ、自然を育む。<br>
                そうして陽は巡り、森や海を豊かにする。<br>
                愛おしい想いを込めて
              </p>
            </div>
          </div>
        </div>
      </div>
     
      <div class="contents">
        <h2 class="title _eg-b _f2">Brand Keyword</h2>
        <div class="_box-container">
          <div class="content-box">
            <img src="@/assets/images/hinamori/hi.jpg" alt="" class="_box-image contents-image">
            <h3 class="_box-sub-title"><p class="_f3">或る記憶</p></h3>
            <p class="_f5">記憶の中に感じる、言わず知らずの感覚。心の動きを丁寧に感じ取り、誠実でいることを讃える為の手段。身体の中を流れる何かを捉えられるような、その風景や移りゆく時を大切にする事で、この星と人の繋がりは保たれるのではないだろうか。</p>
          </div>
          <div class="content-box">
            <img src="@/assets/images/hinamori/te.jpg" alt="" class="_box-image contents-image">
            <h3 class="_box-sub-title"><p class="_f3">手の学び</p></h3>
            <p class="_f5">手はよく学び、意志を持って動く。人の歴史と共に成長し、発展した人間の最も根源的行為そのものである。様々な手仕事によって、手の活動範囲を広げる道具や生活用具、美しい飾りなどが生まれる。</p>
          </div>
          <div class="content-box">
            <img src="@/assets/images/hinamori/ishi.jpg" alt="" class="_box-image contents-image">
            <h3 class="_box-sub-title"><p class="_f3">生命の姿</p></h3>
            <p class="_f5">悠遠なる水の流れ、草木土、はつかなる陽の光。そうしたたった一つしかない、自然の息づく愛おしい姿に寄り添い、あまねく伝える。</p>
          </div>
        </div>
      </div>

      <img src="@/assets/images/hinamori/tsuki.jpg" class="tsuki">

      <div class="container">
        <div class="_left">
          <div class="_left-content">
          </div>
        </div>
        <div class="_right">
          <div class="_right-content">
            <p class="shio-title">自然の循環から生まれる塩</p>
            <p class="_text">
              森の地下を通り、海底に湧き出る湧水。塩作りの原料となるこの湧水は、淡水ではなく、ミネラルや旨味を豊富に含む塩水です。通常、塩を作る際には、このミネラルの内の一つ、マグネシウムをにがりとして取り除く工程が含まれますが、我々の塩からは、にがりを取り除いていません。マグネシウムの含有量が低い訳ではなく、取り除かなくても、できあがる塩が苦くないのです。海水のミネラルバランスは、人間の血液のそれと近いと言われており、そのバランスを保ったままの塩を、皆様のお手元にお届けしています。<br><br>
              私たちはこの星の息づかいから生まれてくるもの、つまりは自然の循環の中で 人間がもつ役割を丁寧に紡ぎ直してゆこうと考えています。<br><br><br>
              ※この商品は薪焚きにこだわっております。炭が小さな粒となって入ることが ございますが、食べても問題ありません。商品についてご不明点等がございま したら、メールにてご連絡ください。
            </p>
          </div>
        </div>
      </div>

      <div class="news">
        <h2 class="title _eg-b _f2">News</h2>
        <div class="_box-container">
          <div class="_box">
            <img src="@/assets/images/hinamori/shio.jpg" alt="" class="_box-image">
            <h3 class="_box-sub-title _f3">夏の塩 予約販売を行います</h3>
            <p class="news-day">2022.03.05</p>
            <p class="_f5">4/1 から夏の塩の予約販売を行います。</p>
          </div>
          <div class="_box">
            <img src="@/assets/images/hinamori.jpg" alt="" class="_box-image">
            <h3 class="_box-sub-title _f3">EC サイトオープン</h3>
            <p class="news-day">2022.02.28</p>
            <p class="_f5">陽森の EC サイトをオープンしました。</p>
          </div>
          <div class="_box">
            <img src="@/assets/images/hinamori_main.jpg" alt="" class="_box-image">
            <h3 class="_box-sub-title _f3">WEB サイトオープン</h3>
            <p class="news-day">2022.02.25</p>
            <p class="_f5">陽森の WEB サイトをオープンしました。</p>
          </div>
          <!-- <div v-if="works.length%3 === 2" class="_box-dummy"></div> -->
        </div>
      </div>
    </div>
    <FooterHinamori></FooterHinamori>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Navi from '@/components/Navi.vue'
import FooterHinamori from '@/components/FooterHinamori.vue'
import Mixin from '@/mixin/mixin.js'

export default {
  components:{
    Header,
    Navi,
    FooterHinamori,
    // GoTop
  },
  props:{

},
  data(){
    return{
      pageTitle: "NoTitle",
      isOpenNavi: false,

      topImage: require('@/assets/images/hinamori_main.jpg'),

      // 仮パス用
      cover: true,
      password: null, //illustrious
    }
  },
  mixins:[
    Mixin
  ],
  beforeCreate(){
    
  },
  created(){
    
    
  },
  mounted(){

  },
  beforeDestory(){
    
  },
  methods:{
    // ナビ用
    openNavi(){
      this.isOpenNavi = true
      this._stop(true)
    },
    closeNavi(){
      this.isOpenNavi = false
      this._stop(false)
    },
    
    // 仮パス用
    go(){
      if(this.password === "illustrious"){
        this.cover = false
      }
    }

  },
  watch: {

  },
  computed:{

  },

}
</script>

<style scoped>
.cover{
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--bg);
}
.password{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.content{
  width: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
}
.container{
  width: 100%;
  display: flex;
  padding-bottom: 120px;
}
.container-s{
  margin: 0 auto;
  width: 90%;
  max-width: 1400px;
}
.title{
  color: var(--white);
  text-align: center;
  margin-bottom: 40px;
}

.top{
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  position: relative;
}
.top-title{
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  height: 200px;
  width: 40px;
  position: relative;
  top: 80px;
  left: 50px;
}
.top-title h1{
  color: var(--white);
  font-size: 2.8rem;
  letter-spacing: 10px;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.top-title span{
  display: block;
  width: 1px;
  height: 20px;
  background-color: var(--white);
  position: absolute;
  top: 76px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.top-title p{
  color: var(--white);
  letter-spacing: 4px;
  position: absolute;
  top: 106px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}


.bland-left-title{
  color: var(--white);
}
.bland-content{
  -webkit-transform: translate(0, -5px);
  transform: translate(0, -5px);
}
.bland-title{
  font-size: 2.2rem;
  font-weight: 700;
  color: var(--black);
  margin-bottom: 30px;
  letter-spacing: 3px;
}
.bland-info{
  margin-left: 100px;
}
.bland-text{
  color: var(--black);
  line-height: 36px;
  letter-spacing: 1px;
  margin-bottom: 50px;
}

.message{
  display: flex;
  height: 450px;
  margin: 0 auto;
  width: 90%;
  max-width: 1400px;
  padding-bottom: 180px;
}
.message-left{
  width: 60%;
  object-fit: cover;
}
.message-right{
  width: 40%;
  position: relative;
}
.message-content{
  position: relative;
}
.message-title{
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  height: 200px;
  width: 40px;
  position: absolute;
  top: 30px;
  right: 10px;
}
.message-title h1{
  color: var(--black);
  font-size: 2.2rem;
  letter-spacing: 2px;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.message-title p{
  color: var(--black);
  font-size: 1.4rem;
  letter-spacing: 1px;
  position: absolute;
  top: 70px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.message-box{
  position: absolute;
  top: 60px;
  right: 70px;
  display: flex;
  flex-direction: row-reverse;
}
.message-text{
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  font-size: 1.4rem;
  margin-left: 14px;
}

.contents{
  width: 90%;
  display: block;
  margin: 0 auto;
  margin-bottom: 180px;
}
.content-box{
  width: 30%;
}
.contents-image{
  height: 440px;
  /* shareのフィルターを打ち消している */
  filter: grayscale(0);
}

.tsuki{
  width: 100%;
  height: 600px;
  object-fit: cover;
  padding-bottom: 80px;
}
.shio-title{
  font-size: 2.2rem;
  font-weight: 700;
  color: var(--black);
  margin-bottom: 40px;
  letter-spacing: 3px;
}

.news{
  margin: 0 auto;
  width: 90%;
  max-width: 1400px;
  padding:60px 0 80px;
}
.news-day{
  font-size: 1.2rem;
  margin: 20px 0 0;
}
@media screen and (max-width:599px){

  .top-title{
    top: 26px;
    left: 10px;
  }
  .top-title h1{
    top: 0;
  }
  .top-title span{
    top: 70px;
  }
  .top-title p{
    top: 100px;
  }

  .container{
    width: 90%;
    display: block;
    padding-bottom: 50px;
    margin: 0 auto;
  }
  .bland-info{
    margin-left: 0;
  }
  .message{
    flex-direction: column;
    width: 100%;
    height: auto;
    padding-bottom: 50px;
  }
  .message-left{
    width: 100%;
    height: 330px;
    margin-bottom: 20px;
    
  }
  .message-right{
    width: 100%;
    height: 400px;
  }
  .message-content{
    width: 300px;
    margin: 0 auto;
  }
  .message-title{
    right: 0;
  }

  .contents{
    margin-bottom: 50px;
  }
  .content-box{
    width: 100%;
    margin-bottom: 60px;
  }
  .contents-image{
    height: 200px;
  }

}

@media screen and (min-width:600px) and (max-width:729px){
  .message{
    flex-direction: column;
    width: 100%;
    height: auto;
  }
  .message-left{
    width: 100%;
    height: 330px;
    margin-bottom: 20px;
    
  }
  .message-right{
    width: 100%;
    height: 400px;
  }
  .message-content{
    width: 300px;
    margin: 0 auto;
  }
  .message-title{
    right: 0;
  }
}

/* タブレット */
@media screen and (min-width:730px) and (max-width:960px){
  .message-left{
    width: 50%;
  }
  .message-right{
    width: 50%;
  }
}
</style>