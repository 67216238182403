<template>
  <Spinner v-if="isButtonLock" :isBackground="true"></Spinner>
  <div class="m_base">
    <p class="m_title">New Journal</p>
    <p class="m_input-label _f4 _eg">Style</p>
    <div class="m_tag">
      <div class="m_tag-box">
        <input type="radio" id="normal" :value="'normal'" v-model="journal.style">
        <label for="normal" class="m_tag-label"><span v-if="journal.style === 'normal'" class="m_tag-active"></span></label>
        <label for="normal" class="m_tag-text _eg">Normal</label>
      </div>
      <div class="m_tag-box">
        <input type="radio" id="plural" :value="'plural'" v-model="journal.style">
        <label for="plural" class="m_tag-label"><span v-if="journal.style === 'plural'" class="m_tag-active"></span></label>
        <label for="plural" class="m_tag-text _eg">Plural</label>
      </div>
      <div class="m_tag-box">
        <input type="radio" id="haiku" :value="'haiku'" v-model="journal.style">
        <label for="haiku" class="m_tag-label"><span v-if="journal.style === 'haiku'" class="m_tag-active"></span></label>
        <label for="haiku" class="m_tag-text _eg">Haiku</label>
      </div>
      <div class="m_tag-box">
        <input type="radio" id="scroll" :value="'scroll'" v-model="journal.style">
        <label for="scroll" class="m_tag-label"><span v-if="journal.style === 'scroll'" class="m_tag-active"></span></label>
        <label for="scroll" class="m_tag-text _eg">Scroll</label>
      </div>
    </div>
    <div v-if="journal.style === 'haiku'" class="m_input-box m_box">
      <label for="color" class="m_input-label _f4 _eg">Color</label>
      <input type="color" list id="color" v-model="journal.color">
    </div>
    <div class="m_input-box m_box">
      <label for="title" class="m_input-label _f4 _eg">Title</label>
      <input id="title" v-model="journal.title" placeholder="" class="m_input _f4 _eg">
    </div>
    <div class="m_input-box m_box">
      <label for="subTitle" class="m_input-label _f4 _eg">Subtitle</label>
      <input id="subTitle" v-model="journal.subTitle" placeholder="" class="m_input _f4 _eg">
    </div>
    <div class="m_input-box m_box">
      <label for="subTitle" class="m_input-label _f4 _eg">Date</label>
      <div class="m_select-container">
        <select v-model="exportPreparation.date.year"  class="m_select-2" >
          <option :value="null" disabled >年</option>
          <option v-for="n in 2" :key="n" :value="n + currentYear - 1" :style="{'color': '#131313'}" >{{n + currentYear - 1}}年</option>
        </select>
        <select v-model="exportPreparation.date.month" class="m_select-2" >
          <option :value="null" disabled >月</option>
          <option v-for="n in 12" :key="n" :value="n" :style="{'color': '#131313'}" >{{n}}月</option>
        </select>
        <select v-model="exportPreparation.date.day"  class="m_select-2" >
          <option :value="null" disabled >日</option>
          <option v-for="n in daysMax" :key="n" :value="n" :style="{'color': '#131313'}" >{{n}}日</option>
        </select>
      </div>
    </div>
    <div class="m_input-box m_box">
      <label for="text" class="m_input-label _f4 _eg">Text</label>
      <textarea id="text" v-model="journal.text" placeholder="" class="m_textarea"></textarea>
    </div>
    <div class="m_input-box m_box">
      <label for="link" class="m_input-label _f4 _eg">Link</label>
      <input id="link" v-model="journal.link" placeholder="" class="m_input _f4 _eg">
    </div>
    <div v-if="journal.style !== 'plural'" class="m_input-box">
      <input type="file" ref="preview" @change="setImage($event)" accept="image/png, image/jpeg" id="image" class="_hide">
      <label for="image"><img :src="previewImageUrl" alt="" class="image"></label>
      <label v-if="!previewImageUrl" for="image" class="image-upload-icon">
        <img  src="@/assets/images/camera-g.png" alt="カメラアイコン" class="camera-icon">
      </label>
    </div>
    <div v-if="journal.style === 'plural'" class="image-upload">
      <label for="image" class="m_input-label _f4 _eg">Photos</label>
      <div class="image-upload-container">
        <div v-for="(imageObject, index) in imageDisp"
              :key="index"
              draggable="true"
              @dragstart="dragstart(imageObject, $event)"
              @dragenter="dragenter(imageObject)"
              @dragover.stop.prevent="dragover"
              @dragend.stop.prevent="dragend"
              class="box preview">
          <div class="preview-image" :style="{'background-image': 'url(' + imageObject.url +')'}"></div>
          <img @click="deleteImageObject(index, )" src="@/assets/images/cross-button-w.png" alt="" class="image-delete">
        </div>
        <div class="box image-upload-button">
          <input type="file" ref="preview" @change="setImages($event)" accept="image/png, image/jpeg" id="image" class="_hide">
          <label for="image" class="image-upload-icon">
            <img  src="@/assets/images/camera-g.png" alt="カメラアイコン" class="camera-icon">
          </label>
        </div>
      </div>
    </div>

    
    <!-- <button @click="create()" :disabled="v$.journal.$invalid || isButtonLock" class="m_submit _eg" :class="{'_invalid-button': v$.journal.$invalid}">Submit</button> -->
    <button v-if="mode === 'create'" @click="create('public')" :disabled="v$.journal.$invalid || isButtonLock" :class="{'m_invalid-button': v$.journal.$invalid}" class="m_submit _eg">Create</button>
    <button v-if="mode === 'create'" @click="create('unpublic')" :disabled="v$.journal.$invalid || isButtonLock" :class="{'m_invalid-button': v$.journal.$invalid}" class="m_delete _eg">Create as unpublic</button>
    <button v-if="mode === 'edit'" @click="edit()" :disabled="v$.journal.$invalid ||  isButtonLock" :class="{'m_invalid-button': v$.journal.$invalid}" class="m_submit _eg">Edit</button>
    <button v-if="mode === 'edit'&&journal.isPublic === true" @click="unPublicJournal()" :disabled="isButtonLock"  class="m_delete _eg">Unpublic</button>
    <button v-if="mode === 'edit'&&journal.isPublic === false" @click="publicJournal()" :disabled="isButtonLock"  class="m_restart _eg">Public</button>
    <p v-if="isError" class="m_error">エラー発生。再度リトライしてください。</p>
  </div>
</template>

<script>

import "@/css/manager.css"
import { db, storage, generateId, currentTime} from '@/firebase/index.js'
import Journal from "@/class/journal.js"
import imageCompression from 'browser-image-compression'
import  useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Spinner from '@/components/Spinner.vue'

export default {
  components:{
    Spinner
  },
  props:{
    is_sign: Boolean
},
  data(){
    return{
      pageName: "journal",
      isError: false,
      isButtonLock: false,
      mode: "create", //"create" or "edit"

      journal: new Journal(),
      compressedImage: null,
      previewImageUrl: null,
      imageChangeCounter : 0,
      currentYear:null,
      daysMax: null,
      exportPreparation:{
        date:{
          year: null,
          month: null,
          day: null
        },
      },
      // 複数枚画像用
      imageObjects:[],
      imageTrashBox:[],
      draggingItem: null, // ドラッグ中の要素を保持するための変数
      imageChangeCounterForPlural : 0,

    }
  },
  setup(){
    return { v$: useVuelidate()}
  },
  beforeCreate(){

  },
  created(){
    this.$store.commit('managerPageName', this.pageName)
    if(this.$route.params.id){
      this.mode = "edit"
      db.collection('journal').doc(this.$route.params.id).get()
      .then((doc)=>{
        this.journal = new Journal(doc.data())
        this.previewImageUrl = this.journal.imageUrl
        // colorがまだなかった記事用
        if(!this.journal.color){
          this.journal.color = ""
        }
        // updateCounterがまだなかった記事用
        if(!this.journal.updateCounter){
          this.journal.updateCounter = 0
        }
        // updateCounterがまだなかった記事用
        if(!this.journal.imageObjects){
          this.journal.imageObjects = null
        }
        if(this.journal.imageObjects){
          this.imageObjects = this.journal.imageObjects
          this.imageObjects.sort((a,b)=>{
            if(a.order < b.order){return -1}
            if(a.order > b.order){return 1}
            return 0
          })
        }
      })
      

    }else{
      this.mode = "create"
    }
    const date = new Date()
    this.currentYear = date.getFullYear()
  },
  mounted(){

  },
  methods:{
    async setImage(event){
      // プレビュー用
      const file = this.$refs.preview.files[0]
      
      // 保存用
      const originImage = event.target.files[0]
      const options = {
        maxSizeMB: 1.0, //最大ファイルサイズ
        maxWidthOrHeight: 2000, //最大縦横値
      }
      this.compressedImage = await imageCompression(originImage, options)
      this.previewImageUrl = URL.createObjectURL(file)
      this.$refs.preview.value = null
      this.imageChangeCounter =+ 1
    },
    async setImages(event){
      // プレビュー用
      const file = this.$refs.preview.files[0]
      
      // 保存用
      const originImage = event.target.files[0]
      const options = {
        maxSizeMB: 1.0, //最大ファイルサイズ
        maxWidthOrHeight: 2000, //最大縦横値
      }
      const compressedImage = await imageCompression(originImage, options)
      this.imageObjects.push({url: URL.createObjectURL(file), file: compressedImage})
      this.imageObjects.forEach((object, index)=>{
        object.order = index
      })
      this.$refs.preview.value = null
    },
    async create(type){
      this.isButtonLock = true
      let lastJournal = null
      this.setDate()
      if(type === 'unpublic'){
        this.journal.isPublic = false
      }
      await db.collection('journal').orderBy("order", "desc").limit(1).get()
      .then((querySnapShot)=>{
        if(querySnapShot.empty){
          lastJournal = "noData"
          this.journal.order = 1
        }else{
          querySnapShot.forEach((doc)=>{
            lastJournal = doc.data()
            this.journal.order = doc.data().order + 1
          })
        }
      })
      this.journal.id = generateId("journal")

      // 単体画像
      if(this.compressedImage){
        this.journal.imageName =  "journal/" + this.journal.id + String(this.journal.updateCounter)
        await storage.ref().child(this.journal.imageName).put(this.compressedImage)
        .then( async (snapShot)=>{
          this.journal.imageUrl = await snapShot.ref.getDownloadURL()
          
        })
        .catch((error)=>{
          console.log(error.message)
          this.isError = true
          this.isButtonLock = false
          throw new Error("画像の保存に失敗");
        })
      }

      //複数画像
      if(this.imageObjects !== []){
        this.imageObjects.forEach((imageObject, index)=>{
          imageObject.order = index
        })

        const imageObjectsToReturn = []
        const promises = []
        this.imageObjects.forEach((imageObject, index)=>{
          const fileName = `journal/${this.journal.id + "_" + String(this.journal.updateCounter) + "_" + String(index)}`
          promises.push(storage.ref().child(fileName).put(imageObject.file)
          .then( async (snapShot)=>{
            const url = await snapShot.ref.getDownloadURL()
            imageObjectsToReturn.push({url: url, order: imageObject.order, name: fileName})
          }))
        })
        this.journal.imageObjects = await Promise.all(promises).then(()=>{return imageObjectsToReturn})
      }

      this.journal.createdAt = currentTime
      this.journal.updatedAt = currentTime

      if(lastJournal === "noData"){
        // 最初の記事
        db.collection('journal').doc(this.journal.id).set({...this.journal})
        .then(()=>{
          this.$router.push({name: 'ManagerJournal'})
        })
        .catch((error)=>{
          console.log(error.message)
          this.isError = true
          this.isButtonLock = false
        })
      }else{
        // 最初以外
        let batch = db.batch()
        let newRef = db.collection('journal').doc(this.journal.id)
        batch.set(newRef, {...this.journal})
        batch.commit()
        this.$router.push({name: 'ManagerJournal'})
      }
    },
    async edit(){
      this.isButtonLock = true
      this.journal.updatedAt = currentTime
      this.journal.updateCounter = this.journal.updateCounter + 1
      this.setDate()
      // 単体画像の変更
      if(this.imageChangeCounter > 0){
        const lastImageName = this.journal.imageName
        storage.ref().child(lastImageName).delete()
        .then(()=>{
          this.journal.imageName = generateId("journal/")
          storage.ref().child(this.journal.imageName).put(this.compressedImage)
          .then( async (snapShot)=>{
            this.journal.imageUrl = await snapShot.ref.getDownloadURL()
            
            
            db.collection('journal').doc(this.journal.id).update({...this.journal})
            .then(()=>{
              this.$router.push({name: 'ManagerJournal'})
            })
            .catch((error)=>{
              console.log(error.message)
              console.log( "アップデートに失敗")
              this.isError = true
              this.isButtonLock = false
            })
          })
          .catch((error)=>{
            console.log(error.message)
            console.log("画像の保存に失敗")
            this.isError = true
            this.isButtonLock = false
          })
        })
        .catch((error)=>{
          console.log(error.message)
          console.log("画像の削除に失敗")
          this.isError = true
          this.isButtonLock = false
        })
      }else{

        // 複数画像保存
        this.imageObjects.forEach((imageObject, index)=>{
          imageObject.order = index
        })
        let imageObjectsForStorage = this.imageObjects.filter((object)=>{
          return object.name == null
        })
        this.imageObjects = this.imageObjects.filter((object)=>{
          return object.name != null
        })
        const imageObjectsToReturn = []
        const promises = []
        imageObjectsForStorage.forEach((imageObject, index)=>{
          const fileName = `journal/${this.journal.id + "_" + String(this.journal.updateCounter) + "_" + String(index)}`
          promises.push(storage.ref().child(fileName).put(imageObject.file)
          .then( async (snapShot)=>{
            const url = await snapShot.ref.getDownloadURL()
            imageObjectsToReturn.push({url: url, order: imageObject.order, name: fileName})
          }))
        })
        let imageObjectsFromStorage = await Promise.all(promises).then(()=>{return imageObjectsToReturn})
        imageObjectsFromStorage.forEach((imageObject)=>{
          this.imageObjects.push(imageObject)
        })
        this.imageTrashBox.forEach((object)=>{
          storage.ref().child(object.name).delete()
          .then(()=>{
          })
          .catch((error)=>{
            console.log(error.message)
          })
        })
        this.journal.imageObjects = this.imageObjects


        db.collection('journal').doc(this.journal.id).update({...this.journal})
        .then(()=>{
          this.$router.push({name: 'ManagerJournal'})
        })
        .catch((error)=>{
          console.log(error.message)
          console.log( "アップデートに失敗")
          this.isError = true
          this.isButtonLock = false
        })
      }
    },
    unPublicJournal(){
      this.isButtonLock = true
      this.journal.isPublic = false
      this.journal.updatedAt = currentTime
      db.collection('journal').doc(this.journal.id).update({...this.journal})
      .then(()=>{
        this.$router.push({name: 'ManagerJournal'})
      })
      .catch((error)=>{
        console.log(error.message)
        console.log("記事の非公開に失敗")
        this.isError = true
        this.isButtonLock = false
      })
    },
    publicJournal(){
      this.isButtonLock = true
      this.journal.isPublic = true
      this.journal.updatedAt = currentTime
      db.collection('journal').doc(this.journal.id).update({...this.journal})
      .then(()=>{
        this.$router.push({name: 'ManagerJournal'})
      })
      .catch((error)=>{
        console.log(error.message)
        console.log("記事の非公開に失敗")
        this.isError = true
        this.isButtonLock = false
      })
    },
    setDate(){
      if(this.exportPreparation.date.day !== null){
        this.journal.year = this.exportPreparation.date.year
        this.journal.month = this.$store.getters['getMonths'](this.exportPreparation.date.month)
        this.journal.day = this.exportPreparation.date.day
      }
    },

    deleteImageObject(index){
      if(this.imageObjects[index].name){
        const imageTrash = this.imageObjects[index]
        this.imageTrashBox.push(imageTrash)
        this.imageObjects.splice(index, 1)
      }else{
        this.imageObjects.splice(index, 1)
      }
      this.imageObjects.forEach((object, index)=>{
        object.order = index
      })
    },
    dragstart(object, e) {
      this.draggingItem = object; // ドラッグ中の要素を保持
      e.dataTransfer.effectAllowed = 'move'; // 移動モードに設定
      e.target.style.opacity = 0.5; // ドラッグ中要素のスタイルを変更
    },
    dragenter(object) {
      // ドラッグ中の要素とドラッグ先の要素の表示順を入れ替える
      [object.order, this.draggingItem.order] = [this.draggingItem.order, object.order];
    },
    dragover(e) {
      e.dataTransfer.dropEffect = 'move'; // 移動モードに設定
    },
    dragend(e) {
      e.target.style.opacity = 1; // ドラッグ中要素のスタイルを変更（元に戻す）
      this.draggingItem = null; // ドラッグ中の要素をクリア
    },
  },
  watch: {
    'exportPreparation.date.year': function(){
      this.daysMax = new Date(this.exportPreparation.date.year, this.exportPreparation.date.month, 0).getDate()
    },
    'exportPreparation.date.month': function(){
      this.daysMax = new Date(this.exportPreparation.date.year, this.exportPreparation.date.month, 0).getDate()
    },
    imageDisp:{
      deep: true,
      handler: function (){
        return this.imageObjects.sort((a, b) => a.order - b.order)
      }
    },
  },
  computed:{
    imageDisp() {
      return [...this.imageObjects].sort((a, b) => a.order - b.order)
    },
  },
  validations(){
    return{
      journal:{
        title:{
          required
        },
        text:{
          required,
        },


      },

    }
  },
}
</script>


<style scoped>
.image{
  width: 100%;
}
.image-upload-icon{
  display: block;
  background-color: var(--white);
  width: 100px;
  height: 100px;
  position: relative;
  box-sizing: border-box;
    border: 1px solid #b3a69f;
}

.camera-icon{
  position: absolute;
  width: 34px;
  height: 34px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


/* 複数枚画像用 */
.image-upload{
  margin-bottom: 22px;

}
.image-upload-label{
  font-size: 1.3rem;
  color: var(--gray-hi);
}
.image-upload-container{
  margin-top: 5px;
  display: flex;
  white-space:nowrap;
  overflow-x: auto;
}
.image-upload-icon{
  display: block;
  background-color: var(--white);
  width: 100px;
  height: 100px;
  position: relative;
  box-sizing: border-box;
    border: 1px solid #b3a69f;
}
.box{
  margin-right: 10px;
  display: block;
  width: 100px;  
}
.preview{
  position: relative;
}
.preview-image{
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: cover;
}
.image-delete{
  width: 18px;
  height: 18px;
  position: absolute;
  top: 4px;
  right: 4px;
}

@media screen and (min-width:600px){

}

</style>